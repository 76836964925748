import React, {useState, Fragment} from 'react';
import { ChargesRow, ChargesHeader } from "./ChargesRow";
import Loading from "./Loading";
import moment from "moment";
import gql from 'graphql-tag';
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button
} from "shards-react";
import { useQuery } from '@apollo/react-hooks';

export const GET_CHARGES_ACTIONS = gql`
  query getActions($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getActions(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      actions {
        _id
        type
        campaign
        supporterId
        date
        data
        description
      }
    }
  }
`;

export default function ChargesComponent({supporterId, supporter, charges, campaignView = false, hideHeader}) {

  const [skip, setSkip] = useState(0);
  const [limit] = useState(5);

  let IdList = !!supporter && [supporter._id];
    if ( !!supporter.mergedWith && !!supporter.mergedWith.length ) {
      IdList = [ supporter._id, ...supporter.mergedWith  ];
    }

  const opts = {
    variables: {
      selector : {
        supporterId : {$in : IdList},
        "data.paymentType" : {$exists : true, $ne : false}
      },
      limit,
      skip,
      sort     : { date : -1 },
      //hack using riding codes to ensure this actually reruns when the codes change
    },
    fetchPolicy : "network-only",
    onError(e) {
      console.log('fuckin error', e);
    }
  };

  const {data, loading } = useQuery(GET_CHARGES_ACTIONS, opts)

  const actions = !!data && data.getActions.actions || [];
  const count = !!data && data.getActions.count;



  const type = {
    "otg" : "OTG",
    "subscription" : "PAC"
  }
  const method = {
    "card"   : <i style = {{color : "royalBlue", fontSize : "1.8em"}} className="fab fa-cc-stripe"></i>,
    "cheque" : <i style = {{color : "#a8a886", fontSize : "1.8em"}} className="fas fa-money-check-alt"></i>,
    "bank"   : <i style = {{fontSize : "1.8em"}} className="fas fa-piggy-bank"></i>,
    "import" : <i style = {{fontSize : "1.8em"}} className="fas fa-database"></i>,
    "paypal"   : <i style = {{fontSize : "1.8em"}} className="fab fa-cc-paypal"></i>,
  }

  const table = {
    "data.paymentMethod" : {
      header: " ",
      xs : 1,
      sm : 1,
      format : (action) => !!action.data && !!method[action.data.paymentMethod] && method[action.data.paymentMethod] || action.data.paymentMethod
    },
    "date" : {
      header: "Date",
      xs : 5,
      sm : 3,
      format : (action) => moment(action.date).format("ll")
    },
    "data.amount" : {
      header: "Transaction",
      xs : 3,
      sm : 3,
      format : (action) => !!action.data && `${!!type[action.data.paymentType] && type[action.data.paymentType]}-$${(action.data.amount/100).toFixed(2)}` || ""
    },
    "campaign.nickname" : {
      header: "Campaign",
      xs : 4,
      sm : 3,
      format : (action) => {
        if(!!action.data.billingDate) {
          return `PAC-${moment(action.data.billingDate).format("MMMYYYY")}`;
        }
        return !!action.campaign && <Link to={`/campaign/${action.campaign._id}`}>{action.campaign.nickname}</Link>
      }
    }
  }


  return (
    <div>
      {!!actions.length &&
      <Fragment>
        <Row className="mx-1">
          {Object.keys(table).map((field, i) => {
            return <Col  key={i + Math.random()} xs={table[field].xs} sm={table[field].sm}>
              {table[field].header}
            </Col>
          })}
        </Row>
        {!!loading && <Loading /> || !!actions.length && actions.map((action, index) => {

            return  <div key={index + Math.random()}>
              <ChargesRow>
              <ChargesHeader
                campaignView={campaignView}
                table={table}
                index={index}
                action={action}/>

            </ChargesRow>
            </div>
          })}
        <Row className="m-3 justify-content-between">
          <Button
            theme={"white"}
            disabled = {skip === 0}
            onClick={() => setSkip(skip - limit)}>
          Previous
          </Button>
          <Button
            theme={"white"}
            disabled={skip + limit > count}
            onClick={() => setSkip(skip + limit)}>
            Next
          </Button>
        </Row>
      </Fragment>

      || <div className="mx-4 mb-4">- No Contributions -</div>}

    </div>
  );
}
