import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import _ from "underscore";

import {
  FormCheckbox,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Collapse
} from "shards-react";

import config from "./baseConfig";
import { fields, advancedFields } from "./fields";
import FieldRow from "./FieldRow";

import "./style.scss";

const QueryBuilder = ({ defaultSelector = {}, selector = {}, setSelector, setSkip, collection = "actions", setLoading }) => {
  // selector is the main output of the component
  const [advanced ] = useState(false);
  const [selectorInner, setSelectorInner] = useState(!!selector && !!Object.keys(selector).length && selector || defaultSelector);
  const [open, toggleOpen] = useState(false);

  //modal for advanced Search
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    toggleOpen(true);
  };
  const handleShow = () => {
    toggleOpen(false);
    setShow(true);
  };

  //when selectorInner changes, set table loading state

  useEffect( () => {
    if ( !!setLoading ) {
      setLoading(true);
    }
  }, [selector]);


  const collectionFields = fields[collection];

  const togglePopover = function (e) {
    toggleOpen(!open);
  };

  const triggerSubmit = (e) => {
    //set the actual selector to the value of the internal selector
    setSelector(selectorInner);
    //reset skip on submit
    setSkip(0);

    if(!!show) {
      setShow(false);
    }
    if(!!open) {
      toggleOpen(false);
    }

  };

  return (
    <div>

      {/* The query builder appears in a popover */}
      <Button theme="dark" outline id="qb-popover" onClick={togglePopover}>
        <i className="fa fa-filter"></i>&nbsp; Filters

        {/* Show the number of filters applied, excluding defaults */}
        {( _.difference( Object.keys(selector), Object.keys(defaultSelector) ).length && (
          <span className="border-left border-secondary ml-1 pl-2">
            {_.difference( Object.keys(selector), Object.keys(defaultSelector) ).length}
          </span>
        )) ||
          ""}
      </Button>
      <Popover
        open={open}
        target={"#qb-popover"}
        placement="bottom-start"
        style={{
          zIndex: 9999999,
          minWidth: 300,
        }}
        boundary={"viewport"}
        className="border"
      >
        <PopoverHeader>
          <div className="d-flex justify-content-between align-items-center">

            {/* Button to clear all filters */}
            <Button
              theme="secondary"
              size="sm"
              onClick={(e) => {
                setSelectorInner(defaultSelector);
                setSelector(defaultSelector);

                //reset skip on clear
                setSkip(0);
                toggleOpen(!open);
              }}
            >Clear
            </Button>
            <span className=""> Filters </span>

            {/* Button to triggger filter changes */}
            <Button theme="primary" size="sm" onClick={triggerSubmit}>
              Done
            </Button>
          </div>
        </PopoverHeader>
        <PopoverBody>

          {/* The builder UI itself. Render one row for each field */}
          {Object.keys(collectionFields).map((key, index) => {
            const field = collectionFields[key];

            return (
              <Fragment key={`frag-row-${key}`}>

                {/* Show border except on first child */}
                {(index > 0 && (
                  <span
                    style={{
                      width: "115%",
                      maxWidth: 300,
                      marginLeft: -20,
                    }}
                    className="border-top border d-block my-2"
                  >
                  </span>
                )) ||
                  ""}

                <FieldRow
                  // React key for multiple instances
                  key={`field-row-${key}`}
                  //keyName the component can actually use
                  keyName={key}
                  //the full field config
                  field={field}
                  //current selector
                  selector={selectorInner}
                  //hook to set selector
                  setSelector={setSelectorInner}
                  //all possible operators
                  operators={config.operators}

                  triggerSubmit = {triggerSubmit}
                />
              </Fragment>
            );
          })}
          {collection === "supporters" && <Button
              className="mt-2"
              theme={!!advanced && "primary" ||"secondary"}
              size="sm"
              onClick={handleShow}
            >Advanced
            </Button>
            || ""}

          {/* Show the selector in dev for debugging */}
          { process.env.REACT_APP_NODE_ENV === 'development' &&  JSON.stringify(selectorInner) || '' }
          { process.env.REACT_APP_NODE_ENV === 'development' &&  JSON.stringify(selector) || '' }

        </PopoverBody>
      </Popover>
      <Modal
        size="md"
        // centered
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdvancedSearch
            collection={collection}
            selectorInner={selectorInner}
            setSelectorInner={setSelectorInner}
            triggerSubmit={triggerSubmit}
          />
        </Modal.Body>
        <Modal.Footer>
        <Button theme="primary" size="sm" onClick={triggerSubmit}>
              Done
            </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AdvancedSearch = ({collection, selectorInner, setSelectorInner, triggerSubmit}) => {


  const [ active, setActive ] = useState([]);

  const handleCollapse = (title) => {
     if(_.includes(active, title)) {
       return setActive(_.without(active, title))
     }
     return setActive([...active, title]);
  };

  const keyPresentInSelector = (keys) => {
    let present = false;
    keys.map( key => {
      if(!!_.keys( selectorInner ).filter( k => k.includes(key) ).length) {
        present = true
      }
    })
    return present
  }

  const collectionFields = advancedFields[collection];
  {/* The builder UI itself. Render one row for each field */}
  return Object.keys(collectionFields).map((title, i) => {
    const isActive = !!_.includes(active, title) || keyPresentInSelector(Object.keys(collectionFields[title]))
    return (
    <div key={'advanced-row' + i}>
    {/* Show border except on first child */}
    {(i > 0 && (
      <span
        style={{
          width: "115%",
          // maxWidth: 300,
          marginLeft: -30,
        }}
        className="border-top border d-block my-2"
      >
      </span>
    )) ||
      ""}
      <FormCheckbox
          style={{fontWeight: 'bold'}}
          checked={ isActive }
          className={ !isActive && 'mb-0' || 'bg-warning'  }
          onChange={ (e) => {
            handleCollapse(title);
          }}>
           {title}
        </FormCheckbox>
        <Collapse open={isActive} timeout={0}>
        <div style={{'paddingLeft': '1rem'}}>
        {Object.keys(collectionFields[title]).map((key, index) => {
          const field = collectionFields[title][key];
          return (
            <Fragment key={`advance-row-${key}`}>

              {/* Show border except on first child */}
              {(index > 0 && (
                <span
                  style={{
                    width: "105%",
                    // maxWidth: 300,
                    marginLeft: -20,
                  }}
                  className="border-top border d-block my-2"
                >
                </span>
              )) ||
                ""}

              <FieldRow
                // React key for multiple instances
                key={`advance-field-row-${key}`}
                //keyName the component can actually use
                keyName={key}
                //the full field config
                field={field}
                //current selector
                selector={selectorInner}
                //hook to set selector
                setSelector={setSelectorInner}
                //all possible operators
                operators={config.operators}

                triggerSubmit = {triggerSubmit}
              />
            </Fragment>
          );
        })}

        </div>

    </Collapse>

    </div>
    )

  })
}

export default QueryBuilder;
