import React, { useState, Fragment} from "react";

import { useMutation } from '@apollo/react-hooks';

//UTILS
import _ from "underscore";

//UI
import { toast } from 'react-toastify';
import { Button, FormCheckbox } from "shards-react";

import gql from 'graphql-tag';

export const UPDATE_HOUSEHOLD = gql`
  mutation unLinkSupporters($supporterIds: [ String ], $unlinkIds: [ String], $allSupportersInfo: GenericObject) {
    unLinkSupporters(supporterIds: $supporterIds, unlinkIds: $unlinkIds, allSupportersInfo: $allSupportersInfo) {
      ok
      n
      nModified
    }
  }
`;

const ManageHousehold = ({supporter, handleClose}) => {

  const mutationOpts = {
    onCompleted({ mergeSupporters }) {
      toast.success("Household updated")
      handleClose();

    },
    onError(e){
      console.log({e})
      if(!!e.graphQLErrors && !!e.graphQLErrors.length) {
        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retirce the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage);
        }
      }

    },
    refetchQueries : ['getSupporter']
  };

  const [updateHousehold, { householdData, householdLoading, householdError }] = useMutation(UPDATE_HOUSEHOLD , mutationOpts );

  const [selected, setSelected] = useState({});

  const allTogether = [ _.pick(supporter, "_id", "firstName", "lastName"), ...supporter.householdMembers ];


  const submit = () => {

    let unlinkIds = [];
    for(const id in selected){
      unlinkIds.push(id)
    };

    let supporterIds = _.difference(_.pluck(allTogether, "_id"), unlinkIds);

    let allSupportersInfo = {}

    //this is to pass firstName and lastName of all the supporters to save it in the action
    allTogether.forEach( s => {
      allSupportersInfo[s._id] = {
        firstName : s.firstName,
        lastName  : s.lastName
      }
    });

    updateHousehold({
      variables : {
        supporterIds,
        unlinkIds,
        allSupportersInfo
      }
    })
  }

  if({householdError}) {
    console.log({householdError})
  }
  return (
    <Fragment>
    <p>Please uncheck supporters you want to remove from the household: </p>
    <div className={"m-3 p-3"}>
      {allTogether.map(s => {
        return (
          <FormCheckbox
            checked={!selected[s._id]}
            onChange={() => {

              const newSelected = !!selected[s._id] && _.omit(selected, [s._id]) || {...selected, [s._id] : true}

              setSelected(newSelected)}
              }
            key={`${s._id}`}>
            {`${s.firstName} ${s.lastName}`}
          </FormCheckbox>
        )
      })}
    </div>
    <div className="d-flex justify-content-between">
      <Button
        onClick={() => {
          submit();
        }}>
        Save
      </Button>
      <Button
        outline
        theme="danger"
        onClick={() => {handleClose()}}>
        Cancel
      </Button>
    </div>
    </Fragment>
  )
}

export default ManageHousehold;
