import React, {Component, useRef, useEffect, Fragment} from "react";
import _ from "underscore";
import Charges from "../../../friendsComponents/Charges";

import { useQuery } from '@apollo/react-hooks';
import PaymentMethods from "../../../friendsComponents/PaymentMethods";
import Contributions from "../../../friendsComponents/Contributions";
import {Accordion, AccordionBody, AccordionHeader} from "../../../friendsComponents/Accordion";

import CampaignOneTimeCard from "../../../friendsComponents/CampaignOneTimeCard";
import CampaignSelector from "../../../friendsComponents/CampaignSelector";
import CampaignRegisterCheque from "../../../friendsComponents/CampaignRegisterCheque";
import Loading from "../../../friendsComponents/Loading";
import {Row} from "shards-react";

import { GET_SUPPORTER_PAYMENT_LIGHT } from '../gql.js';

const CampaignStepOTG = ({store, registerGift, setPendingAmount}) => {

  const scrollTopRef = useRef(null);
  const scrollToTop = () => {
    scrollTopRef.current.scrollIntoView({ behavior: "smooth" })
  }
  
  useEffect(scrollToTop);
  // scrollToTop();
  return (
    <Fragment>
      <div ref={scrollTopRef} />
      <Row className="justify-content-between mx-3">
        <h3>{store.supporter.firstName} {store.supporter.lastName} {!!store.supporter.isDeceased && "(deceased)"} {!!store.supporter.isArchived && "(archived)"} {!!store.supporter.isVolunteer && "(volunteer)"}</h3>   
      </Row>

      <div>
        <PaymentMethods
          supporter={store.supporter}
        />
      </div>
      <div className= "m-2">
        <Contributions 
          setPendingAmount={setPendingAmount}
          title={"One-Time Donations"}
          supporter={store.supporter}
          user={store.user}
          campaign={store.campaign}
          wizardRegisterGift={registerGift}
          campaignView = {"otg"}
        />
      </div>
      <div className="mb-4">
        <Accordion
              openOnLoad={true}>
              <AccordionHeader 
                title="Contribution History"
              />
              <AccordionBody
                table={true}>
                <Charges
                  campaignView={true}
                  supporter={!!store.supporter && store.supporter}
                  hideHeader={true}
                />
              </AccordionBody>
        </Accordion>
      </div>
      
     
   </Fragment>
  )
}

export default class CampaignStep3 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pendingAmount    : false
    };
    this.props.setStep(3)
  }
  
  setPendingAmount = (boolean) => {
    // console.log("setting pendingAmount to : ", boolean)
    this.setState({pendingAmount : boolean});
  }

  isValidated = () => {
    if (!!this.state.pendingAmount) {
      alert("The payment entered hasn't been validated, please submit it before going to the next step");
      return false;
    }
    return !this.state.pendingAmount;
  }

  render() {
    return (
      <CampaignStepOTG
      store={this.props.store}
      registerGift={this.props.registerGift}
      setPendingAmount={this.setPendingAmount}
      />
    )
  }


}





  // const { data, loading, error } = useQuery(GET_SUPPORTER_PAYMENT_LIGHT, {
  //   variables: {
  //     supporterId : store.supporter._id
  //   },
  //   fetchPolicy : "network-only"
  // });

  // if(!!loading) {
  //   return <Loading />
  // }

//   const selector = [
//   {
//     name : "Credit Card",
//     default : true,
//     component : <CampaignOneTimeCard
//       store={ store }
//       data={ data }
//       setPendingAmount={setPendingAmount}
//       wizardRegisterGift = { registerGift }
//     />
//   },
//   {
//     name : "Cheque",
//     disabled : !!_.contains(store.user.roles.campaign, "caller"),
//     component : <CampaignRegisterCheque
//       supporterId={store.supporter._id}
//       campaign={store.campaign}
//       setPendingAmount={setPendingAmount}
//       wizardRegisterGift = { registerGift }
//     />
//   }
// ];

  // return (
  //   <Fragment>
  //   <CampaignSelector
  //     selector={selector}
  //   />
  //   <Charges
  //       supporter = {store.supporter}
  //       charges = {!!data && data.getSupporter.customerInfo.charges}
  //     />
  //   </Fragment>
  // )