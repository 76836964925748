import React, { useState } from "react";
import { Query } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import PageTitle from "../components/common/PageTitle";
import DeclinedTransactions from "../friendsComponents/DeclinedTransactions";
import TransactionsTable from "../friendsComponents/TransactionsTable";
import Loading from "../friendsComponents/Loading";
import useToast from "../hooks/use-toast";

import {
  Container,
  Row,
  Col,
  Card,
  Button

} from "shards-react";

import numeral from "numeral";
import moment from "moment";

import SmallStats from "./../components/common/SmallStats";

export const UPDATE_BANK_RUN_ITEM = gql`
  mutation updateOneBankRunItem($selector: Selector, $modifier: Modifier, $updateAction: GenericObject, $bankRunToUpdate: GenericObject, $supporterId: String,  $cancelSubscriptionId: String, $subUncollectable: Boolean, $runDate: Date, $plan: GenericObject) {
    updateOneBankRunItem(selector: $selector, modifier: $modifier, updateAction: $updateAction, bankRunToUpdate: $bankRunToUpdate, supporterId: $supporterId, subUncollectable: $subUncollectable, cancelSubscriptionId: $cancelSubscriptionId, runDate: $runDate, plan: $plan) {
      n
      nModified
      ok
    }
  }
`;

export const GET_BANKRUNS = gql`
  query getBankRuns($selector: Selector) {
    getBankRuns(selector: $selector){
        bankRuns {
          _id
          created
          runDate
          status
          bankRunCode
          count {
            expected
            processed
            declined
          }
          amount {
            expected
            processed
            declined
          }

        }
      }
    }
`;

export const COMPLETE_BANKRUN = gql`
  mutation completeBankRun($bankRunId: String, $runDate: Date) {
    completeBankRun(bankRunId : $bankRunId, runDate: $runDate){
      ok
      n
      nModified
    }
  }
`;

const BlastDetails = ({match, location, history}) => {

  const bankRunId = match.params.id || null;
  const [processing, setProcessing] = useState(false);

  const [declinedList, setDeclinedList] = useState([]);

  const { loadingToast, completeToast, errorToast} = useToast();

  const opts = {
    refetchQueries : ['getBankRuns'],
    onCompleted :  ({completeBankRun}) => {

      setProcessing(false);
      completeToast("Bank Run completed");
      setDeclinedList([]);
    },
    onError : (e) => {
      console.log({e});
      errorToast("Something went wrong closing the bank run");
    }
  }

  const item_opts = {
    onCompleted :  ({updateOneBankRunItem}) => {
      console.log('updateOneBankRunItem', updateOneBankRunItem);
      toast.dismiss()
      toast.success("Item updated")
    },
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  }


  const [ completeBankRun ] = useMutation(COMPLETE_BANKRUN, opts );
  const [updateOneBankRunItem, {error}] = useMutation(UPDATE_BANK_RUN_ITEM, item_opts);


  const complete = (completeBankRun, runDate) => {
    const conf = window.confirm(`Are you sure you want to complete this bank run?`);

    if ( !conf )  {
      return toast.warning(`Complete bank run canceled`);
    }

    completeBankRun({
      variables : {
        bankRunId,
        runDate
      }
    })
  }

  return (

      <Query
        query = {GET_BANKRUNS}
        variables = {{
          selector : {_id: bankRunId}
        }}
        fetchPolicy = "network-only"
        onError     = {(e) => {
          console.log(e);
          toast.error("There was a problem");
        }}>
        {({ data, loading, error, refetch, networkStatus }) => {

          if(loading && networkStatus == 1) {
            return <Loading />
          }
          if (error) return <p>ERROR: {error.message}</p>;

          const bankRun = !!data && !!data.getBankRuns && data.getBankRuns.bankRuns[0];

          return (
            <Container fluid className="main-content-container p-4">
              <Row noGutters className="page-header py-4">
                <PageTitle sm="8" title={`Bank Run ${bankRun.bankRunCode}`} subtitle={`created ${moment(bankRun.created).format('lll')}`} className="text-sm-left"/>
              </Row>
              <Row>

                <Col className="col-lg mb-4" md="6" sm="6">
                  <SmallStats
                    id={"runDate"}
                    variation="1"
                    label={"runDate"}
                    value={!!bankRun && moment(bankRun.runDate).format('MM/DD/YY') || 0}
                    percentage={null}
                  />
                </Col>
                { [ 'expected', 'processed', 'declined'].map( (val) => (
                  <Col className="col-lg mb-4" md="6" sm="6" key={val}>
                    <SmallStats
                      id={`small-stats-${val}`}
                      variation="1"
                      label={val}
                      value={!!bankRun && bankRun.count[val] || 0}
                      percentage={null}
                    />
                  </Col>
                )) }

                { [ 'expected', 'processed'].map( (val) => (
                  <Col className="col-lg mb-4" md="6" sm="6" key={val}>
                    <SmallStats
                      id={`small-stats-${val}`}
                      variation="1"
                      label={val}
                      value={!!bankRun && numeral(bankRun.amount[val]/100).format('$0,0.00') || 0}
                      percentage={null}
                    />
                  </Col>
                )) }
              </Row>
              <Card className="p-0">
                <DeclinedTransactions
                  bankRun={bankRun}
                  bankRunStatus={bankRun.status}
                  declinedList={declinedList}
                  setDeclinedList={setDeclinedList}
                  bankRunId={bankRun._id}
                  updateOneBankRunItem={updateOneBankRunItem}
                  />
                <TransactionsTable
                  bankRun={bankRun}
                  declinedList={declinedList}
                  setDeclinedList={setDeclinedList}
                  updateOneBankRunItem={updateOneBankRunItem}
                  />
                {bankRun.status === "open" &&
                  <Row className='my-2 px-2'>
                    <Col>
                      <Button
                        disabled={!!processing}
                        onClick={() => {
                          setProcessing(true);
                          loadingToast(`Please wait while the bank run is completing. It may take a while, don't refresh or close the page. :-)`);
                          complete(completeBankRun, bankRun.runDate);
                        }}
                      >
                        {!!processing && "processing" || "Complete Bank Run"}
                      </Button>
                    </Col>
                  </Row>
                }
              </Card>

            </Container>
          );
        }}
      </Query>

  )

}


export default BlastDetails;
