import gql from 'graphql-tag';


export const SUPPORTER_DETAILS = gql`
  fragment SupporterDetails on Supporter {
    _id
    prefix
    firstName
    lastName
    emails {
      _id
      address
      verified
      isPrimary
      ENSupporterId
      optInFr
      optInEn
      okNewsletter
      okFund
    }
    homePhone
    mobilePhone
    address1
    address2
    city
    province
    mergedWith
    mergedInto
    postalCode
    language
    optIn
    isDeceased
    isArchived
    isVolunteer
    isUnknown
    createdDate
    lastUpdated
    ENSupporterId
    customerId
    mergedCustomerIds
    PIN
    ridingCode
    ridingName
    PACStatus
    contactPreferences {
      okCall
      okMail
      okEvents
      okEmail
      okSMS
      okTrade
      okContact
      okContactRegular
      okSurveys
      okPremiums
      okPACAsk
    }
    source
    donationHistory {
      totalCount
      totalAmount
      countTwelveMonths
      totalTwelveMonths
      countThisYear
      totalThisYear
      dateFirstDonation
      dateLastDonation
      minDonationOTG
      maxDonationOTG
      meanDonationOTG
      lastRun
    }
  }
`;

export const CUSTOMER_INFO = gql`
  fragment CustomerInfo on Customer {
    id
    email
    description
    email
    sources {
      id
      exp_month
      exp_year
      last4
      brand
      customerId
    }
    default_source
    subscriptions{
      id
      status
      supporterId
      customerId
      bankAccountId
      created
      cancel_at
      pause_collection
      plan {
        id
        amount
        nickname
      }
    }
  }
`;

export const CHARGES = gql`
  fragment Charges on Charge {
    id
    amount
    refunded
    created
    description
    paid
    status
    type
    metadata
    invoice
  }
`;

export const BANK_ACCOUNT = gql`
  fragment BankAccounts on BankAccount {
    _id
    account
    bank
    firstName
    lastName
    last4
    created
    updated
  }
`;

export const SMS_CONVERSATION = gql`
  fragment SMSConversation on Message {
    _id
    blastId
    direction
    message
    from
    to
    status
    date
    supporterId
    prospectId
    __typename
  }
`;
