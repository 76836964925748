import React, { useState, Fragment } from "react";
import { Mutation } from "react-apollo";
import {
  Card,

  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  ButtonGroup
} from "shards-react";

import { toast } from "react-toastify";
import gql from 'graphql-tag';

export const REMOVE_USER = gql`
  mutation deleteUser($_id: ID, $modifier: Modifier) {
    deleteUser(_id: $_id, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

const RemoveUser = ({user, userInStorage}) => {

  const [password, setPassword] = useState("");

  const removeUserButton = {
    color: "white",
    backgroundColor: "red",
    borderColor: "red",
    marginTop: "25px"
  };

  return (
    <Mutation
      mutation={REMOVE_USER}
      onError={(e) => {

        console.log({e})
        toast.warning(e);

      }}
      onCompleted={() => {
        toast.success("User deleted.");
      }}
      >
      {(deleteUser, {data, loading, error}) => {
        return (
          <Fragment>
            
          <Row form className="border-top pt-2 mt-4">
            <Col md="6" className="form-group">
            <Button 
              outline
              style={removeUserButton}
              onClick={()=> {
                if (window.confirm("Remove Account? This action is permanent.")) { 
                const modifier = { };

                console.log(`id: ${user._id}`);
               deleteUser({
                  variables : {
                  _id       : user._id,
                  modifier
                  }
                })
              }}}>
              Remove Account
            </Button>
              
            </Col>
            </Row>
           
          </Fragment> 
        )
      }}
      
    </Mutation>
  )

};

export default RemoveUser;