import React, {useState, useEffect, memo, useContext, Fragment} from 'react';
import {
  Row,
  Col
} from "shards-react";

import {Modal} from "react-bootstrap";
import {ChargesProvider, ChargesContext } from "./ChargesContext";
import Refund from "../Refund";
import EditCampaign from "../EditCampaign";
import EditCheque from "../EditCheque";
import RetryInvoice from "../retryInvoice";
import {
  Collapse,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "shards-react";

import "./index.css";
import moment from "moment";



const ChargesRow = memo(props => {


  return(
    <div className="mt-2 border-bottom">
      <ChargesProvider
        openOnLoad={props.openOnLoad}>
        {props.children}
      </ChargesProvider>
    </div>
  )
})

const ChargesHeader = ({action, index, table, campaignView = false}) => {

  const [actionMenu, setActionMenu] = useState(false);

  //modal Edit Cheque
  const [showEditCheque, setShowEditCheque] = useState(false);

  const handleCloseEditCheque = () => setShowEditCheque(false);
  const handleShowEditCheque = () => setShowEditCheque(true);

  const status = () => {
    if(!!action.data) {
      switch(action.data.status) {
        case "success" :
          return <i class="far fa-check-circle"></i>;
          break;
        case "failure" :
          return <i class="fas fa-times"></i>;
          break;
        case "refunded" :
          return  <i class="fas fa-reply"></i>
          break;
      }
    }

    return "";

  }

  let actionList = [];

  const refundOption = action.data.paymentMethod === "card" && action.data.status === "success" && (!!action.data.record && !!action.data.record.id || !!action.data.record && action.data.record.chargeId)
  && <DropdownItem toggle={false}>
        <Refund
        discrete={true}
        chargeId = {action.data.record.id || action.data.record.chargeId}
        amount = {action.data.amount}
        supporterId = {action.supporterId}
      />
    </DropdownItem>
  || "";

  const editCampaignOption = action.data.paymentType === "otg"
  && <DropdownItem toggle={false}>
        <EditCampaign
          discrete={true}
          action={action}
          />
      </DropdownItem>
  || "";

  const editChequeOption = action.data.paymentMethod === "cheque" && action.data.status === "success"
  && <DropdownItem toggle={false}>
       <span className="btn-block" onClick={()=> { handleShowEditCheque()} }>Edit Cheque</span>
    </DropdownItem>
  || "";

  const retryPACOption = action.data.status === "failure" && !!action.data.record && !!action.data.record.invoiceId
  && <DropdownItem toggle={false}>
       <RetryInvoice
          discrete={true}
          invoiceId = {action.data.record.invoiceId}
          supporterId = {action.supporterId}
        />
  </DropdownItem>
  || "";


  return(
    <Row
        key={index}
        className="mx-1 mb-2">
      {Object.keys(table).map((field, i) => {
        return (<Col key={i + Math.random()} xs={table[field].xs} sm={table[field].sm}>
        {table[field].format(action)}
      </Col>)
      })}
      <Col xs={1}>
        {status()}
      </Col>
      <Col xs={1}>
      {console.log( action.data.paymentMethod )}
       {!!action.data && !!action.data.paymentType && action.data.paymentMethod !== 'bank' && <Dropdown open={actionMenu} toggle={() => setActionMenu(!actionMenu)} >
              <DropdownToggle style={{display : "inline"}}nav><i class="fas fa-ellipsis-h"></i></DropdownToggle>
              <DropdownMenu persist={true} right>
                  {refundOption}
                  {!campaignView && editCampaignOption}
                  {editChequeOption}
                  {retryPACOption}
                </DropdownMenu>
                </Dropdown>
                || ""}


      </Col>
      <Modal
        size="md"
        // centered
        show={showEditCheque}
        onHide={handleCloseEditCheque}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit cheque</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <EditCheque
            cheque={action}
            chequeId = {action._id}
            supporterId={action.supporterId}
            amount={action.data.amount}
            status={action.data.status}
            handleClose={handleCloseEditCheque}
          />
        </Modal.Body>
      </Modal>

  </Row>)

}



const ChargesBody = ({table, action}) => {

  const {active} = useContext(ChargesContext);
  const [actionMenu, setActionMenu] = useState(false);
  const [clickRefund, setClickRefund] = useState(false);

  //if we pass table = true, we want it to take as much space
  const className = !!table &&  "mt-2" || "mb-4 mx-4";

  let actionList = [];

  const refundOption = action.data.paymentMethod === "card" && action.data.status === "success" && (!!action.data.record && !!action.data.record.id || !!action.data.record && action.data.record.chargeId)
  && <Refund
      discrete={true}
      chargeId = {action.data.record.id || action.data.record.chargeId}
      amount = {action.data.amount}
      supporterId = {action.supporterId}
    />
  || "";

  const editCampaignOption = action.data.paymentType === "otg"
  && <EditCampaign
      action={action}
      />
  || "";



  const dropdownOptions = () => {

    if(action.data.paymentMethod === "card" && action.data.status === "success" && (!!action.data.record && !!action.data.record.id || !!action.data.record && action.data.record.chargeId)) {

      actionList.push(<Fragment>
        <DropdownItem onClick={() => {setClickRefund(true)}} key={"key"}>
        <Refund
            dropdown={true}
            click={clickRefund}
            setClick={setClickRefund}
            chargeId = {action.data.record.id || action.data.record.chargeId}
            amount = {action.data.amount}
            supporterId = {action.supporterId}
          />
        </DropdownItem>

        </Fragment>
        )


          // {/* Only show Edit campaign for otg */}
          // {action.data.paymentType === "otg" && <EditCampaign
          // action={action}
          // />
          // || ""}

      // )
    }
    if(action.data.paymentMethod === "cheque" && action.data.status === "success") {
      return "Edit Cheque"
    //   return(
    //     <ButtonGroup className="btn-block">

    //         <Button
    //           block
    //           theme="white"
    //           onClick={()=> { handleShowEditCheque()} }>
    //           Edit
    //         </Button>

    //     <EditCampaign
    //       action={action}
    //       />


    //     <Modal
    //       size="md"
    //       // centered
    //       show={showEditCheque}
    //       onHide={handleCloseEditCheque}
    //     >
    //       <Modal.Header closeButton>
    //         <Modal.Title>Edit cheque</Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body>
    //       <EditCheque
    //           cheque={action}
    //           chequeId = {action._id}
    //           supporterId={action.supporterId}
    //           amount={action.data.amount}
    //           status={action.data.status}
    //           handleClose={handleCloseEditCheque}
    //         />
    //       </Modal.Body>
    //     </Modal>
    // </ButtonGroup>
    //   )
    }
    if(action.data.status === "failure" && !!action.data.record && !!action.data.record.invoiceId) {
      return (
      <RetryInvoice
        invoiceId = {action.data.record.invoiceId}
        supporterId = {action.supporterId}
      />);
    }
    return ""
  }
  dropdownOptions();

  return(
      <Collapse open={active}>
        <div
          className={className}
          >
          <Row className="justify-content-between">
            <span>{!!action && action.description}</span>
              <Dropdown open={actionMenu} toggle={() => setActionMenu(!actionMenu)} >
              <DropdownToggle nav><i class="fas fa-ellipsis-h"></i></DropdownToggle>
              <DropdownMenu right>

              </DropdownMenu>
            </Dropdown>



          </Row>

        </div>

      </Collapse>)

}



export {ChargesRow, ChargesHeader, ChargesBody} ;
