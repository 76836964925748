//Apollo and GQL primary requirements
import { ApolloClient } from 'apollo-client';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { ApolloLink } from 'apollo-link';
// import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from "apollo-link-error";
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloProvider } from 'react-apollo';
import { routesPermissions} from "./data/users-roles-data";
import gql from "graphql-tag";

// import the Stripe provider to wrap the Stripe Context to Stripe elements
// like the add a Credit Card element
import {StripeProvider} from 'react-stripe-elements';

//React libraries & react router
import React from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

//notify with toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Scroll to the top
import ScrollToTop from "./friendsComponents/ScrollToTheTop";

//Routes
import { privateRoutes, publicRoutes, ProtectedRoute } from "./routes";
import withTracker from "./withTracker";

import Errors from './views/Errors.js';

//Style with bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

//Local state management
import {resolvers, typeDefs } from './resolvers';

import { PublicLayout, PrivateLayout } from "./layouts";

// Local state queries
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

//this is a comment
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_HTTP_LINK_URL || process.env.REACT_APP_NODE_ENV === 'staging' && process.env.REACT_APP_HTTP_LINK_URL || 'http://localhost:4000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const userInStorage = localStorage.getItem('user');
  const user = !!userInStorage && !!userInStorage.length && JSON.parse(userInStorage) || null;
  const token = !!user && user.token || "";


  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && `Bearer ${token}` || "",
    }
  };
});

const wsLink = () => {
  const userInStorage = localStorage.getItem('user');
  const user = !!userInStorage && !!userInStorage.length && JSON.parse(userInStorage) || null;
  const token = !!user && user.token || "";

  return new WebSocketLink({
    uri: process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_WS_LINK_URL || process.env.REACT_APP_NODE_ENV === 'staging' && process.env.REACT_APP_WS_LINK_URL || 'ws://localhost:4000/graphql',
    options: {
      lazy      : true,
      reconnect : true,
      connectionCallback(e){
        console.log('in callback', e);
      },
      connectionParams: {
        authorization: `Bearer ${token}` || "",
        authToken: token
      }
    }
  });
}


const errLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            toast.error("You have been logged out");
            if ( window.location.pathname !== '/login' ) {
              window.location = '/login';
            }
            window.localStorage.clear();
        }
      }
    }
    if (networkError) {

      console.log(`[Network error]: ${networkError}`);
      // forward( operation );

      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
      throw networkError;
    }

    return networkError;
  }
);


const finalLink = split(
  // split based on operation type
  ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
  wsLink(),
  // ApolloLink.from( authLink, httpLink)
  ApolloLink.from([ authLink, errLink, httpLink ])
)



const cache = new InMemoryCache();



export default () => {


    const checkCache = async () => {
      await persistCache({
        cache,
        storage: window.localStorage,
      });

    }

    checkCache();




  //Instantiate Apollo Client
  const client = new ApolloClient({
    cache,
    //Link to our server
    link: finalLink,
    //apply local state
    typeDefs,
    resolvers,
  });


  // const userInStorage = client.readQuery({ query: gql`
  //   query CurrentUser {
  //     currentUser @client
  //   }` });
  // const existingUser = !!userInStorage && !!userInStorage.currentUser && JSON.parse(userInStorage.currentUser) || null;

  //setup default cache values
  cache.writeData({
    data: {
      isLoggedIn: false,
      currentUser: null
    }
  });

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <ScrollToTop>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY || "pk_test_DTVN4XyHO5UVyJ1vRzO6u1sZ"}>
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              {/* If logged in, show private layout. Otherwise, public */}

                {/* Container for notifications */}
                <ToastContainer />

                  <div>
                    <Switch>
                    {publicRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          client={client}
                          component={withTracker(props => {
                            return (
                              <PublicLayout title={route.title} {...props}>
                                <route.component {...props} />
                              </PublicLayout>
                            );
                          })}
                        />
                      );
                    })}
                    {privateRoutes.map((route, index) => {
                      return (
                        <ProtectedRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          client={client}
                          authorization={routesPermissions[route.path]}
                          component={withTracker(props => {

                            return (
                              <PrivateLayout title={route.title} client={client} {...props}>
                                <route.component {...props} />
                              </PrivateLayout>
                            );
                          })}
                        />
                      );
                    })}
                    <Route component={Errors} />
                  </Switch>
                </div>
            </ApolloHooksProvider>
          </ApolloProvider>
        </StripeProvider>
      </ScrollToTop>
    </Router>
  )
};
