import getRidingData from "../../data/riding-data";
import getProvinceData from "../../data/province-data";
import { DonationHistory } from "../Contributions";

export const fields = {
  supporters: {
    firstName : {
      label: 'First Name',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'starts_with'
    },
    lastName: {
      label: 'Last Name',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    PIN: {
      label: 'PIN',
      type: 'number',
      operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
      defaultOperator: 'equals'
    },
    address1: {
      label: 'Address (first field)',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    city: {
      label: 'City',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    province: {
      label: 'Province',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: getProvinceData()
    },
    postalCode: {
      label: 'Postal Code',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    PACStatus: {
      label: 'PAC Status',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "pending", label: "Pending" },
        { value: "active", label: "Active" },
        { value: "lapsed", label: "Lapsed" },
        { value: "paused", label: "Paused" },
        { value: "cancelled", label: "Cancelled" },
        { value: "uncollectable", label: "Uncollectable" },
      ]
    },
    contactPreferences: {
      label: "Contact Preferences",
      type: 'object',
      properties: {
        okCall: {
          label: "Can Call",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okMail: {
          label: "Can Mail",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okEvents: {
          label: "Ok Events",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okSMS: {
          label: "Can SMS",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okTrade: {
          label: "Ok Trade",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okSurvey: {
          label: "Ok Survey",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okContact: {
          label: "Ok Contact",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okContactRegular: {
          label: "Ok Contact Regular",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okPremiums: {
          label: "Ok Premiums",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okPACAsk: {
          label: "Ok PAC Ask",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        }
      },
    },
    emails : {
      label: "Email Preferences",
      type: 'object',
      properties: {
        optInEn: {
          label: "EN Opt-in",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        optInFr: {
          label: "FR Opt-in",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okNewsletter: {
          label: "Ok Newsletter",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        },
        okFund: {
          label: "Ok Fund",
          type: 'boolean',
          operators: ['equals'],
          defaultOperator: 'equals'
        }
      }
    },
    ridingCode: {
      label: 'Riding',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: Array.from( getRidingData() ).map( function ([ name, code ], index, map) { return { label: name, value: code } } ),
    },
    lastUpdated: {
      label: 'Last Updated',
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    },
    createdDate: {
      label: 'Created',
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    },
    source : {
      label: 'Source',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    }
  },
  actions: {
    "date" : {
      label: "Date",
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    },
    "data.billingDate" : {
      label: "Billing Date (only subscriptions)",
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    },
    'campaign.nickname': {
      label: 'Campaign',
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'starts_with'
    },
    'campaign.mode': {
      label: 'Campaign Mode',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "house mail", label: "house mail" },
        { value: "prospect mail", label: "prospect mail" },
        { value: "telephone", label: "telephone" },
        { value: "digital", label: "digital" },
        { value: "PAC", label: "PAC" }
      ]
    },
    'data.paymentType' : {
      label: 'Type',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "otg", label: "One time gift" },
        { value: "subscription", label: "subscription" }
      ]
    },
    'data.paymentMethod' : {
      label: 'Payment Method',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "card", label: "card" },
        { value: "bank", label: "bank" },
        { value: "cheque", label: "cheque" },
        { value: "import", label: "import" },
      ]
    },
    'data.status' : {
      label: 'Status',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "success", label: "success" },
        { value: "failure", label: "failure" },
        { value: "refunded", label: "refunded" }
      ]
    },
    'data.amount' : {
      label: 'Amount',
      type: 'number',
      option : "currency",
      operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
      defaultOperator: 'greater_or_equal'
    }
  },
  campaigns: {
    nickname : {
      label: "Name",
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    mode: {
      label: 'Mode',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "house mail", label: "house mail" },
        { value: "prospect mail", label: "prospect mail" },
        { value: "telephone", label: "telephone" },
        { value: "digital", label: "digital" },
        { value: "PAC", label: "PAC" }
      ]
    },
    createdDate: {
      label: 'Created',
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    }
  },
  users: {
    firstName : {
      label: "First Name",
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    lastName : {
      label: "Last Name",
      type: 'text',
      operators: ['equals', 'starts_with', 'ends_with'],
      defaultOperator: 'equals'
    },
    gatewayRole: {
      label: 'Gateway Role',
      type: 'multiselect',
      operators: ['any', 'none'],
      defaultOperator: 'any',
      options: [
        { value: "basic", label: "Basic" },
        { value: "admin", label: "Admin" },
        { value: "super-admin", label: "Super Admin" }
      ]
    },
    createdDate: {
      label: 'Created',
      type: 'date',
      operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
      defaultOperator: 'date_on'
    }
  }
};


export const advancedFields = {
  supporters: {
    Ids : {
      supporterId : {
        label: 'Supporter ID',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'starts_with'
      },
      PIN: {
        label: 'PIN',
        type: 'number',
        operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
        defaultOperator: 'equals'
      },
      "emails.ENSupporterId": {
        label: 'ENSupporter ID',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'starts_with'
      }
    },
    Names : {
      firstName : {
        label: 'First Name',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'starts_with'
      },
      lastName: {
        label: 'Last Name',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      }
    },
    Addresses : {
      address1: {
        label: 'Address (first field)',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      address2: {
        label: 'Address (second field)',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      city: {
        label: 'City',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      province: {
        label: 'Province',
        type: 'multiselect',
        operators: ['any', 'none'],
        defaultOperator: 'any',
        options: getProvinceData()
      },
      postalCode: {
        label: 'Postal Code',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      country: {
        label: 'Country',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      ridingCode: {
        label: 'Riding Code',
        type: 'number',
        operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
        defaultOperator: 'equals'
      },
      ridingName: {
        label: 'Riding Name',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      MPFirstName: {
        label: 'MP First Name',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      MPLastName: {
        label: 'MP Last Name',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      MPParty: {
        label: 'MP Party',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },

    },
    "Contact Information" :{
      homePhone : {
        label: 'Home Phone',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'starts_with'
      },
      mobilePhone : {
        label: 'Mobile Phone',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with', 'not_equal'],
        defaultOperator: 'starts_with'
      },
      emails : {
        label: "Email Preferences",
        type: 'object',
        properties: {
          optInEn: {
            label: "EN Opt-in",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          optInFr: {
            label: "FR Opt-in",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okNewsletter: {
            label: "Ok Newsletter",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okFund: {
            label: "Ok Fund",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          }
        }
      },
    },
    "Contact Preferences" : {
      contactPreferences: {
        label: "Contact Preferences",
        type: 'object',
        properties: {
          okCall: {
            label: "Can Call",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okMail: {
            label: "Can Mail",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okEvents: {
            label: "Ok Events",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okSMS: {
            label: "Can SMS",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okTrade: {
            label: "Ok Trade",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okSurvey: {
            label: "Ok Survey",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okContact: {
            label: "Ok Contact",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okContactRegular: {
            label: "Ok Contact Regular",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okPremiums: {
            label: "Ok Premiums",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          },
          okPACAsk: {
            label: "Ok PAC Ask",
            type: 'boolean',
            operators: ['equals'],
            defaultOperator: 'equals'
          }
        },
      },
    },
    "Supporter Info" : {
      language: {
        label: 'Language',
        type: 'multiselect',
        operators: ['any', 'none'],
        defaultOperator: 'any',
        options: [{value : "French", label: "French"}, {value : "English", label: "English"}]
      },
      lastUpdated: {
        label: 'Last Updated',
        type: 'date',
        operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
        defaultOperator: 'date_on'
      },
      createdDate: {
        label: 'Created',
        type: 'date',
        operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
        defaultOperator: 'date_on'
      },
      isDeceased: {
        label: "Deceased",
        type: 'boolean',
        operators: ['equals'],
        defaultOperator: 'equals'
      },
      isArchived: {
        label: "Archived",
        type: 'boolean',
        operators: ['equals'],
        defaultOperator: 'equals'
      },
      isVolunteer: {
        label: "Volunteer",
        type: 'boolean',
        operators: ['equals'],
        defaultOperator: 'equals'
      },
      isUnknown: {
        label: "Unknown",
        type: 'boolean',
        operators: ['equals'],
        defaultOperator: 'equals'
      }
    },
    Tracking : {
      source : {
        label: 'Source',
        type: 'text',
        operators: ['equals', 'starts_with', 'ends_with'],
        defaultOperator: 'equals'
      },
      sourceDate: {
        label: 'Source Date',
        type: 'date',
        operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
        defaultOperator: 'date_on'
      }
    },
    Donations : {
      PACStatus: {
        label: 'PAC Status',
        type: 'multiselect',
        operators: ['any', 'none'],
        defaultOperator: 'any',
        options: [
          { value: "pending", label: "Pending" },
          { value: "active", label: "Active" },
          { value: "lapsed", label: "Lapsed" },
          { value: "paused", label: "Paused" },
          { value: "cancelled", label: "Cancelled" },
          { value: "uncollectable", label: "Uncollectable" },
        ]
      },
      lastPACAmount: {
        label: "Last PAC Amount",
        type: 'number',
        operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
        defaultOperator: 'greater_or_equal'
      },
      lastPACSuccess: {
        label: "Last PAC Date",
        type: 'date',
        operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
        defaultOperator: 'date_on'
      },
      donationHistory : {
            label: "Donation History",
            type: 'object',
            properties: {
              totalCount: {
                label: 'Count of Donations',
                type: 'number',
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'equals'
              },
              totalAmount: {
                label: 'Total of Donations',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              countTwelveMonths: {
                label: 'Count of Donations (last 12 months)',
                type: 'number',
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'equals'
              },
              totalTwelveMonths: {
                label: 'Total of Donations (last 12 months)',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              countThisYear: {
                label: 'Count of Donations (This Year)',
                type: 'number',
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'equals'
              },
              totalThisYear: {
                label: 'Total of Donations (This Year)',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              minDonationOTG: {
                label: 'Smallest OTG Amount Donated',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              maxDonationOTG: {
                label: 'Largest OTG Amount Donated',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              meanDonationOTG: {
                label: 'Average OTG Amount Donated',
                type: 'number',
                option : "currency",
                operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
                defaultOperator: 'greater_or_equal'
              },
              dateFirstDonation: {
                label: 'Date First Donation',
                type: 'date',
                operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
                defaultOperator: 'date_on'
              },
              dateLastDonation: {
                label: 'Date Last Donation',
                type: 'date',
                operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
                defaultOperator: 'date_on'
              },
            }
      }
    }
  }
};

//     PIN: {
//       label: 'PIN',
//       type: 'number',
//       operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//       defaultOperator: 'equals'
//     },
//     ENSupporterID: {
//       label: 'ENSupporterId',
//       type: 'number',
//       operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//       defaultOperator: 'equals'
//     },
//     address1: {
//       label: 'Address (first field)',
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     city: {
//       label: 'City',
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     province: {
//       label: 'Province',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: getProvinceData()
//     },
//     postalCode: {
//       label: 'Postal Code',
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     PACStatus: {
//       label: 'PAC Status',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "pending", label: "Pending" },
//         { value: "active", label: "Active" },
//         { value: "lapsed", label: "Lapsed" },
//         { value: "cancelled", label: "Cancelled" },
//         { value: "uncollectable", label: "Uncollectable" },
//       ]
//     },
//     contactPreferences: {
//       label: "Contact Preferences",
//       type: 'object',
//       properties: {
//         okCall: {
//           label: "Can Call",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okMail: {
//           label: "Can Mail",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okEvents: {
//           label: "Ok Events",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okSMS: {
//           label: "Can SMS",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okTrade: {
//           label: "Ok Trade",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okSurvey: {
//           label: "Ok Survey",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okContact: {
//           label: "Can Contact",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okContactRegular: {
//           label: "Ok contact more than once a year",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okPremiums: {
//           label: "Ok Premiums",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         }
//       },
//     },
//     emails : {
//       label: "Email Preferences",
//       type: 'object',
//       properties: {
//         optInEn: {
//           label: "EN Opt-in",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         optInFr: {
//           label: "FR Opt-in",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okNewsletter: {
//           label: "Ok Newsletter",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         },
//         okFund: {
//           label: "Ok Fund",
//           type: 'boolean',
//           operators: ['equals'],
//           defaultOperator: 'equals'
//         }
//       }
//     },
//     ridingCode: {
//       label: 'Riding',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: Array.from( getRidingData() ).map( function ([ name, code ], index, map) { return { label: name, value: code } } ),
//     },
//     lastUpdated: {
//       label: 'Last Updated',
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     },
//     createdDate: {
//       label: 'Created',
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     },
//     source : {
//       label: 'Source',
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     donations : {
//       label: "Donation History",
//       type: 'object',
//       properties: {
//         totalCount: {
//           label: 'Count of Donations',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         totalAmount: {
//           label: 'Total of Donations',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         countTwelveMonths: {
//           label: 'Count of Donations (last 12 months)',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         totalTwelveMonths: {
//           label: 'Total of Donations (last 12 months)',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         countThisYear: {
//           label: 'Count of Donations (This Year)',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         totalThisYear: {
//           label: 'Total of Donations (This Year)',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         minDonationOTG: {
//           label: 'Smallest OTG Amount Donated',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         maxDonationOTG: {
//           label: 'Largest OTG Amount Donated',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         meanDonationOTG: {
//           label: 'Average OTG Amount Donated',
//           type: 'number',
//           operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//           defaultOperator: 'equals'
//         },
//         dateFirstDonation: {
//           label: 'Date First Donation',
//           type: 'date',
//           operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//           defaultOperator: 'date_on'
//         },
//         dateLastDonation: {
//           label: 'Date Last Donation',
//           type: 'date',
//           operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//           defaultOperator: 'date_on'
//         },
//       }
//     }
//   },
//   actions: {
//     "date" : {
//       label: "Date",
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     },
//     "data.billingDate" : {
//       label: "Billing Date (only subscriptions)",
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     },
//     'campaign.nickname': {
//       label: 'Campaign',
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'starts_with'
//     },
//     'campaign.mode': {
//       label: 'Campaign Mode',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "house mail", label: "house mail" },
//         { value: "prospect mail", label: "prospect mail" },
//         { value: "telephone", label: "telephone" },
//         { value: "digital", label: "digital" },
//         { value: "PAC", label: "PAC" }
//       ]
//     },
//     'data.paymentType' : {
//       label: 'Type',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "otg", label: "One time gift" },
//         { value: "subscription", label: "subscription" }
//       ]
//     },
//     'data.paymentMethod' : {
//       label: 'Payment Method',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "card", label: "card" },
//         { value: "bank", label: "bank" },
//         { value: "cheque", label: "cheque" },
//         { value: "import", label: "import" },
//       ]
//     },
//     'data.status' : {
//       label: 'status',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "success", label: "success" },
//         { value: "failure", label: "failure" },
//         { value: "refunded", label: "refunded" }
//       ]
//     },
//     'data.amount' : {
//       label: 'Amount',
//       type: 'number',
//       option : "currency",
//       operators: ['greater_or_equal', 'less_or_equal', 'equals', 'between'],
//       defaultOperator: 'greater_or_equal'
//     }
//   },
//   campaigns: {
//     nickname : {
//       label: "Name",
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     mode: {
//       label: 'Mode',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "house mail", label: "house mail" },
//         { value: "prospect mail", label: "prospect mail" },
//         { value: "telephone", label: "telephone" },
//         { value: "digital", label: "digital" },
//         { value: "PAC", label: "PAC" }
//       ]
//     },
//     createdDate: {
//       label: 'Created',
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     }
//   },
//   users: {
//     firstName : {
//       label: "First Name",
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     lastName : {
//       label: "Last Name",
//       type: 'text',
//       operators: ['equals', 'starts_with', 'ends_with'],
//       defaultOperator: 'equals'
//     },
//     gatewayRole: {
//       label: 'Gateway Role',
//       type: 'multiselect',
//       operators: ['any', 'none'],
//       defaultOperator: 'any',
//       options: [
//         { value: "basic", label: "Basic" },
//         { value: "admin", label: "Admin" },
//         { value: "super-admin", label: "Super Admin" }
//       ]
//     },
//     createdDate: {
//       label: 'Created',
//       type: 'date',
//       operators: ['greater_or_equal', 'less_or_equal', 'date_on', 'between'],
//       defaultOperator: 'date_on'
//     }
//   }
// };
export default fields;
