import React, { Component, useState, Fragment, useRef } from "react";
import { toast } from 'react-toastify';
import Charges from "../../../friendsComponents/Charges";
import EditSupporterDetails from "../../EditSupporterDetails";
import ShowSupporterDetails from "../../ShowSupporterDetails";
import CardBankSubscription from "../../../friendsComponents/CardBankSubscription";
import {EmailsComponent} from "../../../friendsComponents/EmailsComponent";
import {Accordion, AccordionBody, AccordionHeader} from "../../../friendsComponents/Accordion";
import Loading from "../../../friendsComponents/Loading";
import {Row} from "shards-react";
import { useQuery } from '@apollo/react-hooks';
import {
  Card
} from "shards-react";

import { GET_SUPPORTER } from '../gql.js';
import { isMatch } from "underscore";



const CampaignStepSupporter = ({store, setSupporter}) => {

  const [actionSelector, setActionSelector] = useState({});
  const [actionLimit, setActionLimit]       = useState(5);
  const [actionSkip, setActionSkip]         = useState(0);
  const [actionSort, setActionSort]         = useState({date : -1});
  const [update, setUpdate]                 = useState(false);


  const opts = {
    variables : {
      supporterId : store.supporter._id,
      actionSelector,
      actionLimit,
      actionSkip,
      actionSort
    },
    fetchPolicy: "network-only"
  }

  const {data, loading, error} = useQuery(GET_SUPPORTER, opts);

  if(loading) {
    return <Loading />
  }
  if ( !!error ) {
    console.log(error);
    return <p>error</p>
  }
  const supporter = !!data && !!data.getSupporter && data.getSupporter;

  if(!!data && !!update && !isMatch(store.supporter, supporter)){
    if(!!update) {
      setUpdate(false);
    }

    console.log("set supporter in campaign step 1 ")
    setSupporter(supporter);
  }


  return (
    <Fragment>
    <Row className="justify-content-between mx-3">
        <h3>{supporter.firstName} {supporter.lastName} {!!supporter.isDeceased && "(deceased)"} {!!supporter.isArchived && "(archived)"}</h3>
    </Row>
    <ShowSupporterDetails
      setUpdate={setUpdate}
      supporterData={supporter}
    />
    <div className="mb-4">
    <Accordion
      openOnLoad={true}>
      <AccordionHeader
        title="Emails"
      />
      <AccordionBody
        table={true}>
        <EmailsComponent
          supporterEmails={ !!supporter && supporter.emails || [] }
          supporterId={ !!supporter && supporter._id || null }
          // supporter={supporter || null}
          />
      </AccordionBody>
    </Accordion>
    </div>

  </Fragment>
  )


}

export default class CampaignStep1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pendingAmount    : false
    };
    this.myRef = React.createRef()
    this.props.setStep(1)

  }

  setPendingAmount = (boolean) => {
    console.log("setting pendingAmount to : ", boolean)
    this.setState({pendingAmount : boolean});
  }


  render() {
    return (
      <div>
      <CampaignStepSupporter
        store        = {this.props.store}
        setSupporter = {this.props.setSupporter}


      />
      </div>
    )
  }


}
