import React, {useState, Fragment} from "react";
import CommentAction from "./CommentAction";
import useToast from "../hooks/use-toast";
import {
  Button
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const REFUND_CHARGE = gql`
  mutation refundCharge($chargeId: String, $supporterId: String, $opts: GenericObject) {
    refundCharge(chargeId: $chargeId, supporterId : $supporterId, opts: $opts) {
      id
      amount
      created
      status
    }
  }
`;

const Refund = ({discrete, chargeId, amount, supporterId, campaign}) => {

  const [showModal, setShowModal]       = useState(false);
  const [refunding, setRefunding]       = useState(false);
  const [opts, setOpts]                 = useState({});

  const { loadingToast, completeToast, errorToast} = useToast();

  const [ refund ] = useMutation(REFUND_CHARGE, {
    onError : (e) => {
      errorToast(e.message); console.log(e);
      setRefunding(false);
    },
    onCompleted : ({refunds}) => {
      completeToast("charge refunded");
      setRefunding(false);
    }
  })

  const submit = () => {

    setOpts({
        variables: {
          chargeId,
          supporterId,
          opts : {
            campaign : !!campaign && campaign || null
          }},
        refetchQueries: ['getSupporter', 'getActions']}
    );
    setRefunding(true);
    setShowModal(true);
  }


  const onHide = () => {
    setRefunding(false)
  }
  return(
    <Fragment>
         {!!discrete &&
              <span className="btn-block" onClick={submit}>Refund</span>
         || <Button
            size="sm"
            theme="white"
            className="btn-block"
            disabled={refunding === true}
            onClick={()=> {
              submit()
            }}
            >
            Refund
          </Button>}

          <CommentAction
            action={`refund $${(amount/100).toFixed(2)}`}
            confirmation={true}
            mutation={refund}
            cancelAction={() => setRefunding(false)}
            showModal={showModal}
            setShowModal={setShowModal}
            loadingMessage={"Processing the refund..."}
            loadingToast={loadingToast}
            opts={opts}
            onHide={onHide}
            />

        </Fragment>
  )

}

export default Refund;
