import React, {useState, useEffect, useRef } from 'react';
import PageTitle from "../components/common/PageTitle";
import Messages from '../friendsComponents/Messages';
import Loading from "../friendsComponents/Loading";
import { toast } from 'react-toastify';
import MessageBox from '../friendsComponents/MessageBox';
import _ from 'underscore';
import ScrollToBottom from 'react-scroll-to-bottom';
import { Query, Subscription } from 'react-apollo';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button} from "shards-react";

import "./Conversation.scss";

import {Mutation} from "react-apollo";
import gql from 'graphql-tag';



export const GET_SUPPORTER_CONVERSATION = gql`
  query getSupporter($supporterId: String) {
    getSupporter(supporterId: $supporterId) {
      _id
      mobilePhone
      contactPreferences
      SMSConversation {
        _id
        direction
        message
        to
        status
        date
        supporterId
      }
    }
  }
`;

export const GET_PROSPECT_CONVERSATION = gql`
  query getSupporter($prospectId: String) {
    getProspect(prospectId: $prospectId) {
      _id
      mobilePhone
      SMSConversation {
        _id
        direction
        message
        from
        to
        status
        date
        supporterId
        prospectId
      }
    }
  }
`;


const MESSAGE_UPDATED = gql`
  subscription messageUpdated($messageId: ID!) {
    messageUpdated(messageId: $messageId) {
      _id
      status
    }
  }
`;

//check query ?
export const MESSAGE_ADDED = gql`
  subscription messageAdded($supporterId: ID, $prospectId: ID){
    messageAdded(supporterId: $supporterId, prospectId: $prospectId) {
      _id
      status
      direction
      message
      date
      supporterId
      prospectId
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($from: String, $to: String, $message: String, $supporterId: String, $prospectId: String){
    sendMessage(from: $from, to: $to, message: $message, supporterId: $supporterId, prospectId : $prospectId){
      _id
      direction
      message
      from
      to
      status
      date
      supporterId
      prospectId
    }
  }
`;


const messageUpdatedSubHandler = function (prev, { subscriptionData }) {
  if (!subscriptionData.data) return prev;
  const updatedMessage = subscriptionData.data.messageUpdated;
  const accessor = _.has(prev, "getSupporter") && "getSupporter" || "getProspect";
  const newConvo = [ ...prev[accessor].SMSConversation ];
  const existing = _.findWhere( newConvo, { _id: updatedMessage._id  } );
  _.extend( existing, updatedMessage );
  
  const newObj = {};
  newObj[accessor] = {
    ...prev[accessor],
    SMSConversation: newConvo
  }
  return Object.assign({}, prev, newObj);
}



export class Conversation extends React.Component {
  render() {
    //use the rights Query and paramters depending if we fetch for a support or a prospecrt
    const QUERY = !!this.props.supporterId ? GET_SUPPORTER_CONVERSATION : GET_PROSPECT_CONVERSATION
    const varQuery = !!this.props.supporterId ? {supporterId: this.props.supporterId} : {prospectId : this.props.prospectId}

    return (
      <Query
        query={QUERY}
        variables={varQuery}
        fetchPolicy={'network-only'}
      >{({subscribeToMore, data, loading, error}) => {
        if (loading) {
          return <Loading />
        }

        if (error) {
          
          if(error.message === 'GraphQL error: 404: Not Found') {
            return "ERROR : You might not have permission to access to this page"
          }
          return <p>ERROR: {error.message}</p>;
        }

        const messages = !!data.getSupporter && data.getSupporter.SMSConversation || data.getProspect.SMSConversation;
        return (
          <ConversationInner
            messages={messages}
            supporter={data.getSupporter}
            prospect={data.getProspect}
            subscribeToMessageStatusUpdates={(message) => {
                subscribeToMore({
                  document: MESSAGE_UPDATED,
                  variables: {messageId: message._id},
                  updateQuery: messageUpdatedSubHandler
                });

            }}
            subscribeToNewMessages={() => {
              const varSubQuery = !!this.props.supporterId ? {supporterId: data.getSupporter._id} : {prospectId : data.getProspect._id}
              subscribeToMore({
                document: MESSAGE_ADDED,
                variables: varSubQuery,
                updateQuery: (prev, { subscriptionData }) => {

                  if (!subscriptionData.data) return prev;

                  const newMessage = subscriptionData.data.messageAdded;
                  const accessor = _.has(prev, "getSupporter") && "getSupporter" || "getProspect";


                  //hack to fix issue with to prop not being set on pub broadcast
                  if ( !newMessage.to ) {
                    newMessage.to = prev[accessor].mobilePhone;
                  }
                  
                  if ( !newMessage.supporterId && !!this.props.supporterId ) {
                    newMessage.supporterId = prev.getSupporter._id;
                  }
                  if ( !newMessage.prospectId && !!this.props.prospectId ) {
                    newMessage.prospectId = prev.getProspect._id;
                  }

                  //subscribe to status updates on this message
                  subscribeToMore({
                    document: MESSAGE_UPDATED,
                    variables: {messageId: newMessage._id},
                    updateQuery: messageUpdatedSubHandler
                  });

                  const returnObject = {};
                  returnObject[accessor] = {
                    ...prev[accessor],
                    SMSConversation: [ ...prev[accessor].SMSConversation, newMessage ]
                  }
                  return Object.assign({}, prev, returnObject);
                  
                }
              
              })
            }
            }
          />
        )

      }
    }

      </Query>
    )

  }
}


export class ConversationInner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageText: '',
      sending: false
    }
    this.messageSubs = [];
  }

  componentDidMount() {
    this.cancelAddedSub   = this.props.subscribeToNewMessages();
    this.props.messages.forEach(m => {
      this.messageSubs.push( this.props.subscribeToMessageStatusUpdates(m) );
    });
  }
  componentWillUnmount() {
    // this.cancelAddedSub();
    // this.cancelStatusSubs.forEach( cancelSub => {
    //   cancelSub();
    // });
  }

  render() {

    const noMessage = (!this.props.messages) && "No message to display" || '';
    const messageComponent = !!this.props.messages && !!this.props.messages.length && this.props.messages.map((message, i) => {
      const id = !message.supporterId && message.supporterId || message.prospectId
          return (
            <Messages
                  key          = {i}
                  messageId    = {message._id}
                  from         = {message.from}
                  direction    = {message.direction}
                  message      = {message.message}
                  date         = {message.date}
                  status       = {message.status}
                  id           = {id}
                  status       = {message.status}
                  conversation = {true}
                  subscribeToStatusUpdates
                />
            );
      });

      return (

        <Container fluid className="main-content-container px-4">

          <Card small className="my-4">
            <CardHeader>
              <h6 className="text-center">Conversation</h6>
            </CardHeader>
            <CardBody className="">
          <ScrollToBottom scrollViewClassName='scroll-container'>
            <div className="conversation chat" >
              {noMessage}
              {messageComponent}
            </div>
          </ScrollToBottom>
          </CardBody>
            <CardFooter>
              <Mutation
                mutation={SEND_MESSAGE}
                onCompleted={({sendMessage}) => {
                  this.setState({
                    'sending'     : false,
                    'messageText' : ''
                  });
                  // toast.success(`message successfuly sent`);
                }}
                onError={e => {
                  this.setState({ 'sending' : false });
                  for (const error of e.graphQLErrors) {
                    //check if the error is within GraphQL or sent back from the server
                    // in order to retirce the error message at the right place
                    const errorMessage = error.extensions.response ?
                    error.extensions.response.body.error.message :
                    error.message;
                    toast.error(errorMessage);
                  }
                }}
              >
                {(sendMessage, {networkStatus, data, loading, error}) => {
                  let importantMessage = !this.props.supporter.contactPreferences.okSMS ? "This supporter does not want to be contacted by SMS" : "";
                  if(!this.props.supporter.mobilePhone) {
                    importantMessage = "This supporter does not have a mobile phone in our records"
                  }
                  return(
                    <InputGroup className="mb-3">
                      <MessageBox
                        message={this.state.messageText}
                        title={importantMessage}
                        disabled={this.state.sending == true || !this.props.supporter.mobilePhone || !this.props.supporter.contactPreferences.okSMS}
                        handleChangeMessage={(val) => {
                          this.setState({'messageText': val});

                        }}
                      />
                      <InputGroupAddon type="append">
                        <Button
                          variant="primary"
                          disabled={this.state.sending == true || !this.props.supporter.mobilePhone || !this.props.supporter.contactPreferences.okSMS}
                          onClick={()=> {
                            if(!this.props.supporter.mobilePhone) {
                              return toast.warning("This supporter does not have a mobile phone in our records")
                            }

                            if(this.props.supporter.contactPreferences.okSMS == false) {
                              return toast.warning("This supporter does not want to be contacted by SMS")
                            }
                            //Block UI
                            this.setState({'sending': true});
                            const variables = {
                                from        : "+16474924966",
                                to          : !!this.props.supporter ? this.props.supporter.mobilePhone : this.props.prospect.mobilePhone,
                                message     : this.state.messageText,
                                supporterId : !!this.props.supporter ? this.props.supporter._id : null,
                                prospectId  : !!this.props.prospect ? this.props.prospect._id : null,
                              }
                              
                            //Send the message
                            sendMessage({
                              variables
                              // refetchQueries: ['getSupporter', 'getProspect']
                            });
                          }}
                        >Send
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  )
                }}
              </Mutation>

            </CardFooter>

          </Card>
        </Container>
      )
  }
}

// export const ConversationInner = ({ supporterId = null , prospectId = null , mobilePhone, messages, subscribeToNewMessages }) => {
//
//   //Uses hook
//   // const [messageText, setMessageText] = useState('');
//   // const noMessage = (!messages) && "No message to display" || '';
//
//   // useEffect(() => {
//     // Update the document title using the browser API
//     // subscribeToNewMessages();
//   // });
//
//   //this renders the array of messages
//
//
// }

export default Conversation;
