import React, {useState, Fragment} from "react";
import Subscription from "./Subscription";
import SubscribeToPlan from "./SubscribeToPlan";
import OTPCampaignSelector from "./OTPCampaignSelector";
import ConfirmationEmail from "./ConfirmationEmail";
import {Modal} from "react-bootstrap";
import numeral from "numeral";
import moment from "moment";
import {
    Button,
    Card,
    Col,
    Row
} from "shards-react";
import _ from "underscore";

const Contributions = ({supporter, user, title, campaign = null, setPendingAmount,  wizardRegisterGift, campaignView = false, disabled}) => {
  //this is used to send the confirmation email
  const [gifts, setGifts] = useState([]);

  //modal email
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);


  const handleCloseEmailConfirmation = () => setShowEmailConfirmation(false);
  const handleShowEmailConfirmation = () => setShowEmailConfirmation(true);


  //modal Add subscription
  const [showAddSubscription, setShowAddSubscription] = useState(false);

  const handleCloseAddSubscription = () => setShowAddSubscription(false);
  const handleShowAddSubscription = () => setShowAddSubscription(true);

  //modal process charge
  const [showCharge, setShowCharge] = useState(false);

  const handleCloseCharge = () => setShowCharge(false);
  const handleShowCharge = () => setShowCharge(true);

  const getEmail = () => {
    const { emails } = supporter;

    if ( !!emails && !!emails.length ) {
      const primary = _.findWhere( emails, { isPrimary: true } );
      return !!primary && primary.address || emails[0].address;
    }

    return "";
  };


  const customer       = supporter.customerInfo;
  const default_source = !!customer && !!customer.default_source && !!customer.sources.length && customer.default_source || "";
  const sources        = !!customer && !!customer.sources && !!customer.sources.length && customer.sources || [];
  const bankAccounts   = !!supporter.bankAccounts && supporter.bankAccounts || [];
  const subscriptions  = !!customer && !!customer.subscriptions && !!customer.subscriptions.length && customer.subscriptions || [];

  const OTGElement = <OTPCampaignSelector
                        supporterId={supporter._id}
                        default_source={default_source}
                        sources={!!customer ? customer.sources : []}
                        setGifts={setGifts}
                        campaignWizard={campaign}
                        handleShowEmailConfirmation={handleShowEmailConfirmation}
                        handleCloseCharge={handleCloseCharge}
                        wizardRegisterGift = { wizardRegisterGift }
                        setPendingAmount = { setPendingAmount }
                        user={user}
                      />;

  const subscriptionsElement = !!subscriptions.length && <Fragment>
      {!!title && " " || <strong>MONTHLY DONATIONS</strong>}
      <Row className="mt-3">
        {subscriptions.map((subscription, i) => {
          return (
            <Col  key={i + Math.random()} xs={12} sm={!!campaignView && 4 || 12}>
              <Subscription
                subscription={subscription}
                bankAccounts = {bankAccounts}
                supporterId={supporter._id}
                existingCreditCard = {!!sources.length}
                customerId={ supporter.customerInfo.id }
              />
            </Col>
          )
        })}
      </Row>
  </Fragment>
|| <span>- No Monthly Donation -</span>

let disabledButtons = disabled;
let archived = supporter.isArchived;

if (archived) {
  disabledButtons = true;
}

    return(
        <Card className="my-4">
          <span  className="p-4"><strong style={{fontSize : "1.5em"}}>Contributions</strong>{!!supporter.donationHistory && !!supporter.donationHistory.lastRun && ` (as of ${moment(supporter.donationHistory.lastRun).format("LLL")}` || ""})</span>
            <DonationHistory
              supporter={supporter}
              campaignView={campaignView}
              />
          {!!title && <strong className="p-4" style={{fontSize : "1.5em"}}>{title}</strong> || ""}
          <div  className="px-4">
          {campaignView !== "otg" && subscriptionsElement
          || ""}
        </div>
        {campaignView === "otg" && " " || <hr/>}
        <div className="px-4 mb-4">
          {campaignView === "otg" && " " || <strong>Create Donations</strong>}
          {campaignView ==="otg" && OTGElement || ""}
          <Row className="mt-3 px-3" >
            {!!campaignView  && " " || <Button
              className="mr-3"
              disabled={!!disabledButtons}
              // disabled={!default_source}
              onClick={handleShowCharge}>
              <i className="fas fa-plus"></i> OTG
            </Button>
            }
            {campaignView === "otg" && " " ||<Button
              //if the user is a caller and there is already a subscription, disable the Add subscription button
              disabled={!sources.length && !bankAccounts.length || _.contains(!!user && user.roles.campaign, "caller") && subscriptions.length >= 1 || !!disabledButtons}
              onClick={(e) => {
                e.preventDefault();
                if(!!subscriptions.length) {
                  const conf = window.confirm(` This supporter already as a monthly donation.
  Are you sure you want to create and add a new one?
  You can click Cancel and edit the existing subscription if needed.`);
                  if(!conf){
                    return;
                  }
                }
                handleShowAddSubscription()
              }}><i className="fas fa-plus"></i> PAC
              </Button>
            }
          </Row>
        </div>



        {/* Modal add subscriptipn */}
        <Modal
        size="md"
        // centered
        backdrop={false}
        keyboard={false}
        show={showAddSubscription}
        onHide={handleCloseAddSubscription}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscribe to plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubscribeToPlan
            setPendingAmount={setPendingAmount}
            supporterId={supporter._id}
            bankAccounts={bankAccounts}
            existingCreditCard = {!!sources.length}
            campaign={campaign}
            handleCloseAddSubscription={handleCloseAddSubscription}
            handleShowEmailConfirmation={handleShowEmailConfirmation}
            setGifts={setGifts}
            wizardRegisterGift = { wizardRegisterGift }
          />
        </Modal.Body>
      </Modal>

      {/* Process Charge Modal */}
      <Modal
        size="lg"
        // centered
        backdrop={false}
        keyboard={false}
        show={showCharge}
        onHide={handleCloseCharge}
      >
          <Modal.Header closeButton>
            <Modal.Title>Process one-time Gift</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {OTGElement}
          </Modal.Body>
      </Modal>

      {/* email Confirmation Modal */}
      <Modal
        size="md"
        // centered
        show={showEmailConfirmation}
        onHide={handleCloseEmailConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Email Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ConfirmationEmail
              supporter={supporter}
              firstName={supporter.firstName}
              address={getEmail()}
              gifts={gifts}
              setGifts={setGifts}
              handleClose={handleCloseEmailConfirmation}
            />
        </Modal.Body>
      </Modal>
        </Card>
    )
}

export const DonationHistory = ({supporter, campaignView = false}) => {
  const display = !!campaignView && 3 || 6;

  return (
  <Fragment>

    <Row  className="px-4 mb-3 border-bottom">

        <Col md={display}>
          <div>In {moment().format('YYYY')}</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && numeral(supporter.donationHistory.totalThisYear/100).format("$0,0.00") || "$0.00"}</div>
          <div className="mb-4">{!!supporter.donationHistory && supporter.donationHistory.countThisYear || "0"} contribution{!!supporter.donationHistory && supporter.donationHistory.countThisYear> 1 && "s" || ""}</div>
        </Col>

        <Col md={display}>
          <div>Last 12 Months</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && numeral(supporter.donationHistory.totalTwelveMonths/100).format("$0,0.00") || "$0.00"}</div>
          <div className="mb-4">{!!supporter.donationHistory && supporter.donationHistory.countTwelveMonths || "0"} contribution{!!supporter.donationHistory && supporter.donationHistory.countTwelveMonths> 1 && "s" || ""}</div>
        </Col>


        <Col md={display}>
          <div>Lifetime Contributions</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && numeral(supporter.donationHistory.totalAmount/100).format("$0,0.00") || "$0.00"}</div>
          <div className="mb-4">{!!supporter.donationHistory && supporter.donationHistory.totalCount || "0"} contribution{!!supporter.donationHistory && supporter.donationHistory.totalCount> 1 && "s" || ""}</div>
        </Col>

        <Col md={display}>
          <div>Min OTG donation</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && supporter.donationHistory.minDonationOTG && numeral(supporter.donationHistory.minDonationOTG/100).format("$0,0.00") || "$0.00"}</div>
          <div className="mb-4"> - </div>
        </Col>

        <Col md={display}>
          <div>Max OTG donation</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && supporter.donationHistory.maxDonationOTG && numeral(supporter.donationHistory.maxDonationOTG/100).format("$0,0.00") || "$0.00"}</div>
          <div className="mb-4"> - </div>
        </Col>

        <Col md={display}>
          <div>Mean OTG donation</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && supporter.donationHistory.meanDonationOTG && numeral(supporter.donationHistory.meanDonationOTG/100).format("$0,0.00") || "-"}</div>
          <div className="mb-4"> - </div>
        </Col>

        <Col md={display}>
          <div>First Donation</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && !!supporter.donationHistory.dateFirstDonation && moment(supporter.donationHistory.dateFirstDonation).format("ll") || "-"}</div>

        </Col>

        <Col md={display}>
          <div>Last Donation</div>
          <div style={{fontSize : "1.2em"}}>{!!supporter.donationHistory && !!supporter.donationHistory.dateLastDonation && moment(supporter.donationHistory.dateLastDonation).format("ll") || "-"}</div>

        </Col>

    </Row>


  </Fragment>
  )
}

export default Contributions;
