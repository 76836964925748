import React, {useState, useEffect} from 'react';
import ReactTable from 'react-table';
import { useQuery } from '@apollo/react-hooks';
import _ from "underscore";
import gql from 'graphql-tag';
import numeral from 'numeral';
import moment from "moment";
import { toast } from 'react-toastify';

import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Button
} from "shards-react";


export const GET_TRANSACTIONS = gql`
  query getBankRunItems($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getBankRunItems(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      bankRunItems {
        _id
        supporterId
        subscriptionId
        cancelSubscription
        status
        PIN
        reason
        plan {
          amount
        }
        supporter {
          firstName
          lastName
        }
        bankAccount{
          last4
          firstName
          lastName
        }
      }
    }
  }
`;


const TransactionsTable =({declinedList, setDeclinedList, bankRun, updateOneBankRunItem}) => {
  //sets the columns to display for our  table
  const columns = [{
    Header: 'Supporter',
    accessor: 'supporter',
    className: "text-center",
    Cell: row => {

      return <Link to={`/supporter/${row.original.supporterId}`}>{`${row.original.PIN} - ${row.original.bankAccount.firstName} ${row.original.bankAccount.lastName}`}</Link>;
    }
  }, {
    Header: 'Status',
    show: bankRun.status !== "open",
    className: "text-center",
    accessor: 'status'
  }, {
    Header: 'Reason',
    className: "text-center",
    accessor: 'reason'
  }, {
    Header: 'Cancel Subscription',
    show: bankRun.status === "open",
    className: "text-center",
    accessor: 'cancelSubscription',
    Cell: row => {

      return !!row.original.cancelSubscription && "yes" || "no"
    }
  }, {
    Header: 'amount',
    className: "text-center",
    accessor: 'plan.amount',
    Cell: row => {

      return <span>{`$${numeral(row.original.plan.amount/100 ).format("0,0.00")}`}</span>
    }

  }//,{
  //   Header: 'Actions',
  //   accessor: "supporterId",
  //   show: true,//bankRun.status === "open",
  //   sortable: false,
  //   Cell: row => {
  //     //shows the remove button
  //     return  (
  //       <Button
  //         outline
  //         theme="danger"
  //         onClick={(e) => {
  //           e.preventDefault();
  //           toast.info("Processing...");
  //           //update the bankrunitem
  //           const item = row.original;
  //           updateOneBankRunItem({
  //             variables : {
  //               selector : {
  //                 _id : item._id
  //               },
  //               modifier : {
  //                 $set : {
  //                   status : null,
  //                   reason : null,
  //                 }
  //               },
  //               supporterId : item.supporterId,
  //               bankRunToUpdate : bankRun
  //             },
  //             refetchQueries : ['getBankRuns', 'getBankRunItems']
  //           });
  //           //setDeclinedList(_.filter(declinedList, (item) => item._id !== row.original._id ))
  //         }}
  //         >
  //         Remove
  //       </Button>
  //     )
  //   }
  // }
  ];

  const [ loading, setLoading ] = useState(false);
  const [limit, setLimit] = useState(20);
  const [ pages, setPages ] = useState(0);

  const opts = {
    variables: {
      selector : { bankRunId: bankRun._id },
      limit,
      skip     : 0,
      sort     : { status: -1, date : -1 }
    },
    fetchPolicy : "network-only"
  };

  const { error, data, fetchMore } = useQuery(GET_TRANSACTIONS, opts );
  if (error) {
    console.log({error})
    return `Error! ${error}`
  };

  if ( !!data && !!data.getBankRunItems && !!data.getBankRunItems.bankRunItems && !!data.getBankRunItems.bankRunItems.length  ) {
    const declinedData = data.getBankRunItems.bankRunItems.filter(i => i.status == 'declined');
    if ( !!declinedData.length ) {
      if ( !declinedList || !declinedList.length || !declinedList.length || declinedList[0]._id !== declinedData[0]._id ) {
        setDeclinedList( declinedData );
      }
    }
  }

  return (
        <ReactTable
          data={bankRun.status === "open" && declinedList ||  !!data && !!data.getBankRunItems && data.getBankRunItems.bankRunItems || []}
          columns={columns}
          loading={loading}
          defaultPageSize={20}
          pageIndex={0}
          showPageJump = {false}
          manualPagination={true}
          manual
          noDataText={`No data to display`}
          pages={ pages }
          onFetchData={(state, instance) =>  {
            if(bankRun.status === "open"){
              return null;
            }
            //create sort object with default being createdDate desc
            let sort = { date: -1 }
            state.sorted.forEach( (sortField, i) => {
              //if we have anything here, overwrite default
              if ( i == 0 ) {
                sort = {};
              }
              sort[sortField.id] = !!sortField.desc && -1 || 1;
            });
            //tell the table we're loading
              setLoading(true);

             // get the data
              fetchMore({
                variables: {
                  skip: state.pageSize * state.page,
                  limit: state.pageSize,
                  sort
                },

                updateQuery: (prev, { fetchMoreResult }) => {
                  if ( !prev && !!fetchMoreResult) {
                    setLoading(false);
                    return fetchMoreResult;
                  }
                  //update the data
                  if (!fetchMoreResult) {
                    setPages(Math.ceil(prev.getBankRunItems.count / state.pageSize));
                    return prev;
                  }
                  setPages(Math.ceil(fetchMoreResult.getBankRunItems.count / state.pageSize));
                  const newObj = {
                    getBankRunItems: {
                      ...prev.getBankRunItems,
                      bankRunItems : fetchMoreResult.getBankRunItems.bankRunItems
                    }
                  };
                  //disable loading state
                  setLoading(false);
                  return Object.assign({}, prev, newObj);
                }
              })

          }}
        />
  );
};


export default TransactionsTable;
