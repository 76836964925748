import React, { Component, Fragment } from "react";
import { pick, isEmpty, pluck } from 'underscore';
import redoJson from 'redo-json';
import { DebounceInput } from 'react-debounce-input';


import { Query } from "react-apollo";
import { toast } from 'react-toastify';

import {
  Row,
  Col,
  Card,
  FormInput,
  FormRadio,
  InputGroup,
  InputGroupAddon,
  Button,
} from "shards-react"

import SupporterTable from '../../../friendsComponents/SupporterTable';
import CreateSupporter from "../../CreateSupporter";
import EditSupporterDetails from "../../EditSupporterDetails";
import ShowSupporterDetails from "../../ShowSupporterDetails";
import Loading from "../../../friendsComponents/Loading";

import { GET_SUPPORTERS } from '../gql.js';

export default class CampaignStep1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchBy         : localStorage.getItem('campaignSearchBy') || "PIN",
      searchVal        : "",
      scannedSupporter : null,
      supportersInTable: [],
      readyToScan      : false,
      showCreate       : false,
      supporter        : !!this.props.store.supporter && this.props.store.supporter || null,
      submitFired      : false,
      view             : "view",
      opts             : {}
    };

    this.props.setStep(0);

    this.handleScan = this.handleScan.bind(this);
    this.setSupporterAfterCreate = this.setSupporterAfterCreate.bind(this);
  }

  setSearchBy(val) {
    this.setState({
      searchBy: val
    });

    //persist for future donations
    window.localStorage.setItem('campaignSearchBy', val);
  }

  //We want to make sure the user searched and found a user before allowing them to go to other steps
  isValidated = () => {

    const supporter = this.props.store.supporter;

    //if supporter is deceased, do not allow to go further
    if(!!supporter && !!supporter.isDeceased) {
      const conf = window.confirm(`The supporter is marked as deceased, are you sure you want to continue?`);
      return conf;
    }

    //if supporter is archived, do not allow to go further
    if(!!supporter && !!supporter.isArchived) {
      const conf = window.confirm(`The supporter is marked as archived, are you sure you want to continue?`);
      return conf;
    }

    if ( ['PIN', 'ENSupporterId'].includes(this.props.searchBy) ) {
      return !!supporter && supporter[this.props.searchBy];
    }
    return !!supporter;
  }

  submit = ({ scannedSupporter } = {}) => {
    //if no supporter or loaded supporter doesn't match input, wipe the state
    if ( !this.state.supporter || this.state.supporter[this.state.searchBy] !== this.state.searchVal  ) {
      console.log('wiping')
      this.setState({supporter : null});
    }


    let selector = {};
    let searchLimit = 50;

    if ( this.state.searchBy == 'barcode' && !!scannedSupporter ) {

      //if no PIN, find a mathch some other way. REmove city and province to get unique vals
      selector.$or = [
        pick( scannedSupporter, 'PIN' ),
        pick( scannedSupporter, 'postalCode' ),
        pick( scannedSupporter, 'address1'),
        pick( scannedSupporter, 'firstName', 'lastName' ),
      ].map( obj => {
        //ensure no empty strings, especially for PIN
        for ( const key in obj ) {
          if ( obj[key] == "" ) {
            delete obj[key];
          }
        }
        return obj;
      }).filter( obj => !isEmpty(obj) )

      //allow multuple supporters ot be returned
      searchLimit = 100;

    } else {
      if(this.state.searchBy === "ENSupporterId") {
        selector["emails.ENSupporterId"] = this.state.searchVal;
      } else {
        selector[this.state.searchBy] = this.state.searchVal;
      }
    }

    this.setState({
      opts : {
        selector,
        limit : searchLimit
      },
      submitFired : true
    });
  }

  handleScan(e) {

    //don't reload
    e.preventDefault();
    let str = e.target.value;

    if ( !str || !str.length ) { return console.log('no string') };
    //deal with poor printing and replace single quotes with doubles and also enclose keys in quotes
    //let newStr = str.replace(/:'/g, '":"').replace(/',/g, '","').replace('{', '{"').replace(/'}/g, '"}');

    //let newStr = '{"firstName":"Maragrethe","lastName":"Sorensen","address1":"9489 205A St","address2":"","city":"Langley","province":"BC","postalCode":"V1M 1Y9","PIN":"258442","campaignId":"F202-118","panel":""}';
    //try to fix the json if you can
    let supporter;

    try {
      console.log('newStr', str);
      supporter = redoJson.parse(str);
    } catch(e) {
      return alert("Invalid bardcode");
    }
    //PIN is a string. Make it a number.
    if ( !!supporter.PIN && !!supporter.PIN.length ) {
      supporter.PIN = parseInt(supporter.PIN, 10);
    }

    //remove space from postalcode
    supporter.postalCode = supporter.postalCode.replace(" ", "");

    //clear the form field so we can scan again if necessary
    e.target.value = '';

    //add default contact prefs to supporter in case we need to create one later
    //create supporter doesn't work without it.
    supporter.contactPreferences = {
      "emails.optInEn":                  false,
      "emails.optInFr":                  false,
      "contactPreferences.okEmail":      true,
      "contactPreferences.okCall":       true,
      "contactPreferences.okMail":       true,
      "contactPreferences.okEvents":     true,
      "contactPreferences.okSMS":        true,
      "contactPreferences.okFund":       true,
      "contactPreferences.okTrade":      true,
      "contactPreferences.okNewsletter": false,
      "contactPreferences.okSurveys":    true,
      "contactPreferences.okContact":    true,
    };

    this.setState({ scannedSupporter: supporter });
    this.submit({ scannedSupporter: supporter });
  }

  onKeyPress = (e) => {
    if(e.which === 13) {
      this.submit();
    }
  }

  changeView =(view) => {
    this.setState({view : view})
  }

  onChange = (value) => {
    if( !!this.state.supporter && !!isEmpty( this.state.supporter ) ) {
      this.setState({supporter : {}});
      this.props.setSupporter(null);
    }

    const regex = /^[0-9]+$/;
    if(!!regex.test(value) || !value) {
      this.setState({searchVal : value});
    }
  }

  setSupporterAfterCreate(supporter) {
    this.setState({
      submitFired: false,
      supporter
    });
    this.props.setSupporter(supporter);

    //then move to the next step
    this.props.jumpToStep(1);
  }

  render() {
    return(
      <Query
        query={GET_SUPPORTERS}
        variables={this.state.opts}
        skip={!this.state.submitFired}
        fetchPolicy="network-only"
        onCompleted={ ( {getSupporters}) => {
          if( !getSupporters || !getSupporters.supporters || !getSupporters.supporters.length ) {
            //if we scanned a supporter and no matches are found, offer to create
            if( !!this.state.scannedSupporter ) {
              const conf = window.confirm("No matching supporter found. Would you like to create a new one?");
              if ( !!conf ) {
                return this.setState({
                  supporter: this.state.scannedSupporter,
                  view: 'create'
                });
              }
            }
            toast.error("Supporter not found");
            this.setState({showCreate : true})
          }

          if(!getSupporters || !getSupporters.supporters || !getSupporters.supporters.length && !!this.state.supporter) {
            this.setState({supporter : {}})
          }

          //if more than one supporter returned, show the table

          //if there are more, show the table so they can pick, or create new
          if( !!getSupporters && !!getSupporters.supporters && !!getSupporters.supporters.length ) {
            if ( getSupporters.supporters.length == 1 ) {
                this.props.setSupporter(getSupporters.supporters[0]);
                this.setState({
                  view: 'show',
                  supporter: getSupporters.supporters[0]
                });
                this.props.jumpToStep(1);
            } else if ( getSupporters.supporters.length > 1 ) {
              this.setState({
                submitFired: false,
                supportersInTable: pluck( getSupporters.supporters, "_id" ),
                view: 'table'
              });
            }
          }
        }}
      >

        {({data, loading, error, refetch}) => {

          if(!!loading) {
            return(<Loading />)
          }
          const supporterTable = !!this.state.supportersInTable && this.state.view === "table" ?
          <div style={{ width: '100%', display: 'block', textAlign: 'right' }}>
            <SupporterTable
              collection={'supporter'}
              query={{ _id: {$in: this.state.supportersInTable } }}
              // query={{ _id: { $in: [ "5e7008d390f6ea610ebe57d9", "5e7173a7d61cd4685ae4b34a" ] } }}
              count={ this.state.supportersInTable.length }
              setCount={ () => {}}
              setCampaignSupporter={ (supporter) => {
                this.setState({ supporter });
                this.props.setSupporter( supporter );
                this.props.jumpToStep(1);
              } }
              actions={ ['useForCampaign'] }
            />
            <Row>
            <Button
              className="mx-2"
              onClick={ e => {
                e.preventDefault();
                this.setState({
                  view: 'create'
                });
              }}
              >Create New Supporter</Button>
             <Button
              onClick={ e => {
                e.preventDefault();
                this.setState({
                  supporter: this.state.scannedSupporter,
                  view: 'create'
                });
              }}
              >Create New Supporter From Scan</Button>
            </Row>
          </div>: '';


          //the conditionnal for the 3 components to display "create", "show" or "edit"
          const createSupporter = this.state.view === "create" ?
            <CreateSupporter
              supporter={this.state.supporter || {}}
              changeView={this.changeView}
              setSupporterAfterCreate={this.setSupporterAfterCreate}
              preventRedirect={ true }
            /> : "";

          const displaySupporter = !!this.state.supporter && this.state.view === "show" && !!this.state.supporter.firstName ?
            <Card className={"p-4"}>
              <h3>{this.state.supporter.firstName} {this.state.supporter.lastName} {!!this.state.supporter.isDeceased && "(deceased)"} {!!this.state.supporter.isArchived && "(archived)"} {!!this.state.supporter.isVolunteer && "(volunteer)"} </h3>
              <ShowSupporterDetails
                supporterData={this.state.supporter}
                changeView={this.changeView}
              />
            </Card>
            :
            "";

          return(
            <Card className="p-4 my-3">
              <Row>
              <Col className="form-group" disabled sm={8}>
                <label htmlFor="search-by">Search by:</label> <br/>
                <FormRadio
                  inline
                  name="search-by"
                  checked={this.state.searchBy === "PIN"}
                  onChange={() => {
                    this.setSearchBy("PIN");
                  }}
                >PIN
                </FormRadio>
                <FormRadio
                    inline
                    name="search-by"
                    checked={this.state.searchBy === "ENSupporterId"}
                    onChange={() => {
                      this.setSearchBy("ENSupporterId");
                    }}
                  >
                    Engaging Networks ID
                  </FormRadio>
                  <FormRadio
                      inline
                      name="search-by"
                      checked={this.state.searchBy === "barcode"}
                      onChange={() => {
                       this.setSearchBy("barcode");
                      }}
                  >Scan Barcode</FormRadio>
                </Col>
              </Row>
              <Row>
                  <Col className="form-group" disabled sm={5}>
                    { this.state.searchBy != 'barcode' && <InputGroup>
                       <FormInput
                         id="supporterPIN"
                         type="text"
                         value={this.state.searchVal}
                         placeholder={this.state.searchBy}
                         onChange={e => {
                           this.onChange(e.target.value);
                         }}
                         // MUST use onKeyDown to prevent stepzilla from overriding return key submit
                         onKeyDown={this.onKeyPress}
                         innerRef={(input) => { if( !!input ) { input.focus() };}}
                    />
                    <InputGroupAddon type="append">
                      <Button
                        type='submit'
                        onClick={(e) => {
                          e.preventDefault();
                          if ( !this.state.searchVal.length ) return;
                          return this.submit()
                        }}>
                        Search
                      </Button>
                    </InputGroupAddon>
                  </InputGroup> ||
                    <div style={{display: 'block', width: '100%', }}>
                    <span style={{'fontSize': '1.5em', 'fontWeight': 'bold'}}>Scan when ready</span>
                    <DebounceInput
                        element={ FormInput }
                        //don't submit search request more than every 100ms
                        debounceTimeout={100}
                        name='scan-results'
                        style={ { opacity: 0, height: '0.1px' } }
                        innerRef={(input) => { if( !!input ) { input.focus() };}}
                        onChange={ this.handleScan }
                      />
                  </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {supporterTable}
                  {createSupporter}
                  {displaySupporter}
                </Col>
              </Row>
              {!!this.state.showCreate && <Row>
                <Button
                className="mx-3"
                onClick={ e => {
                  e.preventDefault();
                  this.setState({
                    view: 'create',
                    showCreate : false
                  });
                }}
                >Create New Supporter</Button>
              </Row>
              || ""}
            </Card>
          )
        }}
      </Query>
    )
  }
};
