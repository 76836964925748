import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { compact, range, first, findWhere } from 'underscore';
import numeral from 'numeral';
import moment from "moment";

import ActivePACWidget from './sharedWidgets/ActivePAC';
import MailComparisonTable from './MailComparisonTable';

import { Card, CardHeader, CardBody, CardFooter, Row, Col, FormSelect, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'shards-react';

import ColumnChart   from "../common/ColumnChart";
import CountTotalToggle from './CountTotalToggle.js';

function calculateOTGChannelTotal(channelData) {;
  const OTGTotal = channelData.OTG.cheque.total + channelData.OTG.card.total;
  return OTGTotal / 100;
}

function calculateOTGChannelCount(channelData) {
  const OTGCount = channelData.OTG.cheque.count + channelData.OTG.card.count;
  return OTGCount;
}

const Channels = ({ data }) => {
  const [ revenueSummaryGrouping, setRevenueSummaryGrouping ] = useState('total');
  const [ lastChartUpdated  , setLastChartUpdated ] = useState(null);
  const [ mailDropdownOpen, setMailDropdownOpen ] = useState(false);
  const [ mailCompare, setMailCompare ] = useState(null);

  const [selectedMonth, setSelectedMonth ] = useState(0);

  if(!data || !data.length) {
    return <p>No Data to show</p>
  }

//dropdown for selecting the month of the Top Channels Card

const dropdownMonths = () => {
  return <FormSelect
   id="monthSelection"
   defaultValue={selectedMonth}
   onChange={ e => {
     setSelectedMonth(e.target.value)
   }}

  >
  {data.map((entry, i) => {

    return <option key={"" + entry.year + entry.month} value={i}>
      {`${moment("" + entry.month).format("MMMM")} ${entry.year}`}
    </option>
  })}

  </FormSelect>
}

function toggleMailDropdown() {
  setMailDropdownOpen( !mailDropdownOpen );
}


  return (
    <div className='mb-4'>
      <Row className="mb-4">
        <Col>
          <ColumnChart
            title="Revenue Summary"
            height={200}
            showFilters={false}
            chartName='allRevenue'
            valueType={ revenueSummaryGrouping == 'total' && 'money' || 'number' }
            filters={<CountTotalToggle activeItem={revenueSummaryGrouping} clickHandler={() => {
                const currentState = revenueSummaryGrouping;
                const nextState    = currentState == 'count' && 'total' || 'count';
                setRevenueSummaryGrouping(nextState);
                setLastChartUpdated('allRevenue');
              }}/>}
            lastChartUpdated = { lastChartUpdated }
            chartData={{
                //reverse because data is sorted by date descending and graphs are rendered the other way around.
                labels: data.map( report => report.year + "-" + report.month ).reverse(),
                datasets: [{
                  label: "Digital",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const channelData = !!data[i] && !!data[i].channelData && findWhere(data[i].channelData, {mode : "digital"});

                  return !!channelData && ( channelData.OTG.cheque[revenueSummaryGrouping] + channelData.OTG.card[revenueSummaryGrouping] + channelData.PAC.gained[revenueSummaryGrouping] + channelData.PAC.upgrades[revenueSummaryGrouping]) / divideBy || 0;
                })).reverse(),
                  backgroundColor: "rgba(0, 123, 255, 1)",
                  borderColor: "rgba(0, 123, 255, 1)",
                  pointBackgroundColor: "#FFFFFF",
                  pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                  borderWidth: 1.5
                },
                {
                  label: "Telephone",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const channelData = !!data[i] && !!data[i].channelData && findWhere(data[i].channelData, {mode : "telephone"});
                  return !!channelData && ( channelData.OTG.cheque[revenueSummaryGrouping] + channelData.OTG.card[revenueSummaryGrouping] + channelData.PAC.gained[revenueSummaryGrouping] + channelData.PAC.upgrades[revenueSummaryGrouping]) / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(255,165,0, 1)",
                borderColor: "rgba(255,165,0, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(72, 160, 255, 1)",
                borderWidth: 1.5
                },
                {
                  label: "House Mail",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const channelData = !!data[i] && !!data[i].channelData && findWhere(data[i].channelData, {mode : "house mail"});
                  return !!channelData && ( channelData.OTG.cheque[revenueSummaryGrouping] + channelData.OTG.card[revenueSummaryGrouping] ) / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(63, 195, 128, 1)",
                borderColor: "rgba(63, 195, 128, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(72, 160, 255, 1)",
                borderWidth: 1.5
                },                {
                  label: "Prospect Mail",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const channelData = !!data[i] && !!data[i].channelData && findWhere(data[i].channelData, {mode : "prospect mail"});
                  return !!channelData && ( channelData.OTG.cheque[revenueSummaryGrouping] + channelData.OTG.card[revenueSummaryGrouping] ) / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(138,43,226, 1)",
                borderColor: "rgba(138,43,226, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(72, 160, 255, 1)",
                borderWidth: 1.5
                },                {
                  label: "Unlinked",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const channelData = !!data[i] && !!data[i].channelData && findWhere(data[i].channelData, {mode : "unlinked"});
                  return !!channelData && ( channelData.OTG.cheque[revenueSummaryGrouping] + channelData.OTG.card[revenueSummaryGrouping] ) / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(169,169,169, 1)",
                borderColor: "rgba(169,169,169, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(72, 160, 255, 1)",
                borderWidth: 1.5
                }
            ]
          }}/>
        </Col>
        <Col className="mt-4">
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Channels </h6>
              {dropdownMonths()}
            </CardHeader>
            <CardBody className='py-2'>
              <div className='table-responsive'>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th className='border-top-0'>Name</th>
                      <th className='border-top-0'>PAC Gains</th>
                      <th className='border-top-0'>PAC Upgrades</th>
                      <th className='border-top-0'>OTG</th>
                    </tr>
                  </thead>
                  <tbody>
                  { data[selectedMonth].channelData.sort( (a, b) => {
                    return (b.PAC.gained.total + b.PAC.upgrades.total + b.OTG.cheque.total + b.OTG.card.total) - (a.PAC.gained.total + a.PAC.upgrades.total + a.OTG.cheque.total + a.OTG.card.total)
                    } ).map( record => {
                      const selector = record.mode != 'unlinked' && { mode: { $in: [ record.mode ] } } || null;
                      const url = !!selector && '/campaign?selector=' + window.btoa( JSON.stringify( selector ) ) + "&skip=0" || '#';
                      return (
                        <tr key={record.mode}>
                          <td><Link to={url}>{record.mode || 'unlinked'}</Link></td>
                          <td>{numeral( record.PAC.gained.total / 100 ).format("$0,00")} ({record.PAC.gained.count})</td>
                          <td>{numeral( record.PAC.upgrades.total / 100).format("$0,00")} ({record.PAC.upgrades.count})</td>
                          <td>{numeral( (record.OTG.cheque.total + record.OTG.card.total) / 100).format("$0,00")} ({record.OTG.cheque.count + record.OTG.card.count })</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
            <CardFooter className="border-top">
              <Link className='btn btn-primary' theme="primary" to='/campaign' size='sm'>View all</Link>
            </CardFooter>
          </Card>

        </Col>
      </Row>
      {/* 
      <Row>
      <Col>
        <Card>
            <Dropdown open={mailDropdownOpen} toggle={toggleMailDropdown}>
               <DropdownToggle theme="primary">Compare Recent Mailings</DropdownToggle>
               <DropdownMenu>
                 <DropdownItem onClick={(e) => {e.preventDefault(); setMailCompare('house mail') }}>
                    House
                 </DropdownItem>
                 <DropdownItem onClick={(e) => {e.preventDefault(); setMailCompare('propsect mail') }}>
                    Prospect
                 </DropdownItem>
               </DropdownMenu>
             </Dropdown>
        </Card>
      </Col>
      </Row>
      {
        !!setMailCompare
        ?

        <MailComparisonTable type={mailCompare} />

        : ""
      } */}
  </div>
  )

}

export default Channels;
