import React, {useState, useEffect, Fragment} from 'react';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import { useQuery } from '@apollo/react-hooks';
import { extend } from "underscore";
import gql from 'graphql-tag';
import numeral from 'numeral';
import ReactTable from 'react-table';


import QueryBuilder from '../QueryBuilder';

import ExportButton from "../ExportButton";
import UploadButton from "../UploadButton";
import importCollections from "../UploadButton/importCollections";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Badge,
  FormCheckbox,
  ButtonGroup,
  Button,
  Row,
  Col,
} from "shards-react";

import BottomActionBar from '../BottomActionBar.js';
const Url = require('url-parse');


const CollectionTable = React.memo(function CollectionTable({ collectionName, viewProps, gqlQuery, getTrProps, columns, exportColumns, selector, projector, sort, tableSize = 20, displayButtons =[], hideHeader, setParentVariables }){

  //get the name of the query from the query itself. Useful in many other contexts below, especially patching in new data
  const queryKey = gqlQuery.definitions[0].name.value;

  // get any stashed params passed through url
  let params            = !!viewProps && new Url(viewProps.location.search, true);
  let passedQueryString = !!params && params.query && params.query.selector || "";
  let parsedSelector;


  //decode ecoded params string and convert to object
  if( !!passedQueryString.length ) {
    parsedSelector = JSON.parse( window.atob( passedQueryString ) );
  }

  // set the query by default
  const [ loading, setLoading ] = useState(true);
  const [ pages  , setPages ] = useState(0);
  const [ skip, setSkip ] = useState( !!params && !!params.query.skip && parseInt(params.query.skip) || 0 );

  const [ query, setQuery ] = useState( !!parsedSelector && parsedSelector || selector || {} );

  // //encode the query in the url history so we can use the back button
  useEffect( () => {
    if ( !!viewProps && !!query && typeof query == 'object' && !!Object.keys(query).length  ) {
      viewProps.history.push(`${window.location.pathname}?selector=${window.btoa( JSON.stringify( query ) )}&skip=${skip}`);
    }

  }, [query, skip]);

  // set the rest of the local state


  const opts = {
    variables: {
      selector : query,
      projector,
      limit    : (tableSize > query.limit) ? parseInt(query.limit) : tableSize,
      skip     : skip,
      sort,
      //hack using riding codes to ensure this actually reruns when the codes change
      cacheBuster : !!query.ridingCode && !!query.ridingCode.$in && query.ridingCode.$in.reduce( (tally, val) => tally + val, 0 ) || 0
    },
    fetchPolicy : "network-only"
    };

  //use passed opts but no matter what happens, set loading to false when query is complete
  const { queryLoading, data, error, fetchMore } = useQuery(gqlQuery, extend(opts, {
    onCompleted : () => {
      setLoading(false);
      !!setParentVariables && setParentVariables({
        variables: opts.variables,
        count: !!data && !!data[queryKey] && data[queryKey].count || null
      });
    }
  }));

  const recordCountString = !!data && !!data[queryKey] && !!data[queryKey].count && `${numeral(data[queryKey].count).format('0,0')} records`;

  //high end of range cannot exceed number of records on screen
  let maxOfRange;
  if ( !!data && !!data[queryKey]  ) {
    if( ( (skip + 1 + tableSize )  <= data[queryKey].count ) ) {
      maxOfRange = (skip + 1 + tableSize )
    } else {
      maxOfRange = data[queryKey].count;
    }
  }

  const recordRangeString = !!data && !!data[queryKey] && !!data[queryKey].count && `${ numeral(skip + 1).format('0,0') } to ${ numeral( maxOfRange ).format('0,0') }`;
  return (
    <div>
      {!hideHeader && <CardHeader>
        <Row className="justify-content-md-center">
          <Col>
            {/* Query builder */}
            {displayButtons.includes("filter") &&
            <QueryBuilder
              collection={collectionName}

              // pass initial value for reset
              defaultSelector={selector}
              selector={query}
              setSkip={ setSkip }
              setLoading={setLoading}
              setSelector={setQuery}
            />
            || ""}
          </Col>
          <Col>
          <h6 className="text-center">{
            !!data && !!data[queryKey] && !!data[queryKey].count && `${recordRangeString} of ${recordCountString}`
            || 'No matches'
          }</h6>
          </Col>
          <Col className='text-right'>

            {/* Create button */}
            {displayButtons.includes("new") &&
              <Link to={`${window.location.pathname}/create`} className='mr-1 btn btn-primary'><i className='fa fa-plus'></i>&nbsp; New</Link>
            || ""}

            {/* Upload */}
            {displayButtons.includes("upload") &&
            <UploadButton
                collection={ collectionName }
                formUpload= {importCollections[collectionName]}
              />
              || ""}

            {/* Export */}
            {displayButtons.includes("export") &&
            <ExportButton
                columnOptions={exportColumns}
                queryReport={Object.assign({}, query, { collection: collectionName })}
                columns={exportColumns}
                projector={{}}
                collection={collectionName}
                name={ `Export ${collectionName}`}
              />
            || ""}
          </Col>
        </Row>
      </CardHeader> || ""}

      {/* The actual table */}
      <CardBody className="p-0">
        <ReactTable
          data={!!data && !!data[queryKey] ? data[queryKey][collectionName] : []}
          columns={columns}
          loading={loading}
          defaultPageSize={ tableSize }
          getTrProps={getTrProps}
          page={ !!skip && skip / tableSize || 0 }
          showPageJump = {false}
          showPageSizeOptions={false}
          manualPagination={true}
          manual
          noDataText={`No ${collectionName} to display`}
          pages={ pages }
          renderTotalPagesCount={ pages => numeral(pages).format('0,0') }

          //All sorting and pagination changes handled here
          onFetchData={(state, instance) =>  {
            //use sort object with default being createdDate desc
            //table maintains its own sort state so we simply pass that value to fetchMore
            let sortObj = !!state.sorted && !!state.sorted.length && {} || sort;
            state.sorted.forEach( (sortField, i) => {
              //clear the slate on first pass
              if ( i == 0 ) {
                sortObj = {};
              }
              sortObj[sortField.id] = !!sortField.desc && -1 || 1;
            });
            //tell the table we are loading
            setLoading(true);
            fetchMore({
              variables: {
                skip: state.pageSize * state.page,
                limit: state.pageSize,
                sort: !!Object.keys(sortObj).length && sortObj || sort
              },
              updateQuery: (prev = {}, { fetchMoreResult }) => {
                setSkip( state.pageSize * state.page )



                if ( !prev[queryKey] ) {
                  prev[queryKey] = {
                    count: data.count,
                    [collectionName]: []
                  };
                }

                //update the data
                if (!fetchMoreResult) {
                  setPages(Math.ceil(prev[queryKey].count / state.pageSize));
                  return prev;
                }
                setPages(Math.ceil(fetchMoreResult[queryKey].count / state.pageSize));
                const newObj = {
                  [queryKey]: {
                    ...prev[queryKey],
                    [collectionName] : fetchMoreResult[queryKey][collectionName]
                  }
                };
                //disable loading state
                setLoading(false);

                return Object.assign({}, prev, newObj);
              }
            });
          }}
        />
      </CardBody>
    </div>
  );
});

export default CollectionTable;
