import React, { Component, useState } from "react";
import ShowSteps from "../ShowSteps";
import CampaignStep0 from "./CampaignStep0";
import CampaignStep1 from "./CampaignStep1";
import CampaignStep2 from "./CampaignStep2";
import CampaignStep3 from "./CampaignStep3";
import CampaignStepReview from "./CampaignStepReview";
import {GET_SUPPORTER} from "../gql";

import { useQuery } from '@apollo/react-hooks';
import _ from "underscore";
import "./CampaignSteps.css";

import { Col, Row } from "shards-react";

import StepZilla from "react-stepzilla";
const Url = require('url-parse');

export default class CampaignSteps extends Component {

  constructor(props) {
    super(props);
    this.state = {
      campaign : this.props.campaign,
      supporter : this.props.supporter,
      user : this.props.user,
      gifts: [ ],
      step : 0,
      jumpTo : null
    };
  }

  setSupporter = (update) => {
    this.setState({supporter : update})
    if(!!update && !!update._id) {
      this.props.history.push(`${this.props.location.pathname}?view=wizard&_id=${update._id}`)
    }

  }

  setStep = (step) => {
    this.setState({step});
  }

  registerGift = ({ type, amount, method = null, cardInfo = null }) => {
    this.setState({
      gifts: [ ...this.state.gifts, { type, amount, method, cardInfo } ]
    });
  }
  resetGifts = () => {
    this.setState({ gifts : []})
  }

  // opts = {
  //   variables : {
  //     supporterId : store.supporter._id,
  //     actionSelector,
  //     actionLimit,
  //     actionSkip,
  //     actionSort
  //   },
  //   fetchPolicy: "network-only"
  // }

  setJumpTo = (step) => {
    this.setState({jumpTo : step});
  }

  render () {

    const steps = [
      {name: 'Search Supporter', component: <CampaignStep0 store={this.state} setSupporter={this.setSupporter} setStep={this.setStep} />},
      {name: 'Confirm Information', component: <CampaignStep1 store={this.state} setSupporter={this.setSupporter} setStep={this.setStep} />},
      {name: 'Monthly Donations', component: <CampaignStep2 store={this.state} registerGift={ this.registerGift } setStep={this.setStep} />},
      {name: 'One-time Donations', component: <CampaignStep3 store={this.state} registerGift={ this.registerGift } setStep={this.setStep} />},
      {name: 'Next', component: <CampaignStepReview store={this.state} setSupporter={this.setSupporter} setStep={this.setStep} />}
    ];


    let params = new Url(this.props.location.search, true);
    const supporterId = params.query._id || null;

    return <Steps
      steps={steps}
      setSupporter={this.setSupporter}
      store={this.state}
      supporterId={supporterId}
      actualStep={this.state.step}
      jumpTo={this.state.jumpTo}
      setJumpTo={this.setJumpTo}
      />

  }
};


const Steps = ({steps, actualStep, supporterId, store, setSupporter, jumpTo, setJumpTo}) => {

  const [actionSelector, setActionSelector] = useState({});
  const [actionLimit, setActionLimit]       = useState(5);
  const [actionSkip, setActionSkip]         = useState(0);
  const [actionSort, setActionSort]         = useState({date : -1});

  const opts = {
    variables : {
      supporterId,
      actionSelector,
      actionLimit,
      actionSkip,
      actionSort
    },
    skip : !supporterId,
    fetchPolicy: "network-only"
  }
  const {data, loading, error} = useQuery(GET_SUPPORTER, opts);
  {/* if(loading) {
    return <Loading />
  } */}
  const supporter = !!data && !!data.getSupporter && data.getSupporter;

  if(!!data && !_.isMatch(store.supporter, supporter)){
    setSupporter(supporter)
  }



  return(
      <Row>
        <Col md={8}>
          <div className="p-4">
            <div className="step-progress">
              <StepZilla
                steps = {steps}
                showSteps = {false}
                // startAtStep = {!!this.props.step && this.props.step || 0}
                nextButtonCls = "btn btn-prev btn-primary btn-md float-right mx-2"
                backButtonText = {<span><i className="fas fa-chevron-left"></i> Back</span>}
                nextButtonText = {<span>Next <i className="fas fa-chevron-right"></i></span>}
                backButtonCls = "btn btn-prev btn-outline-primary btn-md float-left mx-2"
                stepsNavigation = { false }
                preventEnterSubmission = { true }
              />
            </div>
          </div>
        </Col>
        <Col className="mt-4" md={4}>
          <div className="mt-4">
            <ShowSteps
              steps={steps}
              actualStep={actualStep}
              setJumpTo={setJumpTo}
            />
          </div>

        </Col>
      </Row>

    )
}
