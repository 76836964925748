import React, { useState, Fragment } from "react";
import {Form} from 'react-bootstrap';

import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  ButtonGroup
} from "shards-react";

import FormSectionTitle from "../components/edit-user-profile/FormSectionTitle";
import { toast } from "react-toastify";
import gql from 'graphql-tag';


const CampaignForm = ({ campaign, createCampaign, updateCampaign }) => {


  const [newCampaign, setNewCampaign] = useState(!!campaign ? campaign : {
    nickname    : "",
    description : "",
    mode        : "telephone"
    });


  const [modifier, setModifier] = useState({});

  const userInStorage = localStorage.getItem('user');

console.log(typeof(userInStorage));
  
  var user = JSON.parse(userInStorage);

  var currentCampaignIsEnded = false
    if (!!campaign) {
      currentCampaignIsEnded = campaign.isEnded
    }
    
  const button = !!campaign ?
  <>
  <Button
    onClick={() => {
      updateCampaign({
        variables : {
        selector : {_id : campaign._id},
        modifier : {$set: modifier}
        },
        refetchQueries : ['getCampaign']
      });
    }}>
    Update Campaign
  </Button>
  {user.gatewayRole == "super-admin" && !currentCampaignIsEnded &&

  <Button style={{ background: 'yellow', color: 'black', marginLeft: '10px' }}
  onClick={() => {

    const conf = window.confirm(`Are you sure you want to end the campaign?`);

    if ( !conf )  {
    return toast.warning(`Cancelled action`);
    }

    modifier.isEnded = true;
    updateCampaign({
      variables : {
      selector : {_id : campaign._id},
      modifier : {$set: modifier}
      },
      refetchQueries : ['getCampaign']
    });
  }}>
  End Campaign 
</Button> 
} 



{user.gatewayRole == "super-admin" && currentCampaignIsEnded &&

  <Button style={{ background: 'yellow', color: 'black', marginLeft: '10px' }}
  onClick={() => {

    const conf = window.confirm(`Are you sure you want to reopen the campaign?`);

    if ( !conf )  {
    return toast.warning(`Cancelled action`);
    }

    modifier.isEnded = false;
    updateCampaign({
      variables : {
      selector : {_id : campaign._id},
      modifier : {$set: modifier}
      },
      refetchQueries : ['getCampaign']
    });
  }}>
  Reopen Campaign 
</Button> 
} 
</>
  
  : <Button
  onClick={() => {

    const campaign = newCampaign;

    campaign.isEnded = false;
    createCampaign({
      variables : {campaign}
    })
  }}>
  Create Campaign
  </Button>;


  return (
    <Card small className="mb-4">
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* NickName */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Name</label>
                    <FormInput
                      id="feFName"
                      placeholder="nickname"
                      value={newCampaign.nickname}
                      onChange={(e) => {
                        setNewCampaign({...newCampaign, nickname : e.target.value })
                        if(!!campaign) {
                          setModifier({...modifier, nickname : e.target.value})
                        }
                      }}
                    />
                  </Col>
                  {/* Mode */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feMode">Mode</label>
                    <FormSelect
                      id="feMode"
                      placeholder="mode"
                      value={newCampaign.mode}
                      onChange={(e) => {
                        setNewCampaign({...newCampaign, mode : e.target.value })
                        if(!!campaign) {
                          setModifier({...modifier, mode : e.target.value})
                        }
                        }}
                    >
                    <option>telephone</option>
                    <option>digital</option>
                    <option>house mail</option>
                    <option>prospect mail</option>
                    <option>PAC</option>
                    </FormSelect>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feEmail">Description</label>
                    <Form.Control
                      as="textarea"
                      id="feDescription"
                      placeholder="Description"
                      value={ newCampaign.description}
                      onChange={(e) => {
                        setNewCampaign({...newCampaign, description : e.target.value })
                        if(!!campaign) {
                          setModifier({...modifier, description : e.target.value})
                        }
                        }}
                    />
                  </Col>

                </Row>

                <Row form>
                  <Col md="6" className="form-group">
                    {button}

                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>


        </ListGroupItem>
      </ListGroup>

    </Card>
  )
};


export default CampaignForm;
