import React, { useState, Fragment } from "react";
import { Mutation } from "react-apollo";
import {
  Card,

  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  ButtonGroup
} from "shards-react";

import { toast } from "react-toastify";
import gql from 'graphql-tag';

export const REMOVE_SUPPORTER = gql`
  mutation deleteSupporter($_id: ID, $modifier: Modifier) {
    deleteSupporter(_id: $_id, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

const RemoveSupporter = ({supporter}) => {

  const [password, setPassword] = useState("");

  const removeSupporterButton = {
    color: "white",
    backgroundColor: "#a70000",
    borderColor: "red",
    marginTop: "25px"
  };

  function removeSupporterMouseOver(event) {
    event.target.style.boxShadow = "0 5px 15px rgb(0 0 0 / 5%), 0 4px 10px rgb(255 123 0 / 25%)";

  }
  function removeSupporterMouseOut(event){
    event.target.style.boxShadow = "0px 0px 0px";
  }



  return (
    <Mutation
      mutation={REMOVE_SUPPORTER}
      onError={(e) => {



        console.log("Error removing supporter")
        console.log({e})
        toast.warning(e);

      }}
      onCompleted={() => {
          
        console.log("SUCCESS removing supporter")
        toast.success("Archiving.");
        //toast.success("Supporter deleted. Data will be cached and visible temporarily.");
      }}
      >
      {(deleteSupporter, {data, loading, error}) => {
        return (
          <Fragment>

          <Row form className="border-top pt-2 mt-4">
            <Col md="6" className="form-group">
            <Button 
              disabled
              outline
              style={removeSupporterButton}
              onMouseOver={removeSupporterMouseOver} 
              onMouseOut={removeSupporterMouseOut}
              onClick={()=> {
                if (window.confirm("Remove Supporter? This action is permanent.")) { 
                const modifier = { };

               deleteSupporter({
                  variables : {
                  _id       : supporter._id,
                  modifier
                  }
                })
              }}}>
              Archive Supporter
            </Button>

            </Col>
            </Row>

          </Fragment> 
        )
      }}

    </Mutation>
  )

};

export default RemoveSupporter;