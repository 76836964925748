import React, { useState } from "react";
import {
  Container,
  Row,
  Card
} from "shards-react";
import { Link } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';
import CollectionTable from '../friendsComponents/CollectionTable';
// import Loading from "../friendsComponents/Loading";
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import moment from 'moment';
import _ from "underscore";

export const GET_CAMPAIGNS = gql`
  query getCampaigns($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getCampaigns(selector: $selector, limit: $limit, skip: $skip, sort: $sort){
        count
        campaigns {
          _id
          nickname
          isEnded
          description
          mode
          createdDate
        }
      }
    }
`;

const Campaigns = (props) => {
  SetPageMetas({ title: "Campaigns" });
  const user = JSON.parse(localStorage.getItem("user"));
  const hasAdminRights = !!_.contains(user.roles.campaign, "admin") || !!_.contains(user.roles.campaign, "super-admin") || false;
  let defaultSelector = {};

  if ( !!_.contains(user.roles.campaign, "caller")  ) {
    defaultSelector = { mode: "telephone" };
  }

  const columns = [{
    Header    : 'Name',
    className : "text-center",
    accessor  : 'nickname'
  },
  {
    Header    : 'Description',
    className : "text-center",
    accessor  : 'description'
  },
  {
    Header    : 'mode',
    className : "text-center",
    accessor  : 'mode'
  },
  {
    Header    : 'Created',
    className : "text-center",
    accessor  : 'createdDate',
    Cell: row => {
      return moment(row.original.createdDate).format('lll')
    }
  },
  {
    Header   : 'Actions',
    accessor : "actions",

    sortable : false,
    Cell     : row => {

      return <Link
        to={`/campaign/${row.original._id}`}
        className='btn btn-white'
        >
        Details
      </Link>
    }
  }]

  const opts = {
    variables : {
    selector : defaultSelector,
    limit : 20,
    skip : 0,
    sort: {createdDate: -1 }
  },
  fetchPolicy : "network-only",
  onError : (e) => {
    toast.error("There was a problem");
  }
};

  const displayButtons = () => {
    const buttons = ["filter"];

    if(!!hasAdminRights) {
      buttons.push("new");
    }
    return buttons
  };


  return (

  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Campaigns" subtitle="list" className="text-sm-left"/>
    </Row>
    <Card small className="mb-4">

    <CollectionTable
          collectionName='campaigns'
          gqlQuery={GET_CAMPAIGNS}
          gqlQueryOpts={opts}
          columns={columns}
          viewProps={props}
          sort={{createdDate : -1 }}
          displayButtons={displayButtons()}
        />
    </Card>

  </Container>
)};

export default Campaigns;
