import React, { useState, useEffect, Fragment } from "react";
import _ from "underscore";

import {
  Container,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "shards-react";

import fields from "./fields";
import FieldRow from "./FieldRow";

import "./style.scss";

const QueryFilter = ({ defaultSelector = {}, selector = {}, setSelector, setSkip, collection = "supporters", setLoading }) => {
  // selector is the main output of the component
  const [selectorInner, setSelectorInner] = useState(!!selector && !!Object.keys(selector).length && selector || defaultSelector);
  const [open, toggleOpen] = useState(false);

  //Not sure why Sylvain passes "actions" as collName but calls it transatctions in the fields object
  //Doing this as a hack just in case changing collName to transactions breaks something elsewhere
  const collectionFields = collection == "supporters" && fields[collection] || fields['transactions'];

  const [categories, setCategories] = useState(!!collectionFields && Object.keys(collectionFields));

  //when selectorInner changes, set table loading state
  useEffect( () => {
    if ( !!setLoading ) {
      setLoading(true);
    }
  }, [selector]);


  const togglePopover = function (e) {
    toggleOpen(!open);
  };

  const triggerSubmit = (e) => {
    //set the actual selector to the value of the internal selector
    setSelector(selectorInner);
    //reset skip on submit
    setSkip(0);
    toggleOpen(!open);
  };

  return (
      <div>
          {/* The builder UI itself. Render one row for each field */}
          {Object.keys(collectionFields).map((key, index) => {
            const fields = collectionFields[key];

            return (
              <Fragment key={`frag-row-${key}`}>

                {/* Show border except on first child */}
                {(index > 0 && (
                  <span
                    style={{
                      width: "115%",
                      marginLeft: -30,
                    }}
                    className="border-top border d-block my-2"
                  >
                  </span>
                )) ||
                  ""}

                <FieldRow
                  // React key for multiple instances
                  key={`field-row-${key}`}
                  //keyName the component can actually use
                  keyName={key}
                  //the full field config
                  fields={fields}
                  //current selector
                  selector={selector}
                  //hook to set selector
                  setSelector={setSelector}

                  triggerSubmit = {triggerSubmit}
                />

              </Fragment>

            );
          })}
        {/* Show the selector in dev for debugging */}
        { process.env.REACT_APP_NODE_ENV === 'development' &&  JSON.stringify(selectorInner) || '' }
        { process.env.REACT_APP_NODE_ENV === 'development' &&  JSON.stringify(selector) || '' }
    </div>
  );
};

export default QueryFilter;
