'use strict';

/*
  Define operators here.
  The inner components recognize operators by their key.

  Operator
  generateLabel [function] Takes the field object as an argument. Returns the appropriate label for a given field type
  rawValue [function] returns the unformatted current value
  hander [function] returns a formatted selector using the current value

*/

import { uniq, isDate } from 'underscore';
import moment from 'moment';

const config = {
  operators: {
    'equals': {
      generateLabel(field) {
        return 'Equals';
      },
      rawValue( val ) {
        return val;
      },
      handler(field, val) {
        return { [field]: val };
      }
    },

    'not_equal': {
      generateLabel(field) {
        return 'Not equal';
      },
      rawValue( val ) {
        return val['$ne'];
      },
      handler(field, val) {
        return { [field]: { $ne: val }};
      }
    },
    'starts_with': {
      generateLabel(field) {
        return 'Starts with';
      },
      rawValue( val ) {
        return val['$regex'].replace("\^", "");
      },
      handler(field, val) {
        return { [field]: { $regex: `^${val}` }};
      }
    },

    'ends_with': {
      generateLabel(field) {
        return 'Ends with';
      },
      rawValue( val ) {
        return val['$regex'].replace(".*", "").replace("\$", "");
      },
      handler(field, val) {

        // var stringToGoIntoTheRegex = val;
        // var regex = new RegExp("\." + stringToGoIntoTheRegex + "$");
        // at this point, the line above is the same as: var regex = /#abc#/g;

        return { [field]: { $regex: ".*" + val + "$", $options: 'i' } };
      }
    },

    'greater_or_equal': {
      generateLabel(field) {
        if ( field.type == 'date' ) {
          return "After";
        }
        return 'Greater than or equals';
      },
      rawValue( val ) {
        return val['$gte'];
      },
      handler(field, val) {

        const returnObject = { [field]: { $gte: val } };

        if ( isDate(val) ) {
          returnObject[field].$gte = moment( val ).startOf('day').toDate();
        }

        return returnObject;
      }
    },

    'less_or_equal': {
      generateLabel(field) {
        if ( field.type == 'date' ) {
          return "Before";
        }
        return 'Less than or equals';
      },
      rawValue( val ) {
        return val['$lte'];
      },
      handler(field, val) {
        const returnObject = { [field]: { $lte: val } };

        if ( isDate(val) ) {
          console.log('val is date in before');
          returnObject[field].$lte = moment( val ).endOf('day').toDate();
        }

        return returnObject;
      }
    },

    'date_on': {
      generateLabel(field) {
        return 'On';
      },
      rawValue( val ) {
        return val['$gte'];
      },
      handler(field, val) {
        const startOfDay = moment( val ).startOf('day').toDate();
        const endOfDay   = moment( val ).endOf('day').toDate();
        return { [field]: { $gte: startOfDay, $lte: endOfDay } };
      }
    },

    'between': {
      generateLabel(field) {
        return 'Between';
      },
      rawValue( val ) {
        //return the min figure to undo
        return val['$gte'];
      },
      handler(field, val1, val2) {
        const returnObject = { [field]: {} };
        if ( !!val1 || val1 == 0 ) {

          if ( isDate(val1) ) {
            returnObject[field].$gte = moment(val1).startOf('day').toDate();
          } else {
            returnObject[field].$gte = val1;
          }
        }

        if ( !!val2 || val2 == 0 ) {
          if ( isDate(val2) ) {
            returnObject[field].$lte = moment(val2).endOf('day').toDate();
          } else {
            returnObject[field].$lte = val2;
          }
        }

        return returnObject;
      }
    },

    'any': {
      generateLabel(field) {
        return "Any of";
      },
      rawValue( val ) {
        return val['$in'];
      },
      handler(field, vals = []) {
        return { [field]: { $in: uniq(vals) } };
      }
    },
    'none': {
      generateLabel(field) {
        return "None of";
      },
      rawValue( val ) {
        return val['$nin'];
      },
      handler(field, vals = []) {
        return { [field]: { $nin: vals } };
      }
    }
  }
};

export default config;
