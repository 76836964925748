import React, {useState, Fragment} from "react";
import Loading from "./Loading";
import {Mutation} from "react-apollo";
import ProcessCharge from "./ProcessCharge";
import { toast } from 'react-toastify';
import SearchSelect  from "./SearchSelect.js"
import moment from "moment";

import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';


import {
  Row,
  Col,
  CardBody,
  FormCheckbox,
  FormSelect
} from "shards-react";

export const GET_CAMPAIGNS = gql`
  query getCampaigns($limit: Int, $skip: Int, $sort: GenericObject) {
    getCampaigns(limit: $limit, skip: $skip, sort: $sort) {
      count
      campaigns {
        _id
        nickname
        isEnded
        description
        mode
        createdDate
      }
    }
  }
`;


const OTPCampaignSelector = ({supporterId, default_source, setPendingAmount, sources, wizardRegisterGift, campaignWizard, handleCloseCharge, setGifts, handleShowEmailConfirmation, user}) => {
  
  //those are used when the component doesn't get a campaign passed through CanpaignStep variable
  const localCampaignState = localStorage.getItem('activeCampaign') === "true";
  const localCampaign = localStorage.getItem('campaign');

  const [activeCampaign, setActiveCampaign] = useState(localCampaignState);
  const [campaign, setCampaign] = useState(!!localCampaign && localCampaign !== "undefined" && JSON.parse(localCampaign) || {});

  const setResult = (value) => {
    saveCampaign(value);
    setCampaign(value);
  }

  const opts = {
    variables: {
      limit    : 40,
      skip     : 0,
      sort     : { }
    }
  };

  const gqlOpts = {
    variables: {
      selector : {},
      sort     : { createdDate: -1 }
    }
  };
  
  const {data, loading, error} = useQuery(GET_CAMPAIGNS, opts);
  

  const saveCampaignState = (boolean) => {
    localStorage.setItem('activeCampaign', boolean);
  }

  const saveCampaign = (campaign) => {
    if(typeof campaign === "object") {
      localStorage.setItem('campaign', JSON.stringify(campaign));
    }  
  }

  // const campaigns = !! data && data.getCampaigns.campaigns.map((campaign, i) => {
  //   return (
  //     <option
  //       key={i}
  //       value={JSON.stringify(campaign)}>
  //       {`${campaign.nickname} - ${moment(campaign.createdDate).format('ll')}`}
  //     </option>
  //   )
  // });

  if (loading) return(
    <Loading />
  );

  return (
    <CardBody>
    
      {/* if we're in campaign wizard view and a campaign is passed hide the campaign selector */}
      {!campaignWizard && <Row>
        <Col>
        <label>Is this donation part of a campaign?</label>
          <FormCheckbox
            toggle
            checked={activeCampaign}
            onChange={() => {
              setActiveCampaign(!activeCampaign)
              saveCampaignState(!activeCampaign)}}
            >
            Active Campaign
          </FormCheckbox>
        </Col>
        {!!activeCampaign && <Col>
        <label>Select the campaign:</label>
          <SearchSelect

            collectionName={"campaigns"}
            keyName={"nickname"}
            setResult={setResult}
            gqlQuery={GET_CAMPAIGNS}
            defaultValue={campaign }
            gqlOpts={gqlOpts}
            />

        </Col>
        || ""}
        
      </Row>
      || ""}

      <hr/>
    
      <ProcessCharge
        campaign={!!campaignWizard && campaignWizard || !!activeCampaign && !!campaign && campaign || null}
        supporterId={supporterId}
        default_source={default_source}
        sources={sources}
        setGifts={setGifts}
        wizardRegisterGift={wizardRegisterGift}
        handleShowEmailConfirmation={handleShowEmailConfirmation}
        handleCloseCharge={handleCloseCharge}
        user={user}
        setPendingAmount={setPendingAmount}
        />
    </CardBody>
  )
}

export default OTPCampaignSelector;