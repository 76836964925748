import React, { useState } from "react";
import {
  Container,
  Row,
  Card
} from "shards-react";
import { Link } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';
import CollectionTable from "../friendsComponents/CollectionTable";
import { toast } from 'react-toastify';
import gql from 'graphql-tag';


export const GET_USERS = gql`
  query getUsers($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getUsers(selector: $selector, limit: $limit, skip: $skip, sort: $sort){
       count
        users {
          _id
          firstName
          lastName
          createdDate
          gatewayRole
          emails {
            _id
            address
          }
        }
      }
    }
`;


const Users = (props) => {

  SetPageMetas({ title: 'Users' });

  const [limit ] = useState(20);

  const columns = [{
    Header    : 'First Name',
    className : "text-center",
    accessor  : 'firstName'
  },
  {
    Header    : 'Last Name',
    className : "text-center",
    accessor  : 'lastName'
  },
  {
    Header    : 'email',
    className : "text-center",
    accessor  : 'emails',
    Cell : row => row.original.emails[0].address
  },
  {
    Header    : 'Gateway Role',
    className : "text-center",
    accessor  : 'gatewayRole',

  }, {
    Header   : 'Actions',
    accessor : "actions",

    sortable : false,
    Cell     : row => {

      return <Link
        to={`/admin/users/${row.original._id}`}
        className='btn btn-white'
        >
        Edit
      </Link>
    }
  }]

  const opts = {
    variables : {
      selector : {},
      limit,
      skip : 0,
      sort: { createdDate: -1 }
    },
    fetchPolicy : "network-only",
    onError : (e) => {
      console.log(e);
      toast.error("There was a problem");
    }
  };

  return (

  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Users" subtitle="Admin" className="text-sm-left"/>

    </Row>
    <Card small className="mb-4">

      <CollectionTable
            collectionName='users'
            gqlQuery={GET_USERS}
            gqlQueryOpts={opts}
            columns={columns}
            viewProps={props}
            sort={{createdDate : -1 }}
            displayButtons={["filter", "new"]}
          />
      {/* </Card> */}


        {/* <Query
          query={GET_USERS}
          variables={{
            limit,
            skip : 0,
            sort: { createdDate: -1 }
          }}
          fetchPolicy="network-only"
          onError={(e) => {
            console.log(e);
            toast.error("There was a problem");
          }}
          >
          {({ data, loading, error, fetchMore }) => {


            if (error) return <p>ERROR: {error.message}</p>;

            return (
              <Card className="p-0">
                <Card className="p-0">
                  <CardHeader>
                  <h6 className="text-center">Users</h6>
                  </CardHeader>
                </Card>
                <CardBody className="p-0">
                  <ReactTable
                    data={!!data && !!data.getUsers && data.getUsers.users || []}
                    columns={columns}
                    loading={loading}
                    defaultPageSize={20}
                    pageIndex={0}
                    showPageJump={false}
                    manualPagination={true}
                    manual
                    noDataText={`No users to display`}
                    pages = { pages }
                    onFetchData={(state, instance) =>  {

                      //create sort object with default being createdDate desc
                      let sort = { createdDate: -1 }
                      state.sorted.forEach( (sortField, i) => {
                        //if we have anything here, overwrite default
                        if ( i == 0 ) {
                          sort = {};
                        }
                        sort[sortField.id] = !!sortField.desc && -1 || 1;
                      });
                      //tell the table we're loading
                        setLoading(true);

                        //get the data
                        fetchMore({
                          variables: {
                            // skip: state.pageSize * (state.page + 1),
                            skip: state.pageSize * state.page,
                            limit: state.pageSize,
                            sort
                          },

                          updateQuery: (prev, { fetchMoreResult }) => {

                            //update the data
                            if (!fetchMoreResult.getUsers.users) {
                              setPages(Math.ceil(prev.getUsers.count / state.pageSize));
                              return prev;
                            }
                            setPages(Math.ceil(fetchMoreResult.getUsers.count / state.pageSize));
                            const newObj = {
                              getUsers: {
                                ...prev.getUsers,
                                users : fetchMoreResult.getUsers.users
                              }
                            };
                            //disable loading state
                            setLoading(false);
                            return Object.assign({}, prev, newObj);
                          }
                        })

                    }}

                  />
                </CardBody>
              </Card>
            );
          }}
        </Query> */}
    </Card>

  </Container>
)};

export default Users;
