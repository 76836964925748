/*
  Really, REALLY simple template engine to replace anything wrapped in <% var %>
*/

const example = {
  _id: 'id12345',
  firstName: 'Bob',
  lastName: 'Tester',
  emails: [
    {
      address: 'bob.tester@email.ca',
      verified: false,
      isPrimary: true
    }
  ],
  mobilePhone: '+16470123456',
  address1: '1 King St West',
  city: 'Toronto',
  province: 'Ontario',
  postalCode: 'M4C 1T7',
  language: 'English',
  optIn: true,
  ENSupporterId: 458777,
  ridingCode: 7777777,
  ridingName: 'ridingName',
  link: 'https://to.friends.ca/a1b2c3'
};

const templateEngine = function(html, options = example) {
  var re = /<%(.+?)%>/g,
    reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
    code = 'with(obj) { var r=[];\n',
    cursor = 0,
    result,
    match;
  var add = function(line, js) {
    js
      ? (
        code += line.match(reExp)
        ? line + '\n'
        : 'r.push(' + line + ');\n')
      : (
        code += line !== ''
        ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n'
        : '');
    return add;
  }
  while (match = re.exec(html)) {
    add(html.slice(cursor, match.index))(match[1], true);
    cursor = match.index + match[0].length;
  }
  add(html.substr(cursor, html.length - cursor));
  code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');
  try {
    result = new Function('obj', code).apply(options, [options]);
  } catch (err) {
    console.error("'" + err.message + "'", " in \n\nCode:\n", code, "\n");
  }
  return result;
}

export default templateEngine;
