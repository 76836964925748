const mailTrackPrograms = {
    "Prospect List" : [
        {key : "608 - ALS Canada (Amytrophic Lateral Sclerosis)", value : "608"},
        {key : "263 - Alzheimer Canada", value : "263"},
        {key : "0014 - Amnesty International Canada", value : "0014"},
        {key : "0022 - Buffalo PBS (WNED-TV) Supporters", value : "0022"},
        {key : "0564 - Canadian Centre for Policy Alternatives", value : "0564"},
        {key : "1466 - Canadian Olympic Foundation", value : "1466"},
        {key : "0210 - Council of Canadians", value : "0210"},
        {key : "0912 - Covenant House Vancouver (SK, AB, & BC only)", value : "0912"},
        {key : "0064 - Cuso International", value : "0064"},
        {key : "0750 - Ecojustice (Formerly: Sierra Legal Defence Fund)", value : "0750"},
        {key : "0690 - Farm Radio International", value : "0690"},
        {key : "1561 - FINCA Canada", value : "1561"},
        {key : "1579 - Indspire", value : "1579"},
        {key : "0090 - International Fund for Animal Welfare", value : "0090"},
        {key : "0021 - Interval House", value : "0021"},
        {key : "0095 - John Howard Society of Canada", value : "0095"},
        {key : "0176 - Maclean's Magazine", value : "0176"},
        {key : "0811 - MSF Canada (Doctors Without Borders)", value : "0811"},
        {key : "0062 - Oxfam Canada", value : "0062"},
        {key : "0169 - Save the Children (English)", value : "0169"},
        {key : "1094 - The Walrus Magazine", value : "1094"},
        {key : "0181 - TVO - Donors & Members", value : "0181"},
        {key : "0870 - ZOOMER (CARP)", value : "0870"},
    ]
};


export default mailTrackPrograms;