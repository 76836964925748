import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import {Modal} from "react-bootstrap";
import validator from "validator";
import { toast } from 'react-toastify'
import { findWhere , clone, omit} from 'underscore';
import classNames from 'classnames';

import {
  Container,
  Row,
  Col,
  Button,
  FormInput,
  FormCheckbox,
  FormRadio,
  FormFeedback
} from "shards-react";

import SupporterContacttags from "../SupporterContactTags";
import CommentAction from "../CommentAction";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const UPDATE_SUPPORTER = gql`
mutation updateOneSupporter( $selector: Selector, $modifier: Modifier, $opts: GenericObject ){
  updateOneSupporter(
      selector: $selector,
      modifier: $modifier,
      opts: $opts
  ) {
   ok
   n
   nModified
  }
}
`


// newEmail is there to track new emails in the email array and to knwow when users try to delete
// an existing email and tell them to use suppress instead of removing the entire email row
const newEmail = {
  address      : "",
  suppressed   : false,
  verified     : false,
  isPrimary    : false,
  optInEn      : false,
  optInFr      : false,
  okNewsletter : false,
  newEmail     : true
};

const emailFields = ["address", "suppressed", "isPrimary", "optInEn", "optInFr", "okNewsletter"];


const EmailsComponent= ({ supporterEmails, supporterId, disabled }) => {

  //modal Edit

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = (e) => setShowEdit(false);
  const handleShowEdit = (e) => setShowEdit(true);
  return (

    <div>
    {
      !disabled
      ?
      <p
       className={"text-right mx-4 border-top"}
       style={{cursor : "pointer", color : "#2A82E6"}}
       onClick={handleShowEdit}>Edit
      </p>
      :
      ""
    }


    <DisplayEmails emails={ supporterEmails } />
     <Modal
        size="lg"
        backdrop={false}
        keyboard={false}
        show={showEdit}
        onHide={handleCloseEdit}
      >
        <Modal.Header
          closeButton
          >
          <Modal.Title>Edit emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm
            supporterEmails={supporterEmails}
            supporterId={supporterId}
            showSubmitButton = { true }
            handleCloseEdit={handleCloseEdit}
            />
        </Modal.Body>
      </Modal>
    </div>
  )
}

//Simple dumb component to display emails
const DisplayEmails = React.memo(function ({ emails }) {
  if( !emails || !emails.length ) { return "" };
  return (
    <div className="mx-2 px-4">
      {emails.map((email, i) => {

        //display appropriately by class
        const classes = classNames({
          'text-muted': !!email.suppressed,
          'font-italic': !!email.suppressed
        });

        //return !!emailToDisplay.length && <span><a href={`mailto:${emailToDisplay}`}>{emailToDisplay}</a> {!!verified && <i class="far fa-check-circle"></i> || ""}</span> || "";
        const tags = omit( email, ['address', '_id', 'suppressed', 'verified', 'isPrimary', 'ENSupporterId', '__typename'] );

        return (
          <Row key={email._id + '-' + ''} className="mb-3 border-bottom">
            <Col sm="5">
              <span className={classes}>{email.address}</span>{!!email.verified && <span>&nbsp; <i className="far fa-check-circle"></i></span> }
            </Col>
            <Col className="d-flex" sm="7">
              <SupporterContacttags tags={tags} />
            </Col>
          </Row>
        )
      })}

  </div>)
})

//more complex component for editing emails
const EmailForm = ({supporterEmails = [], supporterId, messageEmails = [], addToModifier = null, mergeView = false, showSubmitButton = false, handleCloseEdit}) => {

  //this is for the comment action below
  const [ showModal, setShowModal ] = useState(false);

  //clonse the emails to ensure we don't accidentally modify the parent component, which was happening for some reason
  const [ emails, setEmails ] = useState( supporterEmails );

  //simple change handler
  const onChangeEmail = ({input, field, index}) => {
    let emailsToSet = clone(emails);
    emailsToSet[index][field] = input;
    setEmails([...emailsToSet]);
  }

  //GQL Mutation to perform the update
  const [updateSupporter] = useMutation(UPDATE_SUPPORTER, {
    onCompleted : () => {
      toast.success("Emails updated");
      handleCloseEdit();
    },
    refetchQueries : ["getSupporter", "getSupporters"]
  });

  //if no email present, set a dummy
  //SYLVAIN is this necessary?  Can't we just set a default state?
  if(!emails.length) {
    emails.push({
      address : "",
      suppressed : false,
      verified : false,
      isPrimary: false,
      optInEn : false,
      optInFr : false,
      okNewsletter : true,
      okFund: true,
      newEmail : true
    })
  }
  //we use this in lots of places. It should be abstracted out into a utility somewhere
  const removeSpaces = (input) => {
    return input.split("").map( str => str.replace(/\s/g, '')).join('');
  };

  const addRow = () => {
    const actualEmails = clone(emails);
    const newEmailField = {
      address : "",
      suppressed : false,
      verified : false,
      isPrimary: false,
      optInEn : false,
      optInFr : false,
      okNewsletter : true,
      okFund : true,
      newEmail : true
    }
    actualEmails.push(newEmailField);

    if(!!addToModifier) {
      addToModifier('emails', actualEmails);
    }
    setEmails([...actualEmails]);
  };

  const removeRow = (i) => {

    //don't actually allow removal of emails.
    if(!!emails[i]._id) {
      return alert("Please opt-out the email if supporter does not want to be contacted via this email anymore")
    }

    //do not allow delete if we only have 1 email
    if (emails.length < 2) {
      return;
    }

    const filteredEmails = emails.filter((email, index) => {
      return i !== index;
    });

    if(!!addToModifier) {
      addToModifier('emails', filteredEmails);
    }
    setEmails(filteredEmails);
  };


  //only show remove button if we have multiple emails
  const buttonToDisplay = (i) => {
    if(i + 1 < emails.length) {
      return (
        <Col>
         <a
           style={{cursor : "pointer", color : "red"}}
           onClick={ (e) => removeRow(i) }
         >Delete</a>
        </Col>
       )
    }

    //SYLVAIN i'm commenting this out because it seems redundant
    // return  (
    //   <Col>
    //     <a
    //       style={{cursor : "pointer", color : "red"}}
    //       onClick={ (e) => removeRow(i) }
    //       >Delete</a>
    //   </Col>
    // )
  }

  //there can only be one primary, so if we set one to true all others must be false (i.e. radio behaviour);
  const setPrimary = (i) => {
    let actualEmails = clone(emails);
    for(const [index, email] in emails ) {
      if(index == i) {
        actualEmails[index].isPrimary = true;
      } else {
        actualEmails[index].isPrimary = false;
      }
    }

    if(!!addToModifier) {
      addToModifier('emails', actualEmails);
    }
    setEmails([...actualEmails]);
  };

  //handler to change verious tags
  const setBooleans = ({field, i}) => {
    let emailsToSet = clone(emails);
    emailsToSet[i][field] = !emails[i][field];
    if(!!addToModifier) {
      addToModifier('emails', emailsToSet);
    }
    setEmails([...emailsToSet]);
  }

  const generateModifier = () => {
    const mod = emails.map(email => {
      let emailMod = email;
      if(!!email.newEmail) {
        delete emailMod.newEmail;
      }
      return emailMod;
    });
    return {$set : {emails : mod}};
  };

  //if we're in merge view, only show the primary email.
  if ( !!mergeView && !!emails.length ) {
    emails = [ findWhere( emails, { isPrimary: true } ) || emails[0] ];
  }

  return (
    <div>
      {emails.map((email, i) => {
      return (
        <Row key={email._id + '-' + 'row'} className="mb-3">
          <Col sm="5">
            <FormInput
              id={email._id + '-' + 'input'}
              type="email"
              value={email.address}
              placeholder="mail@friends.ca"
              disabled={!!mergeView}
              onChange={e => {
                const input = removeSpaces(e.target.value);
                const isValid = validator.isEmail(input);
                if (!!isValid || input.length === 0) {
                  onChangeEmail({
                    input,
                    field : "address",
                    error : "",
                    index : i
                  })
                } else {
                  onChangeEmail({
                    input,
                    field : "address",
                    error : "Email address is incorrect",
                    index: i
                  })
                }
              }}
              invalid={!!messageEmails[i]}
            />
            <FormFeedback>{messageEmails[i]}</FormFeedback>
          </Col>
          {!mergeView ?
          <Fragment>
            <Col  className="d-flex" sm="7">
              <FormCheckbox
                inline
                checked={email.suppressed}
                // className="ml-auto my-auto"
                id="isSuppressed"
                onChange={ () => setBooleans({field : "suppressed", i}) }
              >Suppressed</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.verified}
                // className="ml-auto my-auto"
                id="isVerified"
                onChange={ () => setBooleans({field : "verified", i}) }
              >Verified</FormCheckbox>
              <FormRadio
                inline
                checked={email.isPrimary}
                // className="ml-auto my-auto"
                id="isPrimary"
                onChange={ () => setPrimary(i) }
              >Primary</FormRadio>
              {!mergeView ?
                buttonToDisplay(i)
              : ""}
            </Col>
            <Row className="mx-3 mt-2">
            <FormCheckbox
                inline
                checked={email.optInFr}
                // className="ml-auto my-auto"
                id="isOptInFr"
                onChange={ () => setBooleans({field : "optInFr", i}) }
              >opt-in French</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.optInEn}
                // className="ml-auto my-auto"
                id="isOptInEn"
                onChange={ () => setBooleans({field : "optInEn", i}) }
              >opt-in English</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.okNewsletter}
                // className="ml-auto my-auto"
                id="isokNewsletter"
                onChange={ () => setBooleans({field : "okNewsletter", i}) }
              >Newsletter</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.okFund}
                // className="ml-auto my-auto"
                id="isokFund"
                onChange={ () => setBooleans({field : "okFund", i}) }
              >okFund</FormCheckbox>
            </Row>


          </Fragment>
          : ""}
        </Row>
      )
    })}

    <Row>
      <Col>
        <div
          className='mb-3'
        style={{cursor : "pointer", color : "#2A82E6"}}
        onClick={() => addRow() }
        ><span><i className='fa fa-plus'/> Add New Email</span></div></Col>
    </Row>

    {!!showSubmitButton && <Button onClick={() => { setShowModal(true); }}> Submit </Button> || ""}

          <CommentAction
            action={"update emails"}
            confirmation={false}
            mutation={updateSupporter}
            showModal={showModal}
            setShowModal={setShowModal}
            opts={{
              variables  : {
                selector : {
                  _id : supporterId
                },
                modifier:  generateModifier(),
                opts : {}
              },
              refetchQueries : ['getSupporter', 'getActions']
            }}
          />
      </div>
  )
}

export { EmailsComponent };
