//REACT + GQL
import React, { useEffect, useState, Fragment } from "react";
import {Modal} from "react-bootstrap"


import { useMutation, useQuery } from '@apollo/react-hooks';

import { Redirect, Link } from "react-router-dom";

//UTILS
import _ from "underscore";
import moment from 'moment';

//UI
import { toast } from 'react-toastify';
import {
  Col,
  Card,
  CardHeader,
  Button,
  Badge,
  ButtonGroup,
  FormCheckbox,
  Row
} from "shards-react";

//FCB components
import BottomActionBar from "../../friendsComponents/BottomActionBar";
import SupporterForm from "../../friendsComponents/SupporterForm";
import Loading from '../../friendsComponents/Loading';


import { GET_SUPPORTERS, MERGE_SUPPORTERS, CREATE_HOUSEHOLD, SET_AS_NEW_SUPPORTER,  UPDATE_ACTIONS } from './gql';
import { UPDATE_SUPPORTER } from '../EditSupporterDetails.js';

export const PACStatusMap = {
  "active"        : "success",
  "pending"       : "success",
  "lapsed"        : "warning",
  "uncollectable" : "danger",
  "cancelled"     : "secondary"
};

const MergeSupporter = ({ location, wizard = false, specialActions, successCallback, goBack, hasPrev, hasNext, isActionCheck = false, actions = null, fieldsToCompare = null }) => {
  //if we came from the supporters table, dismiss the selection notifications
  //useEffect so that it doesn't dismiss toasts triggerred inside this component
  useEffect( () => {
    toast.dismiss();
  }, [ location ]);


  let supporterIds = !!location && !!location.state && location.state.supportersId || [];
  const [ disableOverride, setDisableOverride ] = useState(false);
  const [supporters, setSupporters ] = useState([]);
  const [redirect, setRedirect]      = useState(false);
  //modifier to update the primary supporter;
  const [modifier, setModifier]      = useState({});
  const [preventReset, setPreventReset ] = useState(false);

  //modal household
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [updateAllMembers, setUpdateAllMembers] = useState(false);

  //keep track of which elements are selected
  const [ selections, setSelections ] = useState({});

  const mutationOpts = {
    onCompleted({ mergeSupporters }) {
      if ( !!wizard ) {
        setDisableOverride(false);
        setPreventReset(false);
        setModifier({});
        successCallback();

      } else {
        setRedirect(true);
      }

    },
    onError(e){
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  };

  const [mergeSupporters ] = useMutation(MERGE_SUPPORTERS , mutationOpts );
  const [createHousehold ] = useMutation(CREATE_HOUSEHOLD , mutationOpts );
  const [updateOneSupporter ] = useMutation(SET_AS_NEW_SUPPORTER, mutationOpts);
  const [updateActions] = useMutation(UPDATE_ACTIONS, {
    onCompleted() {
      console.log('yay!');
    },
    onError: mutationOpts.onError
  });
  const [updateSupporter] = useMutation(UPDATE_SUPPORTER, {
    onCompleted(data) {
      //if we have actions, update them
      const actionsToUpdate = _.where(supporters, { isFake: true });
      updateActions({
        variables: {
          selector: { _id: { $in: _.pluck( actionsToUpdate, "_id" ) } },
          modifier: {
            $set: {
              reviewed: true
            }
          }
        }
      });

      toast.success("Changes saved!");
      if ( !!wizard ) {
        setDisableOverride(false);
        setPreventReset(false);
        setModifier({});
        successCallback();
      }
    },
    onError: mutationOpts.onError
  });

  const queryOpts = {
    variables: {
      selector: {
        _id: { $in: supporterIds },
        showMerged : true
      },
      limit: supporterIds.length
    },
    fetchPolicy : "network-only"
  };
  const { error, data, loading } = useQuery(GET_SUPPORTERS, queryOpts );

  if ( !!error ) {
    return <h1>Error {error.message}</h1>
  }


  if ( !!loading ) {
    return <Loading />
  }

  //ensure that the most recently updated is the default primary
  if ( !!data && !!data.getSupporters && !!data.getSupporters.supporters && !_.isEqual(data.getSupporters.supporters, supporters) ) {
    if ( preventReset !== true ) {

      //fake supporters always go last
      const fakeSupporters = !!actions && actions.map( a => {
        // console.log(a._id);
        a.priorState.isFake = true;
        return a.priorState;
      }) || [];
      const sorted = data.getSupporters.supporters.sort( ( a, b ) => {
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      });
      data.getSupporters.supporters = data.getSupporters.supporters.concat( fakeSupporters );
      setSupporters( sorted.concat( fakeSupporters ) );
      return;
    }
  }

  //defaults

  if ( _.isEmpty(selections) && !!data && !!data.getSupporters && !!data.getSupporters.supporters && !!data.getSupporters.supporters.length ) {

    let fields = {
      firstName          : data.getSupporters.supporters[0]._id,
      lastName           : data.getSupporters.supporters[0]._id,
      customerId         : data.getSupporters.supporters[0]._id,
      PIN                : data.getSupporters.supporters[0]._id,
      language           : data.getSupporters.supporters[0]._id,
      contactPreferences : data.getSupporters.supporters[0]._id,
      address1           : data.getSupporters.supporters[0]._id,
      address2           : data.getSupporters.supporters[0]._id,
      city               : data.getSupporters.supporters[0]._id,
      province           : data.getSupporters.supporters[0]._id,
      postalCode         : data.getSupporters.supporters[0]._id,
      emails             : data.getSupporters.supporters[0]._id,
      homePhone          : data.getSupporters.supporters[0]._id,
      mobilePhone        : data.getSupporters.supporters[0]._id,
    }

    setSelections(fields);
  } else if ( !!wizard && !!selections.firstName && !supporterIds.includes(selections.firstName) && !!data && !!data.getSupporters && !!data.getSupporters.supporters.length && preventReset === false ) {
    /*
      if we're in the wizard we need to trigger this deliberately because
      even though the success callback changes supporterIds, selections
      is still set based upon the previous merge group. This resets it
      to use the current group.
    */
    let fields = {
      firstName          : data.getSupporters.supporters[0]._id,
      lastName           : data.getSupporters.supporters[0]._id,
      customerId         : data.getSupporters.supporters[0]._id,
      PIN                : data.getSupporters.supporters[0]._id,
      language           : data.getSupporters.supporters[0]._id,
      contactPreferences : data.getSupporters.supporters[0]._id,
      address1           : data.getSupporters.supporters[0]._id,
      address2           : data.getSupporters.supporters[0]._id,
      city               : data.getSupporters.supporters[0]._id,
      province           : data.getSupporters.supporters[0]._id,
      postalCode         : data.getSupporters.supporters[0]._id,
      emails             : data.getSupporters.supporters[0]._id,
      homePhone          : data.getSupporters.supporters[0]._id,
      mobilePhone        : data.getSupporters.supporters[0]._id,
    };
    setPreventReset(true)
    setSelections(fields);
  }


  if ( !!loading  ) {
    return <Loading />
  }

  const view = !!location && location.pathname || "/supporter/merge";


  const setPrimary = (index) => {
    const newPrimary = supporters[index];
    const newOrder = [newPrimary, ..._.without(supporters, newPrimary)];
    setPreventReset(true);
    setSelections({
      firstName          : newPrimary._id,
      lastName           : newPrimary._id,
      customerId         : newPrimary._id,
      PIN                : newPrimary._id,
      language           : newPrimary._id,
      contactPreferences : newPrimary._id,
      address            : newPrimary._id,
      emails             : newPrimary._id,
      homePhone          : newPrimary._id,
      mobilePhone        : newPrimary._id
    })
    setSupporters(newOrder);
  };

  const unSelect = (index) => {
    const conf = window.confirm("Are you sure?");
    if ( !conf ) return;

    const supporterToUnselect = supporters[index];
    setPreventReset(true);
    setSupporters( _.filter(supporters, (s) => s._id !== supporterToUnselect._id) );
  };

  //method to select elements
  const onSelectField = (field, value, supporterId) => {

    const indexOfSupporter = supporters.indexOf( _.findWhere( supporters, { _id: supporterId } ) );
    let newSelect = {};
    newSelect[field] = supporterId;

    //set all values to false
    setSelections( { ...selections, ...newSelect } );

    //if we're clicking on a non-primary supporter or manually setting the primary, add items to the modifier
    if(indexOfSupporter > 0 || !!disableOverride ) {
      setModifier({...modifier, [field] : value});
    } else {
      //otherwise, we're editing the main supporter, so remove modifier fields
      setModifier(_.omit(modifier, field));
    }

    //if we're overriding, we need to update the supporter field too. Otherwise UI won't change (i.e value stays the same)
    if ( !!disableOverride ) {
      const supportersToUpdate = supporters;
      supportersToUpdate[indexOfSupporter][field] = value;
      setSupporters( supportersToUpdate );
    }
  };

  const onChange = ({input = null, field, error = ""}) => {
    //sets the value of the input

    setModifier({...modifier, [field] : input});
    const supportersToUpdate = supporters;
    supportersToUpdate[0][field] = input;
    setSupporters( supportersToUpdate );


  };
  //this gets all the first and last names of selected supporters and their house members if there is any. Return only unique values
  const allMemberNames = function () {
    return _.uniq(_.flatten(supporters.map(sup => {
      let names = [`${sup.firstName} ${sup.lastName}`]
      if (!!sup.householdMembers.length) {
        sup.householdMembers.forEach(member => {
          names.push(`${member.firstName} ${member.lastName}`)
        })
      }
      return names;
    })));
  }


  const submitMerge = () => {

    const conf = window.confirm("Are you sure you want to merge supporters ?");
    if ( !conf )  {
      return toast.warning(`merge canceled`);
    }

    let opts = {};

    //if we are on the clean view for the big merge, we don't want to update lastUpdated field and don't want to sync with ENS
    if(!!specialActions && specialActions === "bigMerge") {

      opts.options = {
        skipLastUpdated : true,
        sync            : false
      };

      //if we are doing the big import, we want to unflag the supporter once reviewed and don't want to sync with ENS

    } else if(!!specialActions && specialActions === "bigImport") {

      const importSupporter = _.findWhere(supporters, {requireReview : true});

      opts.options = {
        sync : false
      };
      opts.reviewedSupporterId = importSupporter._id;
    }
    mergeSupporters({
      variables : {
        supportersToMerge: supporters,
        modifier,
        opts
      }
    });
  }

  const householdFields = ["homePhone", "address1", "address2", "postalCode", "city", "province" ]

  //useful for household returns either the fields selected or the default one if there isn't any selected
  //useful to update all the members if needed
  const finalModifier = () => {
    let mod = {};
    householdFields.forEach(field => {
      if(!modifier[field]) {
        mod[field] = !!supporters && supporters.length && supporters[0][field]
      } else {
        mod[field] = modifier[field]
      }
    });
    return mod;
  }


  const submitHouseHold = () => {

    const conf = window.confirm("Are you sure you want to create a household ?");
    if ( !conf )  {
      return toast.warning(`Household canceled`);
    }

    //get an array of the pre existing members to integrate them in the new household
    let preExistingMembers = _.flatten(supporters.map(s => {
      return !!s.householdMembers.length && _.pluck(s.householdMembers, "_id") || [];
    }));

    let allSupportersInfo = {}

    //this is to pass firstName and lastName of all the supporters to save it in the action
    supporters.forEach( s => {
      allSupportersInfo[s._id] = {
        firstName : s.firstName,
        lastName  : s.lastName
      };
      s.householdMembers.forEach(m => {
        allSupportersInfo[m._id] = {
          firstName : m.firstName,
          lastName  : m.lastName
        };
      });
    });


    let opts = {};
    //if we are on the clean view for the big merge, we don't want to update lastUpdated field and don't want to sync with ENS
    if(!!specialActions && specialActions === "bigMerge") {
      opts.options = {
        skipLastUpdated : true,
        sync            : false,
        unSetPIN        : true
      };

      //if we are doing the big import, we want to unflag the supporter once reviewed and don't want to sync with ENS
    } else if(!!specialActions && specialActions === "bigImport") {

      const importSupporter = _.findWhere(supporters, {requireReview : true});

      opts.options = {
        sync : false
      };
      opts.reviewedSupporterId = importSupporter._id;
    }
    createHousehold({
      variables : {
        supporterIds : _.uniq([..._.pluck(supporters, "_id"), ...preExistingMembers]),
        allSupportersInfo,
        modifier : finalModifier(),
        updateAll : updateAllMembers,
        opts

      }
    });
    handleCloseModal();
  };

  if(!!redirect) {
    return <Redirect to={`/supporter/${supporters[0]._id}`} />
  }

  let pageTitle, firstLabel, secondaryLabel, mode;
  if ( !!actions  ) {
    mode = 'donorChanges'
    firstLabel = 'Current Version';
    secondaryLabel = "Changed from";
  } else if (specialActions === "bigMerge") {
    mode = 'bigMerge';
    pageTitle = "Merge Supporters or create household";
    firstLabel = "Primary Supporter";
    secondaryLabel = 'Supporter to merge or household';
  }else if (specialActions === "bigImport") {
    mode = 'bigImport';
    pageTitle = "Merge Supporters or create household";
    firstLabel = "Primary Supporter";
    secondaryLabel = 'Supporter to merge or household';
  } else if ( view === '/supporter/merge' ) {
    mode = 'merge';
    pageTitle = "Merge Supporters";
    firstLabel = "Primary Supporter";
    secondaryLabel = 'Supporter to merge';
  }  else {
    mode = 'household';
    pageTitle = 'Create Household';
    firstLabel = 'Primary Supporter';
    secondaryLabel = 'Supporter to add';
  }

  const setAsNewSupporter = () => {
    const importSupporter = _.findWhere(supporters, {requireReview : true});
    updateOneSupporter({
      variables : {
        selector : {_id : importSupporter._id},
        modifier : {
          $set : {requireReview : false}
        }
      }
    })
  };

  const importBadge = (supporter) => {
    if(!!supporter.requireReview) {
      return <Badge className="mx-2" theme="success">from import</Badge>;
    }
    return "";
  };


  return(

    <div>
          <button onClick={(e) => {

            //if disable in place, confirm. If restoring override, just proceed
            let disabling = !disableOverride;
            if ( !!disabling ) {
              let conf = window.confirm("Are you sure you want to do a manual override?");
              if ( !conf ) {
                return;
              }
            }

            //let them know
            if ( !!disabling ) {
              toast.success("Please edit primary supporter only");
            }

            //toggle
            setDisableOverride( !disableOverride );

          }}
          style={{ position: 'absolute', top: 105, right: 25}} className='btn btn-primary float-right'>{ !disableOverride ? "Manual Edit" : "Close Manual Edit" }</button>
          <h4>{pageTitle}</h4>
      <Row>

        {supporters.map(((supporter, index) => (
            <Col md="6" className="mb-4" key={supporter._id}>
            {/* {console.log("supporter : ", supporter)} */}
                <Card className="px-2">
                  <CardHeader className='border-bottom'>
                    <Row className="justify-content-between align-items-top">
                      <h6>
                        {index === 0 && firstLabel || secondaryLabel}
                        {importBadge(supporter)}
                        {!!supporter.PACStatus && (<a className="ml-2" href="#"> <Badge pill theme={PACStatusMap[supporter.PACStatus]}>{`PAC-${supporter.PACStatus}`}</Badge></a>) || ""}
                        <small>
                          <span style={{display: "block"}}>Last updated {moment(supporter.lastUpdated).format("DD MMMM YYYY h:mm A")}</span>
                        </small>
                        <span>{!!supporter.householdMembers.length && "House members : " + supporter.householdMembers.map(member => ` ${member.firstName} ${member.lastName}`) || ""}</span>
                      </h6>
                        <ButtonGroup style={{height: 35}}>
                          <Button
                            disabled = { !!isActionCheck }
                            size="sm"
                            theme="white"
                            href={`/supporter/${supporter._id}`}
                            target="_blank">
                            View profile
                          </Button>
                          <Button
                            disabled = { !!isActionCheck }
                            size="sm"
                            onClick={() => {
                              unSelect(index)
                            }}
                            theme="warning">
                            Unselect
                          </Button>
                          {index !== 0 ?
                          <Button
                            disabled = { !!isActionCheck }
                            size="sm"
                            onClick={ () => {
                              setPrimary(index)
                            }} >
                            Set as Primary
                          </Button>
                          : " "}
                        </ButtonGroup>
                    </Row>
                  </CardHeader>
                  <SupporterForm
                    supporter={supporter}
                    mergeView={true}
                    disableOverride={ disableOverride && index === 0 }
                    fields={ fieldsToCompare }
                    index={index}
                    selections={selections}
                    merge={view == "/supporter/merge"}
                    onSelectField={onSelectField}
                    onChange={onChange}
                  />
                </Card>
            </Col>
          )
        ))
        }
      </Row>

      <BottomActionBar show={true}>
        <div style={{width: '100%'}}>
          { !!wizard ?
            <Button
              disabled = { !hasPrev }
              className={'float-left'}
              onClick={() => {
                setPreventReset(false);
                goBack()
              }}>
               <i className='material-icons'>keyboard_arrow_left</i> Previous
            </Button> :  "" }
          <Link
            to={{
              pathname : `/supporter/`
            }}
            onClick ={ (e) => {
              const conf = window.confirm('Are you sure you want to cancel?');
              return !!conf || e.preventDefault() ;
            }}
            className='btn btn-outline btn-outline-dark'>
            Cancel
          </Link>
          {mode == "donorChanges" ?
           <Button
             onClick={() => {
              //  console.log("approved clicked", modifier);
               //don't update if no changes
               if ( !modifier || _.isEmpty( modifier ) ) {
                 //if we have actions, update them
                  const actionsToUpdate = _.where(supporters, { isFake: true });
                  updateActions({
                    variables: {
                      selector: { _id: { $in: _.pluck( actionsToUpdate, "_id" ) } },
                      modifier: {
                        $set: {
                          reviewed: true
                        }
                      }
                    }
                  });

                  setDisableOverride(false);
                  setPreventReset(false);
                 return successCallback();
               }
               updateSupporter({
                 variables  : {
                   selector : {
                     _id : supporters[0]._id
                   },
                   modifier: {
                     $set : modifier
                   },
                   opts : {
                     comment: "User-generated changes corrected"
                   }
                 }
               });
             }}>
               Approve
           </Button>
           : "" }
          {mode == "merge" ?
           <Button
             onClick={() => {
               submitMerge();
             }}>
               Merge
           </Button>
           : "" }
           { mode === 'household' ?
           <Button
             onClick={() => {
               handleShowModal();
             }}>
               Create Household
           </Button>
         : "" }
         {mode == "bigMerge" ?
            <Fragment>
              <Button
                onClick={() => {
                  submitMerge();
                }}>
                  Merge
              </Button>
              <Button
                onClick={() => {
                  handleShowModal();
                }}>
                  Create Household
              </Button>
            </Fragment>
           : "" }
        {mode == "bigImport" ?
        <Fragment>
          <Button
            onClick={() => {
              submitMerge();
            }}>
              Merge
          </Button>
          <Button
            onClick={() => {
              handleShowModal();
            }}>
              Create Household
          </Button>
          <Button
            onClick={() => {
              setAsNewSupporter();
            }}>
            Set Primary as New Supporter
          </Button>
        </Fragment>
        : "" }
         { !!wizard ?
           <Button
             disabled = { !hasNext }
             className={'float-right'}
             onClick={() => {
               setPreventReset(false);
               successCallback();
             }}>
              Next <i className='material-icons'>keyboard_arrow_right</i>
           </Button> :  "" }
         </div>
      </BottomActionBar>

      {/* Household Modal*/}
    <Modal
      size="md"
      // centered
      show={showModal}
      onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create household</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"mb-3"}>This household will contain the following supporters:</div>
          <ul style={{listStyleType : "none"}}>
            {!!showModal && allMemberNames().map((name, i) => {
              return <li key={`${i}`}><i className="material-icons">person</i>{name}</li>
            }) || ''}
           </ul>

          <FormCheckbox
            checked={updateAllMembers}
            onChange={()=> setUpdateAllMembers(!updateAllMembers)}>
            Apply to all household members?
          </FormCheckbox>
          <table style={{width: "100%", margin: "auto"}} className={"mb-3 mx-3"}>
            <tbody>
            {householdFields.map((field) => {
              let mod = finalModifier();

              return (
                <tr key={`${field}`}>
                  <td>{field}</td>
                  <td>{`: ${mod[field] || ""}`}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <Button
            onClick={() => {
              submitHouseHold();
            }}>
              Save
            </Button>
            <Button
              theme="danger"
              outline
              onClick={() => {
                handleCloseModal()
              }}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MergeSupporter;
