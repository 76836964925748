import gql from 'graphql-tag';

export const GET_PAC_REPORT = gql`
  query getPACReport($startDate: Date!, $endDate: Date!) {
    getPACReport(startDate: $startDate, endDate: $endDate) {
      data {
        date
        count
        value
      }
    }
  }`;

export const GET_MAIL_REPORT = gql`
  query getMailReport($selector: Selector) {
    getMailReport(selector: $selector)
  }
`;
