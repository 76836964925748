import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import { Tabs, Tab } from 'react-bootstrap';
import CampaignAnalytics from "../../friendsComponents/CampaignAnalytics";
import InlineTitleButton from "../../friendsComponents/InlineTitleButton";
import SetPageMetas from '../../utils/SetPageMetas';

import {
  Container,
  Row,
  Col
} from "shards-react";

import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';
import { contains } from 'underscore';

import Loading from "../../friendsComponents/Loading";
import PageTitle from "../../components/common/PageTitle";

import CampaignForm from "../../friendsComponents/CampaignForm";
import CampaignSteps from "./steps/index.js";

const Url = require('url-parse');


// this needs rectifying awk
export const GET_CAMPAIGN = gql`
  query getCampaign($_id: ID) {
    getCampaign(_id: $_id) {
      _id
      nickname
      isEnded
      description
      mode
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($selector: Selector, $modifier: Modifier) {
    updateCampaign(selector: $selector, modifier: $modifier) {
      n
      nModified
      ok
    }
  }
`;


const CampaignDetails = ({match, location, history}) => {
  const campaignId = match.params.id;
  const user = JSON.parse(localStorage.getItem("user"));


  const adminRights = !!contains(user.roles.campaign, "admin") || !!contains(user.roles.campaign, "super-admin") || false;
  const defaultViewBasedOnRoles = adminRights && "details" || "wizzard"

  let params = new Url(location.search, true);
  // if there is a view passed in the parameters, get it or display 'main' by default
  const [view, setView] = useState(!!params.query.view && params.query.view || defaultViewBasedOnRoles);

  const opts = {
    variables : {
      _id : campaignId
    },
    fetchPolicy : "network-only",
    refetchQueries: ['getCampaign']

  }
  //sets the methods and variable for the update campaign Modal
  const [updateModal, setUpdateModal] = useState(false);
  const handleCloseModal = () => setUpdateModal(false);
  const handleShowModal = () => setUpdateModal(true);

  const { data, loading, error } = useQuery(GET_CAMPAIGN, opts);

  if ( !!error ) {
    return <h1>Error {error.message}</h1>
  }

  if(!!loading) {
    return <Loading />
  }

  const campaign = !!data && data.getCampaign;

  if (!!campaign ) {
    SetPageMetas({ title: campaign.nickname })
  }

  console.log("campaign::");
  console.log(campaign);

  const isEnded = campaign.isEnded;



  return (
    <Mutation
      mutation={UPDATE_CAMPAIGN}
      onCompleted={({updateCampaign}) => {
            toast.success(`Campaign updated`);
            handleCloseModal()
          }}
      onError={(e) => {
        console.log(e);
      }}
      >
      {(updateCampaign, {loading, error}) => {
        if (loading) {
          return <Loading />
        }

        if (error) return <p>ERROR: {error.message}</p>;

        let campaignNick = campaign.isEnded ? campaign.nickname + " (ended)" : campaign.nickname;

        return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="6" title={ `Campaign ${campaignNick}`  } subtitle="Details" className="text-sm-left" />
            <InlineTitleButton sm='6' disabled={!adminRights} onClick={() => {handleShowModal()}} text="Update Campaign"/>

            {/* Update Campaign Modal */}
            <Modal
            size="lg"
            // centered
            show={updateModal}
            onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Update Campaign</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CampaignForm
                  campaign={!!data && data.getCampaign}
                  updateCampaign={updateCampaign}
                />
              </Modal.Body>
            </Modal>
          </Row>

          <Tabs
            id='campaign-tabs'
            activeKey={view}
            onSelect={key => {
              if(key == view) return false;
              if(key == "details" && !adminRights) {
                return false;
              }
              history.push(`/campaign/${campaignId}?view=${key}`)
              setView(key);

          }}>
            <Tab eventKey="details" disabled={!adminRights} title="Details">
              <CampaignAnalytics
                campaignId={campaignId}
              />
            </Tab>

            {  isEnded != true &&
            <Tab eventKey="wizzard" title="Register donations">
              <Row>
                <Col className ="my-4">
                  <CampaignSteps
                    user={user}
                    campaign={data.getCampaign}
                    location={location}
                    history={history}
                  />
                </Col>
              </Row>
            </Tab>
      }
          </Tabs>


        </Container>

      )
     }}

    </Mutation>
  );
}

export default CampaignDetails;
