import React from 'react';

import {
  Button,
  Container,
  Form,
  FormGroup,
  FormRadio,
  Col,
  Row,
  Card,
  CardBody,
} from "shards-react";

import Loading from '../../friendsComponents/Loading.js';
import RangeDatePicker from "../../components/common/RangeDatePicker";

const SelectReportOptions = ({ selection, setSelection, onSubmit, loading, startDate, endDate, handleEndDateChange, handleStartDateChange }) => {
  //list of options for filter
const options = [
  {
    name: "Donor-initiated changes",
    val: "donorChanges"
  },
  {
    name: "Duplicate PIN merge / household",
    val: "bigMerge"
  },
  {
    name: "Review supporters from import",
    val: "bigImport"
  },
  {
    name: "Possible duplicates",
    val: "duplicates"
  },
  {
    name: "Possible household members",
    val: "household"
  }
];

 return(
   <Card small className="mb-4">
    <CardBody>

          <Row className="px-3 my-3">
            <Col>
              <Form>
                <FormGroup>
                  <label className='strong'><strong>What would you like to review?</strong></label><br/>
                  {options.map((element, index) => {
                    return (
                      <FormRadio
                        disabled={ element.val === 'household' }
                        checked={element.val == selection}
                        className="ml-auto my-auto"
                        key = {`position${index}`}
                        onChange={ () => setSelection(element.val)}
                      >{element.name}</FormRadio>
                    )
                  })}
                </FormGroup>
              </Form>
            </Col>
            <Col>
            <span className='strong mb-2'><strong>Updated between</strong></span>
            <RangeDatePicker
              startDate = {startDate || null}
              endDate = {endDate || null}
              handleStartDateChange = {handleStartDateChange}
              handleEndDateChange = {handleEndDateChange}
              maxDateStart = { new Date() }
              maxDateEnd = { new Date() }
              showYearDropdown = { true }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!!loading ? <Loading/> :
              <Button
                onClick={ () => {
                  onSubmit();
                }}
              >
                Search
              </Button>
            }
            </Col>
          </Row>
    </CardBody>
  </Card>
)};

export default SelectReportOptions;
