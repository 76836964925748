import React, {useState} from "react";
import moment from "moment";
import {Modal, Tooltip, OverlayTrigger} from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Badge,
  Card
} from "shards-react";
import "./ShowSupporterDetails.css";

import SupporterContactTags from '../friendsComponents/SupporterContactTags.js';
import EditSupporterDetails from "./EditSupporterDetails";

export const PACStatusMap = {
  "active"        : "success",
  "pending"       : "success",
  "lapsed"        : "warning",
  "uncollectable" : "danger",
  "cancelled"     : "secondary"
};

const ShowSupportDetails = ({supporterData, setUpdate, disabled, merged}) => {

  //modal Edit Supporter
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  //displays the primary email, if not suppressed
  const email = () => {
    let emailToDisplay = "";
    let verified;
    for(const each of supporterData.emails) {
      if(!!each.isPrimary && !each.suppressed) {
        emailToDisplay = each.address;
        verified = each.verified;
      }
    }
    // returns email + validation check if it's vaidated
    return !!emailToDisplay.length && <span><a href={`mailto:${emailToDisplay}`}>{emailToDisplay}</a> {!!verified && <i className="far fa-check-circle"></i> || ""}</span> || "";
  };

  const formatPhone = phone => {
    return `${phone.slice(2, 5)}-${phone.slice(5, 8)}-${phone.slice(8, 12)}`
  }

  const phoneNumber = () => {
    const phones = ["homePhone", "mobilePhone"];
    let phoneToDisplay = "";

    phones.forEach(type => {
      if(!!supporterData[type] && supporterData[type].length === 12 && supporterData[type].includes("+1")) {
        phoneToDisplay = supporterData[type];
      }

    })

    return !!phoneToDisplay && <span>{formatPhone(phoneToDisplay)}</span> || phoneToDisplay;
  }

  const PACStatus = !!supporterData && !!supporterData.PACStatus &&
    (<a><Badge pill theme={PACStatusMap[supporterData.PACStatus]}>{`PAC-${supporterData.PACStatus}`}</Badge></a>) || "";

  const mergedCustomerIds = !!supporterData.mergedCustomerIds && supporterData.mergedCustomerIds.length && supporterData.mergedCustomerIds

  return (
    <Card className="mx-2 my-4">
      {/* User Data */}
      <div className="user-details__user-data">

        <Row className="px-4 pt-4">

          <Col className="top-layer" xs={12} sm={6}>
            {PACStatus}
            {!!supporterData.emails && !!supporterData.emails.length && email()}
            {(!!supporterData.homePhone || !!supporterData.mobilePhone) && phoneNumber()}
          </Col>
          <Col xs={12} sm={6}>
            <Row  className="justify-content-end">
              <span>Last Updated: {moment(supporterData.lastUpdated).format('MMMM Do YYYY')}
              {!disabled ? <a className="mx-2" style={{cursor : "pointer", color : "#2A82E6"}} onClick={handleShowEdit}>Edit</a> : '' }
              </span>

            </Row>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col className="w-50">
            <span className="px-4">Address</span>
            <span className="px-4">{supporterData.address1}</span>
            <span className="px-4">{supporterData.address2}</span>
            <span className="px-4">{supporterData.city}, {supporterData.province}</span>
            <span className="px-4">{supporterData.postalCode}</span>
          </Col>
          <Col className="w-50">
            <span className="px-4">Mobile Phone</span>
            <span className="px-4">{supporterData.mobilePhone ? supporterData.mobilePhone : "-"}</span>
          </Col>
          <Col className="w-50">
            <span className="px-4">Home Phone</span>
            <span className="px-4">{supporterData.homePhone ? supporterData.homePhone : "-"}</span>
          </Col>
        </Row>
       <hr/>
        <Row className="pb-3">
          <Col className="w-50">
              <span className="px-4">Customer ID</span>
            {/* </OverlayTrigger> */}
            <span className="px-4">{supporterData.customerId ? supporterData.customerId : "-"}</span>
          </Col>
          {!!mergedCustomerIds && <Col className="w-50">
            <span className="px-4">Other Customer IDs</span>
            {mergedCustomerIds.map(id => {
              return <span className="px-4">{id}</span>
              })
            }

          </Col>
          || ""}
          <Col className="w-50">
            <span className="px-4">PIN</span>
            <span className="px-4">{supporterData.PIN ? supporterData.PIN : "-"}</span>
          </Col>
        </Row>
        <Row className="mb-3 pb-3">
          <Col className="w-50">
            <span className="px-4">Riding Name</span>
            <span className="px-4">{supporterData.ridingName}</span>
          </Col>
          <Col className="w-50">
            <span className="px-4">Riding Code</span>
            <span className="px-4">{supporterData.ridingCode}</span>
          </Col>
          <Col className="w-50">
            <span className="px-4">Language</span>
            <span className="px-4">{supporterData.language}</span>
          </Col>
        </Row>

      </div>

      <Modal
        size="lg"
        // centered
        show={showEdit}
        onHide={handleCloseEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Supporter Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditSupporterDetails
            setUpdate={setUpdate}
            supporterData={supporterData}
            handleCloseEdit={handleCloseEdit}
          />
        </Modal.Body>
      </Modal>
    </Card>
  )
}

export default ShowSupportDetails;
