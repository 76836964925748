import React from "react";
import {Mutation} from "react-apollo";
import { toast } from 'react-toastify';
import validator from "validator";
import EmailForm from "../friendsComponents/EmailForm";
import CommentAction from "../friendsComponents/CommentAction";
import {Redirect} from "react-router-dom";
import gql from 'graphql-tag';
import _ from "underscore";

import {GET_SUPPORTER_MAIN, GET_SUPPORTER_TOP} from "./supporterProfile";
import {
  Row,
  Col,
  Button,
  CardBody,
  CardFooter,
  FormCheckbox,
  Form,
  FormInput,
  FormSelect,
  FormFeedback
} from "shards-react";

import SupporterContactTags from '../friendsComponents/SupporterContactTags.js';
import CanadaPostAddress from '../friendsComponents/CanadaPostAddress.js';

export const UPDATE_SUPPORTER = gql`
mutation updateOneSupporter( $selector: Selector, $modifier: Modifier, $householdMembers: Array, $opts: GenericObject ){
  updateOneSupporter(
      selector: $selector,
      modifier: $modifier,
      householdMembers: $householdMembers,
      opts: $opts
  ) {
   ok
   n
   nModified
  }
}
`

const removeSpaces = (input) => {
    return input.split("").map( str => str.replace(/\s/g, '')).join('');
  }


class EditSupporterDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: {
        emails: []
      },
      supporterData      : this.props.supporterData,
      initialState       : this.props.supporterData,
      contactPreferences : this.props.supporterData.contactPreferences,
      emails             : this.props.supporterData.emails,
      modifiedFields     : {},
      showModal          : false
    }
  }

  addToModifier = (key, val) => {
    const existing = this.state.modifiedFields;
    existing[key] = val;
    this.setState({modifiedFields: existing});
  }

  setShowModal = (boolean) => {
    this.setState({showModal: boolean})
  }

  onChange = ({input = null, field, error = ""}) => {
    //sets the value of the input
    const supporter  = this.state.supporterData;
    supporter[field] = input;
    this.setState({ supporterData: supporter });

    //stores the error messages to display
    const message = this.state.errorMessages;
    message[field] = error;
    this.setState({message});
    this.addToModifier(field, input);
  }

  onChangeEmail = ({input, field, error, index}) => {

    //shallow copy of emails
    let emails = this.state.emails;
    //shallow copy of email and change property
    let email = {
      ...emails[index],
      [field] : input
    }
    //put the modified email back in the array
    emails[index] = email;
    this.addToModifier('emails', emails);
    this.setState({ emails })

  }

  setEmails = (emails) => {
    this.setState({emails});
  }

  onChangePrimary = (i) => {
    //shallow copy of emails
    let emails = this.state.emails;
    let email = emails[i];

    !!email.isPrimary ? email.isPrimary = false : email.isPrimary = true;

    emails[i] = email;

    this.addToModifier('emails', emails);
    this.setState({ emails })
  }

  onChangeVerified = (i) => {
    //shallow copy of emails
    let emails = this.state.emails;
    let email = emails[i];

    !!email.verified ? email.verified = false : email.verified = true;

    emails[i] = email;

    this.addToModifier('emails', emails);
    this.setState({ emails })
  }

  // this toggles the noCall, noSMS ... values
  onToggle = (field) => {
    const allPrefs  = this.state.contactPreferences;
    //switch 'em
    allPrefs[field] = !allPrefs[field];
    this.setState({'contactPreferences': allPrefs});
    this.addToModifier('contactPreferences.' + field, allPrefs[field]);
  }


  render()  {
    const user = JSON.parse(localStorage.getItem("user"));

    const hasGatewayRights = !!user.gatewayRole && user.gatewayRole !== "basic";

    return (
      <Mutation
          mutation={UPDATE_SUPPORTER}
          onCompleted={({updateOneSupporter}) => {
            toast.success("Supporter updated");
            if(!!this.props.handleCloseEdit){
              this.props.handleCloseEdit();
            }
            // this.props.refetch();
            // // this.props.changeView("show");

            // //if the props allowRedirect is passed, sets the redirect to true
            // if(!!this.props.allowRedirect) {
            //   this.setState({ redirect: true });
            // }

          }}
          refetchQueries={["getSupporter", "getActions"]}
        >
        {(update, {loading, error}) => {
          if ( !!error ) {
            console.log(error);
            return toast.error('error');
          }
          return (
            <Form>
              <CardBody className="p-0">

              {/* Supporter Data */}
              <div className="user-details__user-data border-bottom p-4">
                {!!hasGatewayRights && <Row>
                  <Col>
                  <p><strong>Do not manually change the PACStatus of a supporter. This is only available to fix an incorrect status.</strong></p>
                  </Col>
                  <Col md="6" className="form-group" disabled>
                    <label>PACStatus</label>
                    <FormSelect
                        id="PACStatus"
                        defaultValue={this.props.supporterData.PACStatus}
                        onChange={e => this.onChange({input : e.target.value, field : "PACStatus"})}
                        >
                        <option></option>
                        <option>pending</option>
                        <option>active</option>
                        <option>lapsed</option>
                        <option>cancelled</option>
                        <option>uncollectable</option>
                        <option>paused</option>

                    </FormSelect>

                  </Col>
                </Row> || "plop"}
                <Row form>
                  <Col md="2" className="form-group" disabled>
                  <label>Prefix</label>
                    <FormInput
                      id="prefix"
                      type="text"
                      value={!!this.props.supporterData.prefix ? this.props.supporterData.prefix : ""}
                      onChange={e => this.onChange({input : e.target.value, field : "prefix"})}
                    />
                    </Col>
                    <Col>
                  <label>First Name</label>
                    <FormInput
                      id="firstName"
                      type="text"
                      value={!!this.props.supporterData.firstName ? this.props.supporterData.firstName : ""}
                      onChange={e => this.onChange({input : e.target.value, field : "firstName"})}
                    />
                    </Col>
                    <Col>
                    <label>Last Name</label>
                  <FormInput
                      id="lastName"
                      type="text"
                      value={!!this.props.supporterData.lastName ? this.props.supporterData.lastName : ""}
                      onChange={e => this.onChange({input : e.target.value, field : "lastName"})}
                    />
                    </Col>
                  </Row>



                  <Row className="mb-3">
                    <Col>
                      <label>Donor PIN</label>
                      <FormInput
                        id="PIN"
                        type="text"
                        value={!!this.props.supporterData.PIN ? this.props.supporterData.PIN : ""}
                        onChange={e => this.onChange({input : e.target.value, field : "PIN"})}
                      />
                    </Col>

                    <Col className="w-50">
                      <label>Language</label>
                      <FormSelect
                        id="language"
                        defaultValue={this.props.supporterData.language}
                        onChange={e => this.onChange({input : e.target.value, field : "language"})}
                        >
                        <option>English</option>
                        <option>French</option>
                      </FormSelect>
                      </Col>
                    </Row>

                  <Row className="mb-3">
                    <Col className="w-50">
                      <label>Mobile Phone</label>
                      <FormInput
                        id="mobilePhone"
                        type="tel"
                        value={this.props.supporterData.mobilePhone || ""}
                        placeholder="Mobile Phone"
                        onChange={e => {
                          const input = removeSpaces(e.target.value)

                          const isValid = validator.isMobilePhone(input, ['en-CA', 'en-US'])
                          if (!!isValid || input.length === 0) {
                            this.onChange({
                              input : input,
                              field : "mobilePhone",
                              error : ""
                              })
                          } else {
                            this.onChange({
                              input : input,
                              field : "mobilePhone",
                              error : 'Mobile Phone is not valid'
                            })
                          }
                        }}
                        invalid={!!this.state.errorMessages.mobilePhone}
                      />
                      <FormFeedback>{this.state.errorMessages.mobilePhone}</FormFeedback>
                    </Col>
                    <Col className="w-50">
                      <label>Home Phone</label>
                      <FormInput
                        id="homePhone"
                        type="text"
                        value={this.props.supporterData.homePhone || ""}
                        placeholder="Home Phone"
                        onChange={e => {
                          const input = removeSpaces(e.target.value)
                          const isValid = validator.isMobilePhone(input, ['en-CA', 'en-US'])
                          if (!!isValid || input.length === 0) {
                            this.onChange({
                              input,
                              field : "homePhone",
                              error : ""
                              })
                          } else {
                            this.onChange({
                              input,
                              field : "homePhone",
                              error : 'Home Phone is not valid'})
                          }
                        }}
                        invalid={!!this.state.errorMessages.homePhone}
                      />
                      <FormFeedback>{this.state.errorMessages.homePhone}</FormFeedback>
                    </Col>

                  </Row>
                  <CanadaPostAddress
                    supporter={this.props.supporterData}
                    errorMessage={this.state.errorMessages}
                    onChange={this.onChange}
                  />

                  <Row className="mb-3">
                    <Col>
                      <label>EN Supporter ID</label>
                      <FormInput
                        id="ENSupporterId"
                        disabled={true}
                        type="text"
                        value={!!this.props.supporterData.ENSupporterId ? this.props.supporterData.ENSupporterId : ""}
                        onChange={e => this.onChange({input : e.target.value, field : "ENSupporterId"})}
                      />
                    </Col>
                    <Col>
                      <label>Stripe Customer ID</label>
                      <FormInput
                        id="customerId"
                        type="text"
                        disabled={!hasGatewayRights}
                        value={this.props.supporterData.customerId ? this.props.supporterData.customerId : ""}
                        onChange={e => this.onChange({input : e.target.value, field : "customerId"})}
                      />
                    </Col>

                  </Row>

                </div>
                {/* User Tags
                <div className="user-details__tags p-4 border-bottom">
                <h6>CONTACT PREFERENCES</h6>
                <SupporterContactTags
                    tags={this.state.contactPreferences}
                    edit={true}
                    onToggle={this.onToggle}
                />
                </div> */}

                <div className="user-details__tags p-4 border-bottom">
                  <FormCheckbox
                    checked={!!this.props.supporterData.isDeceased || false}
                    onChange={() => this.onChange({input : !this.props.supporterData.isDeceased, field : "isDeceased"})}
                    >
                    Deceased
                  </FormCheckbox>
                  <FormCheckbox
                    checked={!!this.props.supporterData.isArchived || false}
                    onChange={() => this.onChange({input : !this.props.supporterData.isArchived, field : "isArchived"})}
                    >
                    Archived
                  </FormCheckbox>
                   <FormCheckbox
                    checked={!!this.props.supporterData.isVolunteer || false}
                    onChange={() => this.onChange({input : !this.props.supporterData.isVolunteer, field : "isVolunteer"})}
                    >
                    Volunteer
                  </FormCheckbox>
                   <FormCheckbox
                    checked={!!this.props.supporterData.isUnknown || false}
                    onChange={() => this.onChange({input : !this.props.supporterData.isUnknown, field : "isUnknown"})}
                    >
                    Unknown
                  </FormCheckbox>
                </div>

              </CardBody>
              <CardFooter className="justify">
                <Button
                  outline
                  theme="danger"
                  onClick={() => {
                    toast.warn("modifications cancelled");

                    if(!_.isEmpty(this.state.modifiedFields)) {
                      return window.location.reload();
                    }

                    if(!!this.props.handleCloseEdit) {
                      this.props.handleCloseEdit();
                    }

                  }}>
                  Cancel
                </Button>
                <Button
                  theme="primary"
                  disabled={ !Object.keys(this.state.modifiedFields).length}
                  onClick={() => {

                    if(!!this.props.supporterData.householdMembers && this.props.supporterData.householdMembers.length) {
                      const householdFields = ["homePhone", "address1", "address2", "postalCode", "city", "province" ];

                      householdFields.forEach(field => {
                        if(!!this.state.modifiedFields[field]) {
                          this.setState({householdMembers : this.props.supporterData.householdMembers})
                        }
                      })
                    }
                    let errMessages = 0;
                    // counts the number of errors,
                    for( const field in this.state.errorMessages.message) {
                      if (this.state.errorMessages[field].length > 0) errMessages += 1;
                    }
                    if (errMessages) {
                      toast.warning(`Please correct the ${errMessages} incorrect field(s)`)
                    } else {
                      if(!!this.props.setUpdate){
                        this.props.setUpdate(true)
                      }
                      this.setState({showModal: true});

                    }
                  }}
                >Save</Button>
                <CommentAction
                    action={"update supporter"}
                    confirmation={false}
                    mutation={update}
                    showModal={this.state.showModal}
                    setShowModal={this.setShowModal}
                    householdMembers={!!this.state.householdMembers && this.state.householdMembers || null}
                    opts={{
                        variables  : {
                          selector : {
                            _id : this.props.supporterData._id
                          },
                          modifier: {
                            $set : this.state.modifiedFields
                          },
                          opts : {}
                        },
                        refetchQueries : ['getSupporter', 'getActions']

                      }} />
              </CardFooter>
            </Form>
          )
        }}
      </Mutation>
       );
  }

}


// const EditSupporterDetails =({this.props.supporterData, changeView}) => {
//
//
//
//
//   this.state.errorMessages={this.state.message}
//   onChange={this.onChange}
//   onChangeEmail={this.onChangeEmail}
//   onChangePrimary={this.onChangePrimary}
//   onChangeVerified={this.onChangeVerified}
//   onToggle={this.onToggle}
//
//   this.state.modifiedFields = {};
//
//
//
//
// }

export default EditSupporterDetails;
