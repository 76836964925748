import React, { useState, Fragment } from "react";
import Loading from "./Loading";
import SubscriptionForm from "./SubscriptionForm";
import { Modal } from "react-bootstrap";
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($subscriptionId: String, $type: String, $supporterId: String, $amount: Int, $bankAccountId: String, $opts: GenericObject) {
    updateSubscription(subscriptionId : $subscriptionId, amount: $amount, bankAccountId: $bankAccountId, supporterId : $supporterId, type: $type, opts: $opts){
      id
      bankAccountId
      status
      plan {
        id
        nickname
      }
    }
  }
`;


const UpdateSubscription = ({subscription, supporterId, bankAccounts, existingCreditCard, campaign}) => {

  //setup the varaibles
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleShowUpdate = (e) => {
    e.preventDefault();
    setShowUpdateModal(true);
  };
  const handleCloseUpdate = () => setShowUpdateModal(false);

  const [updateSubscription, {loading, error}] = useMutation(UPDATE_SUBSCRIPTION, {
    onCompleted : () => {
      toast.success("Subscription updated")
      handleCloseUpdate();
    },
    refetchQueries : ["getSupporter", "getSupporters"]
  })

  if(!!loading) {
    return <Loading />
  }

  if(error) {
    console.log("error in update subscription : ", error)
    toast.error("unable to update subscription")
  }


  return (
    <Fragment>
    <a
      href="#"
      style={{cursor : "pointer", color : "#2A82E6", paddingTop: "11px"}}
      onClick={handleShowUpdate}
    >
      Edit
    </a>
    <Modal
      size="lg"
      show={showUpdateModal}
      onHide={handleCloseUpdate}>
      <Modal.Header closeButton>
        <Modal.Title>Update Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SubscriptionForm
          mutation={updateSubscription}
          subscription={subscription}
          campaign={campaign}
          supporterId={supporterId}
          bankAccounts={bankAccounts}
          update={{from : subscription.plan.amount, subscriptionId : subscription.id, type : !!subscription.bankAccountId && "bank_account" || "credit_card"}}
          existingCreditCard={existingCreditCard}
        />
      </Modal.Body>

    </Modal>
  </Fragment>

  )
}

export default UpdateSubscription;
