import React, {useState, Fragment} from "react";
import {
  Row,
  Container,
  Tooltip
} from "shards-react";
import "./SupporterContactTags.css"


function parseContactKeyName(key) {
  const keyMap = {
    okMail: 'Mail',
    okCall: 'Call',
    okFund: 'Fund',
    okSMS:  'SMS',
    okContact: 'Contact',
    optInEn: 'English Opt-In',
    optInFr: 'French Opt-In',
    'okEmail': 'Email',
    'okNewsletter' : 'Newsletter',
    'okTrade' : 'Trade',
    'okSurveys' : 'Surveys',
    'okEvents' : 'Events',
    'okPremiums' : 'Premiums',
    'okContactRegular' : 'Contact Regular',
    'okPACAsk' : 'PAC Ask'
  };

  return !!keyMap[key] && keyMap[key];
}

const ToolTipMap = {
  // okMail: 'Mail',
  // okCall: 'Call',
  // okFund: 'Fund',
  // okSMS:  'SMS',
  // okContact: 'Contact',
  // optInEn: 'English Opt-In',
  // optInFr: 'French Opt-In',
  // 'okEmail': 'Email',
  // 'okNewsletter' : 'Newsletter',
  // 'okTrade' : 'Trade',
  // 'okSurveys' : 'Surveys',
  // 'okEvents' : 'Events',
  'okPremiums' : 'Tells if supporter is okay to receive premiums like the annual calendars',
  'okContactRegular' : 'Tells if supporter is okay to be contacted regularly or only once a year if unselected.'
}

const SupporterContactTags =({tags, edit = false, onToggle }) => {
  let sortBy = {
    valuesON  : [],
    valuesOFF : []
  };

  for(const key in tags) {
    if(parseContactKeyName(key)) {
      if(tags[key]) {
        sortBy.valuesON.push(key);
      } else {
        sortBy.valuesOFF.push(key);
      }
    }  
  }


  return (
    <div className="mt-2">
        {["valuesON", "valuesOFF"].map(value => {
          return (
            <div className="mb-2" key={value}>
              {sortBy[value].map(key => {
                return (
                  <span
                    className="mr-4"
                    id={key}
                    style={!!edit ? {cursor:'pointer'} : {'cursor': 'default'}}
                    key={key + Math.random()}
                    onClick={() => {
                        if( !!edit ) {
                          onToggle(key)
                        }
                        return;
                    }}
                  >
                    <i className={`fas ${value === "valuesON" && "fa-check" || "fa-times"}`}></i>
                    {parseContactKeyName(key)}
                  </span>
                )

              } )}
          </div> )
        })}

    </div>
  )
}

export default SupporterContactTags;
