import React, { useState, useEffect } from "react";
import {Button, Modal} from "react-bootstrap"
import gql from 'graphql-tag';

import { Container, Badge, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import MessageEditor from "../friendsComponents/MessageEditor";
import CollectionTable from "../friendsComponents/CollectionTable";
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';
import Review from "../friendsComponents/Review";
import templateEngine from "../templateEngine";

import { random, clone, extend } from 'underscore';

export const PACStatusMap = {
  "active"        : "success",
  "pending"       : "success",
  "lapsed"        : "warning",
  "uncollectable" : "danger",
  "cancelled"     : "secondary"
};

const gqlQuery = gql`
  query getSupporters($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject, $cacheBuster: Int) {
    getSupporters(selector: $selector, projector: $projector, limit: $limit, skip: $skip, sort: $sort, cacheBuster: $cacheBuster ) {
      count
      supporters {
        _id
        firstName
        lastName
        mobilePhone
        city
        PIN
        province
        language
        PACStatus
        customerId
        isDeceased
        isArchived
        isVolunteer
        isUnknown
      }
    }
  }
`;


const Messages = (props) => {
  SetPageMetas({ title: "SMS Messages" });

  const [ selector, setSelector ] = useState({
    merged: false,
    isDeceased: false,
    isArchived: false,
    isVolunteer: false,
    isUnknown: false,
    mobilePhone: { $ne: null },
    "contactPreferences.okContact": true,
    "contactPreferences.okSMS": true
  });
  const [from, setFrom] = useState('16474924966');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [queryStringHandler, setQueryStringHandler] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [positiveResponse, setpositiveResponse] = useState('');
  const [positiveLink, setPositiveLink] = useState('');
  const [negativeResponse, setnegativeResponse] = useState('');


  //modal Review
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {

    if ( !message || !message.length ) {
      return alert('You must set the message text.');
    }

    if ( message.includes("<%link%>") && ( !link || !link.length ) ) {
      return alert("Message includes a <%link%> tag but you have not set the link field.");
    }

    setShow(true);
  }


  //methods for the Message editor form
  const handleChange = (event) => {
		setFrom(event.target.value);
	};

	const handleChangeLink = (event) => {
		setLink(event.target.value);
	};

	const handleChangeQueryString = (event) => {
		setQueryStringHandler(event.target.value);
	};

	const handleChangeMessage = (input) => {
    setMessage(input);
    //need to add the example : our dear Bob Tester
		setMessageTemplate(templateEngine(input));
	};

  // const mobilePhoneRequired = () => {
  //   const actualQuery = query;
  //   actualQuery.selector.mobilePhone = { $ne: null}
  //   return actualQuery

  // }

  const columns = [{
    Header: 'First Name',
    className: "text-center d-flex align-items-center",
    accessor: 'firstName'
  }, {
    Header: 'Last Name',
    className: "text-center d-flex align-items-center",
    accessor: 'lastName'

  }, {
    Header: 'PIN',
    className: "text-center",
    accessor: 'PIN'
  }, {
    Header: 'City',
    className: "text-center",
    accessor: 'city'
  },{
    Header: 'Province',
    className: "text-center",
    accessor: 'province'
  }, {
    Header: 'Mobile Phone',
    className: "text-center",
    accessor: 'mobilePhone'
  },{
    Header: 'PAC',
    className: "text-center",
    accessor: "PACStatus",
    Cell: row => {
      return <span>{!!row.original.PACStatus && <Badge pill theme={PACStatusMap[row.original.PACStatus]}>{row.original.PACStatus}</Badge>}</span>;
    }
  }];

  return (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Messages" subtitle="Send Bulk" className="text-sm-left" />
    </Row>
    <Card small className="mb-4">
      <MessageEditor
        from={from}
        message={message}
        messageTemplate={messageTemplate}
        link={link}
        handleChange={handleChange}
        handleChangeMessage={handleChangeMessage}
        handleChangeLink={handleChangeLink}
        handleChangeQueryString={handleChangeQueryString}
        positiveResponse={positiveResponse}
        setpositiveResponse={setpositiveResponse}
        positiveLink={positiveLink}
        setPositiveLink={setPositiveLink}
        negativeResponse={negativeResponse}
        setnegativeResponse={setnegativeResponse}

      />
    </Card>

    <Card small className="mb-4">
      <CollectionTable
        collectionName='supporters'
        gqlQuery={gqlQuery}
        setParentVariables={({ variables, count }) => {
          setSelector( extend( variables.selector, { limit: count || 20 } ) );
        }}
        columns={columns}
        selector={clone( selector )}
        displayButtons={['filter']}
        viewProps={ props }
        projector={{
          _id         : 1,
          firstName   : 1,
          lastName    : 1,
          PIN         : 1,
          city        : 1,
          province    : 1,
          ridingName  : 1,
          mobilePhone : 1,
          PACStatus   : 1,
          isDeceased  : 1,
          isArchived  : 1,
          isVolunteer  : 1,
          isUnknown   : 1,

        }}
        sort={{createdDate : -1}}
        exportColumns={[]}
      />

    </Card>

    <Button variant="primary" size={'lg'} className='my-3' onClick={handleShow}>
        Review
    </Button>

    {/* modal review before sending messages  */}
    <Modal
      size="lg"
      // centered
      show={show}
      onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Review before sending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Review
            message={message}
            messageTemplate={messageTemplate}
            queryStringHandler={queryStringHandler}
            link={link}
            from={from}
            query={ selector }
            count={ selector.limit }
            positiveResponse={positiveResponse}
            negativeResponse={negativeResponse}
            positiveLink={positiveLink}
            collection={'supporter'}
          />
        </Modal.Body>
      </Modal>

  </Container>
)};

export default Messages;
